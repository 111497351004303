import { useState } from 'react'

import { Center, useBreakpointValue } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'

import { lightboxVideoModalState } from 'src/atoms/ui.atom'
import VimeoPlayer from 'src/components/widgets/VimeoPlayer/VimeoPlayer'
import YoutubePlayer from 'src/components/widgets/YoutubePlayer/YoutubePlayer'
import { pause } from 'src/lib/audioPlayer'

import LightboxModal from './LightboxModal'

const LightboxVideoModal = () => {
  const [isLoading, setIsLoading] = useState(true)
  const isSm = useBreakpointValue({ sm: true, md: false })
  const [{ vimeoUrl, youtubeUrl, brandColor }, setModalState] = useRecoilState(
    lightboxVideoModalState
  )
  if (isSm === undefined) return null
  const onClose = () => {
    setModalState({})
    setIsLoading(true)
  }
  const onReady = () => {
    setIsLoading(false)
    pause()
  }
  const size = isSm ? '100%' : '80%'
  return (
    <LightboxModal
      onClose={onClose}
      isOpen={!!(vimeoUrl || youtubeUrl)}
      loading={isLoading}
    >
      <Center pointerEvents="auto" w={size} h={size}>
        {!!vimeoUrl && (
          <VimeoPlayer
            url={vimeoUrl}
            onReady={onReady}
            brandColor={brandColor}
            options={{ autoplay: true }}
          />
        )}
        {!!youtubeUrl && <YoutubePlayer url={youtubeUrl} onReady={onReady} />}
      </Center>
    </LightboxModal>
  )
}

export default LightboxVideoModal
