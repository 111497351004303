import { useEffect, useState } from 'react'

import { useDebouncedResizeListener } from 'src/lib/hooks/auth/useDebouncedResizer'

const YOUTUBE_SHORT_RATIO = 656 / 1166
const YOUTUBE_PLAYER_RATIO = 16 / 9

export const useYoutubePlayer = (url: string) => {
  const isValid = !!url.match(/^.*(youtube\.com|youtu\.be)\/.+/)
  const metadata = getVideoId(url)

  const [width, setWidth] = useState<number>()
  const { ref, rect } = useDebouncedResizeListener()
  useEffect(() => {
    if (rect?.width && width !== rect.width)
      setWidth(
        metadata?.isShort ? rect.width * YOUTUBE_SHORT_RATIO : rect.width
      )
  }, [metadata?.isShort, rect, width])
  return {
    ref,
    width,
    videoId: isValid && metadata?.videoId,
    isValid,
    aspectRatio: metadata?.isShort ? YOUTUBE_SHORT_RATIO : YOUTUBE_PLAYER_RATIO,
  }
}

function getVideoId(urlString: string) {
  try {
    const url = new URL(urlString)
    if (url.pathname.startsWith('/shorts')) {
      return { videoId: url.pathname.replace(/^\/shorts\//, ''), isShort: true }
    }
    return {
      videoId: url.searchParams.get('v') ?? url.pathname.replace('/', ''),
      isShort: false,
    }
  } catch (e) {
    return null
  }
}
