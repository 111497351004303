import { Flex } from '@chakra-ui/react'
import { ItList } from 'types/graphql'

import { routes } from '@redwoodjs/router'

import Logo from 'src/components/widgets/Logo/Logo'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

import LoggedIn from './LoggedIn/LoggedIn'
import LoggedOut from './LoggedOut/LoggedOut'

const Large: React.FC<{ headerButtonVariant?: string; itList?: ItList }> = ({
  headerButtonVariant,
  itList,
}) => {
  const { isAuthenticated } = useCurrentUser()
  return (
    <Flex as="nav" gap={4} w="100%" alignItems="center" h="44px">
      <Logo mr={6} to={routes.home()} />
      {isAuthenticated ? (
        <LoggedIn headerButtonVariant={headerButtonVariant} itList={itList} />
      ) : (
        <LoggedOut headerButtonVariant={headerButtonVariant} itList={itList} />
      )}
    </Flex>
  )
}

export default Large
