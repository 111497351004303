import { useState } from 'react'

import { Box, Button } from '@chakra-ui/react'
import { faCommentDollar } from '@fortawesome/pro-solid-svg-icons'

import { navigate, routes, useLocation } from '@redwoodjs/router'

import Icon from '../../Icon/Icon'

const ReferralButton: React.FC<{
  hoverText: string
  label: string
}> = ({ hoverText, label }) => {
  const { pathname } = useLocation()
  const [isHovering, setIsHovering] = useState(false)
  const isActive = pathname === routes.referral()

  return (
    <Button
      variant={isActive ? 'highlightActive' : 'highlight'}
      w="181px"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => {
        navigate(routes.referral())
      }}
      gap={isHovering || isActive ? 0 : 4}
    >
      <Icon icon={faCommentDollar} gradient="primary-special4" />
      <Box flexGrow={1}>{isHovering || isActive ? hoverText : label}</Box>
    </Button>
  )
}

export default ReferralButton
