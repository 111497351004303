import { faPlus } from '@fortawesome/pro-regular-svg-icons'

import BrandButton from 'src/components/widgets/buttons/BrandButton/BrandButton'
import Icon from 'src/components/widgets/Icon/Icon'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

import { ChannelFormData } from '../ChannelsEditableList'

export const AddChannelButton: React.FC<{
  onAdd: (item: ChannelFormData) => void
}> = ({ onAdd }) => {
  const { brandColor } = useCurrentUser()
  return (
    <BrandButton
      brandColor={brandColor}
      w="100%"
      data-testid="editableList-add"
      onClick={() => {
        onAdd({ name: '' })
      }}
      leftIcon={<Icon icon={faPlus} />}
    >
      Add a Topic Channel
    </BrandButton>
  )
}

export default AddChannelButton
