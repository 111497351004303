import { Box, SystemProps } from '@chakra-ui/react'

import { Link } from '@redwoodjs/router'

import ItListLogoColor from 'src/assets/image/ItlistLogoColorSVG'
import ItListLogo from 'src/assets/image/ItlistLogoWordmarkSVG'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

type LogoProps = {
  size?: string
  to?: string
} & SystemProps

const Logo = ({ size, to, ...props }: LogoProps) => {
  const { isAuthenticated } = useCurrentUser()

  const Logo = () =>
    isAuthenticated ? (
      <ItListLogo />
    ) : (
      <ItListLogoColor width="75px" height="41px" />
    )

  return to ? (
    <Box w={size} {...props} data-testid="logo">
      <Link to={to}>
        <Logo />
      </Link>
    </Box>
  ) : (
    <Box w={size} {...props} data-testid="logo">
      <Logo />
    </Box>
  )
}

export default Logo
