import type { SVGProps } from 'react'
const SocialTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#FFFFFE"
    />
    <path
      d="M12.0261 9.4582L12.0597 10.0205L11.4998 9.95164C9.46143 9.68771 7.68069 8.79264 6.16874 7.28938L5.42957 6.54349L5.23917 7.0943C4.83599 8.32215 5.09358 9.61886 5.93355 10.491C6.38153 10.9729 6.28074 11.0418 5.50796 10.7549C5.23917 10.6631 5.00398 10.5943 4.98158 10.6287C4.90319 10.709 5.17198 11.7533 5.38477 12.1664C5.67596 12.7401 6.26954 13.3024 6.91911 13.6352L7.4679 13.8991L6.81832 13.9106C6.19114 13.9106 6.16874 13.9221 6.23594 14.1631C6.45993 14.9089 7.3447 15.7007 8.33026 16.045L9.02464 16.286L8.41986 16.6532C7.52389 17.181 6.47113 17.4794 5.41837 17.5023C4.91439 17.5138 4.5 17.5597 4.5 17.5941C4.5 17.7089 5.86635 18.3515 6.66152 18.604C9.04704 19.3499 11.8805 19.0286 14.0085 17.7548C15.5204 16.8483 17.0324 15.0466 17.7379 13.3024C18.1187 12.3729 18.4995 10.6746 18.4995 9.85984C18.4995 9.33198 18.5331 9.26312 19.1603 8.63199C19.5299 8.26478 19.8771 7.86315 19.9443 7.74839C20.0563 7.53036 20.0451 7.53036 19.4739 7.72544C18.5219 8.0697 18.3875 8.0238 18.8579 7.50741C19.2051 7.14021 19.6195 6.47464 19.6195 6.27956C19.6195 6.24514 19.4515 6.30251 19.2611 6.40579C19.0595 6.52054 18.6115 6.69267 18.2755 6.79595L17.6707 6.99103L17.122 6.61234C16.8196 6.40579 16.394 6.17629 16.17 6.10743C15.5988 5.94678 14.7252 5.96973 14.2101 6.15334C12.8101 6.66972 11.9253 8.00085 12.0261 9.4582Z"
      fill="#55ACEE"
    />
  </svg>
)
export default SocialTwitter
