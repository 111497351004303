import { useState } from 'react'

import { Box, Image, Text } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'

import { lightboxImageModalState } from 'src/atoms/ui.atom'

import LightboxModal from './LightboxModal'

const LightboxImageModal = () => {
  const [{ isOpen, image }, setModalState] = useRecoilState(
    lightboxImageModalState
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)

  const onClose = () => {
    setLoading(true)
    setError(false)
    setModalState((lastState) => {
      return { ...lastState, isOpen: false }
    })
  }

  return (
    <LightboxModal onClose={onClose} isOpen={isOpen} loading={loading}>
      <Box
        opacity={loading ? 0 : 1}
        transition="all 0.3s ease-in-out"
        borderRadius={{ base: 0, md: 12 }}
        overflow="hidden"
      >
        {error ? (
          <Text as="p" className="body-small" color="white">
            Oops, that image didn&apos;t load.
          </Text>
        ) : (
          <Image
            pointerEvents="auto"
            objectFit="contain"
            maxW={{ base: '100%', lg: '60vw' }}
            maxH={{ base: '85vh', md: '80vh' }}
            onLoad={() => setLoading(false)}
            onError={() => setError(true)}
            src={image.src}
            alt={image.alt || 'lightbox image'}
          />
        )}
      </Box>
    </LightboxModal>
  )
}

export default LightboxImageModal
