import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const IconButton: ComponentStyleConfig = {
  baseStyle: {
    _disabled: {
      opacity: 0.7,
      boxShadow: 'none !important',
    },
  },
}
