import { Box } from '@chakra-ui/react'
import _ from 'lodash'
import { ItList, ItListItem } from 'types/graphql'

import SearchInput from './SearchInput/SearchInput'
import SearchResuts from './SearchResults/SearchResults'
import useHeaderSearch from './useHeaderSearch'

export type Result = {
  item: ItListItem
  stringInContext: React.ReactNode
  fieldValue: string
}

const HeaderSearch: React.FC<{ itList: ItList }> = ({ itList }) => {
  const {
    updateSearch,
    isFocus,
    setIsFocus,
    currentQuery,
    setCurrentQuery,
    currentIndex,
    incCurrentIndex,
    results,
    brandColor,
    styleProps,
    pickResult,
    pickCurrentResult,
  } = useHeaderSearch(itList)
  return (
    <Box position="relative">
      <Box transition="all 0.2s" {...styleProps[isFocus ? 'focus' : 'blur']}>
        <SearchInput
          brandColor={brandColor}
          updateSearch={updateSearch}
          isFocus={isFocus}
          setIsFocus={setIsFocus}
          currentQuery={currentQuery}
          setCurrentQuery={setCurrentQuery}
          incCurrentIndex={incCurrentIndex}
          pickCurrentResult={pickCurrentResult}
        />
        {isFocus && (
          <SearchResuts
            isFocus={isFocus}
            results={results}
            currentQuery={currentQuery}
            currentIndex={currentIndex}
            pickResult={pickResult}
          />
        )}
      </Box>
    </Box>
  )
}

export default HeaderSearch
