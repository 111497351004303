import {
  Box,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  List,
  ListItem,
} from '@chakra-ui/react'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { Link, routes } from '@redwoodjs/router'

import LoginButton from 'src/components/widgets/buttons/LoginButton/LoginButton'
import LogoutButton from 'src/components/widgets/buttons/LogoutButton/LogoutButton'
import Icon from 'src/components/widgets/Icon/Icon'
import Logo from 'src/components/widgets/Logo/Logo'

const MobileNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  return (
    <>
      <Box
        display={{ base: 'block', md: 'none' }}
        mr="5"
        fontSize="20px"
        onClick={onOpen}
        ref={btnRef}
        data-testid="adminHeader-menuButton"
      >
        <Icon icon={faBars} />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        size="full"
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton fontSize={16} m={2} color="onyx" />
          <DrawerHeader>
            <Logo />
          </DrawerHeader>

          <DrawerBody>
            <List variant="overlined" as="nav" mt="28px">
              <Link to={routes.adminHome()} onClick={onClose}>
                <ListItem>Home</ListItem>
              </Link>
              <Link to={routes.waitlistTable()} onClick={onClose}>
                <ListItem>Waitlist</ListItem>
              </Link>
              <Link to={routes.creatorsTable()} onClick={onClose}>
                <ListItem>Creators</ListItem>
              </Link>
              <Link to={routes.agencyTable()} onClick={onClose}>
                <ListItem>Agencies</ListItem>
              </Link>
            </List>
          </DrawerBody>

          <DrawerFooter
            flexDir="column"
            alignItems="stretch"
            textAlign="center"
            mb={{ base: '50px', md: 'auto' }}
          >
            <LoginButton mr={2} />
            <LogoutButton mr={2} />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileNav
