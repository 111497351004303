import {
  AccordionButton,
  AccordionIcon,
  AccordionItem as ChakraAccordionItem,
  AccordionPanel,
  Box,
  Divider,
} from '@chakra-ui/react'
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons'

import { typography } from 'src/theme/typography'

import type { AccordionItemProps } from '..'
import Icon from '../../Icon/Icon'

const AccordionItem: React.FC<
  AccordionItemProps & { parentKey: string; withChevron: boolean }
> = ({
  title,
  children,
  headerProps,
  bodyProps,
  parentKey,
  withChevron,
  hideBottomDivider = false,
}) => {
  return (
    <>
      <ChakraAccordionItem
        border="none"
        px={0}
        w="100%"
        data-testid="accordion-trigger"
        key={parentKey}
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton
              px={0}
              py={4}
              flex="1"
              gap={4}
              textAlign="left"
              color="onyx"
              _hover={{ backgroundColor: 'transparent', cursor: 'pointer' }}
              as="h5"
              {...typography.h5}
              {...headerProps}
            >
              <Box w="100%">{title}</Box>
              {withChevron ? (
                <AccordionIcon />
              ) : (
                <Box
                  transition="all 0.2s ease-in-out"
                  transform={`rotate(${isExpanded ? -180 : 0}deg)`}
                >
                  <Icon
                    icon={isExpanded ? faMinus : faPlus}
                    color="stone"
                    fontSize="14px"
                  />
                </Box>
              )}
            </AccordionButton>
            <AccordionPanel
              px={0}
              pb={4}
              className="body-small"
              color="stone"
              whiteSpace="pre-wrap"
              {...bodyProps}
            >
              {children}
            </AccordionPanel>
          </>
        )}
      </ChakraAccordionItem>
      {!hideBottomDivider && <Divider color="sandstone" />}
    </>
  )
}

export default AccordionItem
