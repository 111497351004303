import type { SVGProps } from 'react'
const SocialMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="white" />
    <path
      d="M13.025 12.0518C13.025 14.5656 11.0046 16.6034 8.51256 16.6034C6.02049 16.6034 4 14.5652 4 12.0518C4 9.53845 6.02033 7.5 8.51256 7.5C11.0048 7.5 13.025 9.53799 13.025 12.0518Z"
      fill="black"
    />
    <path
      d="M17.9755 12.0518C17.9755 14.418 16.9653 16.337 15.7192 16.337C14.4731 16.337 13.4629 14.418 13.4629 12.0518C13.4629 9.68562 14.4729 7.76666 15.719 7.76666C16.9651 7.76666 17.9753 9.685 17.9753 12.0518"
      fill="black"
    />
    <path
      d="M20 12.0518C20 14.1713 19.6448 15.8906 19.2065 15.8906C18.7682 15.8906 18.4131 14.1718 18.4131 12.0518C18.4131 9.93178 18.7683 8.21295 19.2065 8.21295C19.6446 8.21295 20 9.93163 20 12.0518Z"
      fill="black"
    />
  </svg>
)
export default SocialMedium
