import { useRecoilState } from 'recoil'

import { isChannelsModalOpenState } from 'src/atoms/ui.atom'
import ModalFrame from 'src/components/widgets/Modals/ModalFrame/ModalFrame'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

import EditChannelsModalCell from './EditChannelsModalCell'

const EditChannelsModal: React.FC = () => {
  const [isChannelsModalOpen, setIsChannelsModalOpen] = useRecoilState(
    isChannelsModalOpenState
  )

  const { id: creatorId } = useCurrentUser()
  const onClose = () => {
    setIsChannelsModalOpen(false)
  }
  return (
    <ModalFrame onClose={onClose} isOpen={isChannelsModalOpen}>
      {!!creatorId && (
        <EditChannelsModalCell creatorId={creatorId} onClose={onClose} />
      )}
    </ModalFrame>
  )
}

export default EditChannelsModal
