import { Box, Link, useDisclosure } from '@chakra-ui/react'
import { useSetRecoilState } from 'recoil'

import { creatorChannelsState } from 'src/atoms/creator.atom'
import ChannelFAQ from 'src/components/widgets/ChannelFAQ/ChannelFAQ'
import { ChannelFormData } from 'src/components/widgets/EditableList/instances/ChannelsEditableList/ChannelsEditableList'
import ModalForm from 'src/components/widgets/Modals/ModalForm/ModalForm'
import { useSetCreatorChannels } from 'src/lib/hooks/mutations/useSetCreatorChannels'

import ErrorMessage from '../../../ErrorMessage/ErrorMessage'

import ChannelsExample from './ChannelsExample/ChannelsExample'

const CellFrame: React.FC<{
  initialItems?: ChannelFormData[]
  onClose: () => void
  children: React.ReactNode
}> = ({ children, initialItems = [], onClose }) => {
  const {
    isOpen: isOpenFAQ,
    onOpen: onOpenFAQ,
    onClose: onCloseFAQ,
  } = useDisclosure()
  const setChannels = useSetRecoilState(creatorChannelsState)
  const { submit, error } = useSetCreatorChannels({
    onCompleted: ({ setCreatorChannels }) => {
      setChannels(
        setCreatorChannels.sort((a, b) => (a.order > b.order ? 1 : -1))
      )
    },
  })
  const openFAQ = () => {
    onOpenFAQ()
  }

  return (
    <>
      <ModalForm
        mutation={submit}
        defaultValues={{ items: initialItems }}
        onClose={onClose}
      >
        <Box as="h3" mt={10} className="thick" data-testid="cellFrame-title">
          Edit Topic Channels
        </Box>
        <Box mt={4} className="body-small">
          Topic Channels are topics in your niche you talk about often.{' '}
          <Link
            className="body-small-emphasis"
            textDecoration="underline"
            onClick={openFAQ}
          >
            More info
          </Link>
        </Box>
        <Box mt={8}>{children}</Box>
        <ChannelsExample />
        {!!error && <ErrorMessage mt={2} ml={6} message={error} />}
      </ModalForm>
      <ChannelFAQ closeModal={onCloseFAQ} isOpen={isOpenFAQ} />
    </>
  )
}

export default CellFrame
