import { useState } from 'react'

import { Link, SetCreatorLinksInput } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useCurrentUser } from '../auth/useCurrentUser'

type SetCreatorLinkResponse = {
  setCreatorLinks: Pick<Link, 'id' | 'name' | 'url' | 'order'>[]
}

type SetCreatorLinkRequest = {
  input: SetCreatorLinksInput
}

const SET_CREATOR_LINKS_MUTATION = gql`
  mutation SetCreatorLinksMutation($input: SetCreatorLinksInput!) {
    setCreatorLinks(input: $input) {
      id
      name
      url
      order
    }
  }
`

export const useSetCreatorLinks = (
  options?: GraphQLMutationHookOptions<
    SetCreatorLinkResponse,
    SetCreatorLinkRequest
  >
) => {
  const { id: sessionUserId } = useCurrentUser()

  const [mutation, { loading, data }] = useMutation<
    SetCreatorLinkResponse,
    SetCreatorLinkRequest
  >(SET_CREATOR_LINKS_MUTATION, options)

  const [error, setError] = useState<string>()

  const setCreatorLinks = async (input: SetCreatorLinksInput) =>
    mutation({ variables: { input } })

  const submit = async ({ items }: { items: Omit<Link, 'order'>[] }) => {
    const links = items.map((item, index) => ({
      id: item.id,
      name: item.name?.trim(),
      url: item.url?.trim(),
      order: index + 1,
    }))

    const response = await setCreatorLinks({
      creatorId: sessionUserId,
      links,
    })
    if (response.errors) {
      setError('Update links failed')
    }
    return response
  }

  return {
    mutation: setCreatorLinks,
    loading,
    error,
    data,
    submit,
  }
}
