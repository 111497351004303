import { Flex, Box } from '@chakra-ui/react'

import AdminHeader from 'src/components/admin/AdminHeader/AdminHeader'
import Footer from 'src/components/layout/Footer/Footer'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

type AdminLayoutProps = {
  children?: React.ReactNode
}

const CenteredBox = ({ children, ...props }: AdminLayoutProps) => {
  return (
    <Flex
      direction="column"
      boxSizing="content-box"
      maxW={{
        base: '100%',
        xl: '1200px',
      }}
      m={{
        base: '0',
        xl: '0 auto',
      }}
      px={{ base: 4, lg: 6 }}
      {...props}
    >
      {children}
    </Flex>
  )
}

const AdminLayout = (props: AdminLayoutProps) => {
  const { isAdmin } = useCurrentUser()
  if (!isAdmin) {
    return <Box p={5}>...</Box>
  }
  return (
    <>
      <CenteredBox>
        <AdminHeader />
      </CenteredBox>
      <Box
        boxShadow="inner"
        overflowX="scroll"
        borderBottom="1px solid"
        borderBottomColor="vapor"
      >
        <CenteredBox>{props.children}</CenteredBox>
      </Box>
      <CenteredBox>
        <Footer />
      </CenteredBox>
    </>
  )
}

export default AdminLayout
