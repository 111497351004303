import type { SVGProps } from 'react'
const SocialLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.65948 7.41135C8.65948 8.1959 8.06892 8.82367 7.12069 8.82367H7.10331C6.19033 8.82367 5.6001 8.1959 5.6001 7.41135C5.6001 6.6102 6.2083 6 7.13861 6C8.06892 6 8.64183 6.6102 8.65948 7.41135ZM8.48045 9.93896V18.1098H5.76099V9.93896H8.48045ZM18.2876 18.1098L18.2877 13.4249C18.2877 10.9151 16.9461 9.74707 15.1566 9.74707C13.7128 9.74707 13.0663 10.5401 12.7054 11.0965V9.93915H9.9856C10.0214 10.7059 9.9856 18.11 9.9856 18.11H12.7054V13.5467C12.7054 13.3025 12.723 13.0589 12.7949 12.8841C12.9915 12.3962 13.439 11.8911 14.1903 11.8911C15.1748 11.8911 15.5683 12.6404 15.5683 13.7384V18.1098H18.2876Z"
      fill="#0A66C2"
    />
  </svg>
)
export default SocialLinkedin
