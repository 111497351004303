import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const List: ComponentStyleConfig = {
  variants: {
    dropDown: {
      container: {
        borderWidth: '1px',
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)',
        position: 'absolute',
        zIndex: 'dropdown',
        left: '0',
        right: '0',
        padding: 4,
      },
      item: {
        _hover: { bg: 'vapor' },
        cursor: 'pointer',
        borderRadius: '8px',
        p: '10px 8px 10px 8px',
        fontSize: '14px',
      },
    },
    overlined: {
      item: {
        borderTopColor: 'sandstone',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        py: 4,
        px: 4,
      },
    },
    underlined: {
      item: {
        borderBottomColor: 'sandstone',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        py: 5,
        px: 4,
      },
    },
  },
}
