import type { SVGProps } from 'react'
const SocialPoshmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="white"
    />
    <path
      d="M14.015 4.00072C11.6441 4.00072 9.73031 5.92883 9.73031 8.28541V15.0267H11.3156V8.28541C11.3156 6.78576 12.5296 5.58605 14.015 5.58605C15.5146 5.58605 16.7144 6.80005 16.7144 8.28541C16.7144 9.71363 15.6003 10.8991 14.1721 10.9848V12.5701C16.543 12.513 18.4139 10.5563 18.3568 8.18543C18.314 5.84313 16.3716 3.95787 14.015 4.00072ZM11.9869 14.7124C11.9869 16.2121 10.7729 17.4118 9.28756 17.4118C7.78792 17.4118 6.58821 16.1978 6.58821 14.7124C6.58821 13.2842 7.70223 12.0988 9.13046 12.0131V10.4277C6.75959 10.5134 4.91718 12.4987 5.00287 14.8695C5.08857 17.2404 7.07381 19.0828 9.44467 18.9971C11.7441 18.9114 13.5722 17.0119 13.5722 14.7124V7.9712H11.9869V14.7124Z"
      fill="#602D35"
    />
  </svg>
)
export default SocialPoshmark
