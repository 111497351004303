import { useEffect, useState } from 'react'

import { useAuth } from 'src/auth'

import { useCurrentUser } from '../auth/useCurrentUser'

import { ViewerStatus } from './useAnalytics'

export function useViewerStatus(hasPurchased?: boolean) {
  const { isAuthenticated } = useAuth()
  const currentUser = useCurrentUser()
  const [viewerStatus, setViewerStatus] = useState<Set<ViewerStatus>>()

  useEffect(() => {
    const statusSet = new Set<ViewerStatus>()
    if (hasPurchased) {
      statusSet.add('purchaser')
    }
    if (isAuthenticated) {
      if (currentUser.isAffiliate) {
        statusSet.add('affiliate')
      }
      if (currentUser.isCreator) {
        statusSet.add('creator')
      }
      if (!currentUser.isCreator && !currentUser.isAffiliate) {
        statusSet.add('explorer')
      }
    } else {
      statusSet.add('guest')
    }
    setViewerStatus(statusSet)
  }, [
    hasPurchased,
    isAuthenticated,
    currentUser.isCreator,
    currentUser.isAffiliate,
  ])

  return viewerStatus
}
