import { Spinner } from '@chakra-ui/react'

import { LoaderProps } from '..'

const InlineSpinner = ({
  size = 'md',
  color = 'brand.200',
  fontSize = '16px',
  thickness = '2px',
  ...props
}: Omit<LoaderProps, 'show' | 'inline'>) => (
  <Spinner
    size={size}
    opacity={1}
    color={color}
    thickness={thickness}
    fontSize={fontSize}
    {...props}
  ></Spinner>
)

export default InlineSpinner
