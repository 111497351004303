import { pathJoin } from 'src/lib/utils'

const assetBasePath = pathJoin(
  process.env.ASSETS_URL,
  process.env.ASSETS_BASE_PATH
)

export function getImageKitUrl(src: string): string {
  const isUserGeneratedAsset = src.startsWith(assetBasePath)
  if (isUserGeneratedAsset)
    return pathJoin(
      process.env.IMAGE_IO_ENDPOINT,
      process.env.ASSETS_BASE_PATH,
      src.replace(new RegExp(`^${assetBasePath}`), '')
    )
  return pathJoin(`${process.env.IMAGE_IO_ENDPOINT}-static`, src)
}
