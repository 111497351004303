import { useRecoilState } from 'recoil'

import { isLinksModalOpenState } from 'src/atoms/ui.atom'
import ModalFrame from 'src/components/widgets/Modals/ModalFrame/ModalFrame'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

import EditLinksModalCell from './EditLinksModalCell'

const EditLinksModal = () => {
  const [isLinksModalOpen, setIsLinksModalOpen] = useRecoilState(
    isLinksModalOpenState
  )
  const { id: creatorId } = useCurrentUser()
  const onClose = () => {
    setIsLinksModalOpen(false)
  }

  return (
    <ModalFrame onClose={onClose} isOpen={isLinksModalOpen}>
      {!!creatorId && (
        <EditLinksModalCell creatorId={creatorId} onClose={onClose} />
      )}
    </ModalFrame>
  )
}

export default EditLinksModal
