import { Box, ScaleFade } from '@chakra-ui/react'

const InputTitle: React.FC<{
  shifted?: boolean
  hasValue: boolean
  hasErrors?: boolean
  hasFocus: boolean
  value: string
}> = ({ shifted, hasValue, hasFocus, hasErrors, value }) => {
  return (
    <Box position="absolute" top="-7px" left={shifted ? 4 : 3} zIndex={1}>
      <ScaleFade initialScale={0.5} in={hasValue}>
        <Box
          transition="all 0.1s linear"
          fontSize={10}
          bg="white"
          px={1}
          color={
            hasErrors
              ? 'danger'
              : hasFocus
              ? 'onyx'
              : value
              ? 'stone'
              : 'graphite'
          }
          _groupHover={{
            color: hasErrors ? 'danger' : 'onyx',
          }}
        >
          {value}
        </Box>
      </ScaleFade>
    </Box>
  )
}

export default InputTitle
