import { useResetCreatorState } from 'src/atoms/creator.atom'
import { useResetNavigationState } from 'src/atoms/navigation.atom'
import { useResetUiState } from 'src/atoms/ui.atom'
import { useAuth } from 'src/auth'

import { useAnalytics } from '../analytics/useAnalytics'

export function useLogout() {
  const { logOut } = useAuth()
  const resetCreatorState = useResetCreatorState()
  const resetNavigationState = useResetNavigationState()
  const resetUiState = useResetUiState()
  const { track, reset } = useAnalytics()
  return async () => {
    track({ eventName: 'User Signed Out' })
    reset()
    resetCreatorState()
    resetNavigationState()
    resetUiState()
    await logOut()
    // It also work to not reload the page in most cases, but:
    // - We then would need to do an extra getToken() because logout doesn't seem to clear the token
    //   and the next signin request fails with the old Bearer token
    // - Refresh is needed in an itList details page of an unlocked paid itList, otherwise the paywalled itlist will still
    //   display the items
    window.location.reload()
  }
}
