import { Button, Flex, Spacer, Box } from '@chakra-ui/react'
import {
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'

import { Link, routes } from '@redwoodjs/router'

import FeedbackButton from 'src/components/widgets/buttons/FeedbackButton/FeedbackButton'
import { HELP_CENTER_URL } from 'src/lib/constants'

import { thisYear } from '../Footer'
import SocialLink from '../SocialLink/SocialLink'

const Large = () => {
  return (
    <>
      <Flex gap={2} py={10}>
        <FeedbackButton />
        <Spacer />
        <a href={HELP_CENTER_URL} target="_blank" rel="noreferrer">
          <Button variant="ghost" size="sm" lineHeight="26px">
            Help Center
          </Button>
        </a>
        <Link to={routes.aboutUs()}>
          <Button variant="ghost" size="sm" lineHeight="26px">
            About Us
          </Button>
        </Link>
        <Link to={routes.privacy()}>
          <Button variant="ghost" size="sm" lineHeight="26px">
            Privacy
          </Button>
        </Link>
        <Link to={routes.terms()}>
          <Button variant="ghost" size="sm" lineHeight="26px">
            Terms
          </Button>
        </Link>
      </Flex>
      <Flex h="110px" alignItems="center" gap={6}>
        <SocialLink icon={faTwitter} url="https://twitter.com/ItList_HQ" />
        <SocialLink
          icon={faInstagram}
          url="https://www.instagram.com/itlist_hq/"
        />
        <SocialLink
          icon={faLinkedinIn}
          url="https://www.linkedin.com/company/itlist/about/"
        />
        <Spacer />
        <Flex direction="column" alignItems="end" className="body-extra-small">
          <Box color="stone">Made with ❤️ in LA</Box>
          <Box color="stone">&copy; {thisYear} ItList Creator Labs, Inc.</Box>
          <Box color="stone">All rights reserved</Box>
        </Flex>
      </Flex>
    </>
  )
}

export default Large
