import { Box, BoxProps } from '@chakra-ui/react'
import {
  FaSymbol,
  FlipProp,
  IconProp,
  PullProp,
  RotateProp,
  SizeProp,
  Transform,
} from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { colors } from 'src/theme/colors'

export type IconProps = {
  icon: IconProp
  mask?: IconProp
  className?: string
  color?: string
  gradient?: string
  spin?: boolean
  pulse?: boolean
  border?: boolean
  fixedWidth?: boolean
  inverse?: boolean
  listItem?: boolean
  flip?: FlipProp
  size?: SizeProp
  pull?: PullProp
  rotation?: RotateProp
  transform?: string | Transform
  symbol?: FaSymbol
  tabIndex?: number
  title?: string
  swapOpacity?: boolean
  primaryColor?: string
  secondaryColor?: string
  style?: Record<string, string>
} & Omit<BoxProps, 'style'>

const gradientClassNames = {
  'primary-special1': 'fill-gradient-primary-special1',
  'primary-special2': 'fill-gradient-primary-special2',
  'primary-special3': 'fill-gradient-primary-special3',
  'primary-special4': 'fill-gradient-primary-special4',
}

const Icon = ({
  icon,
  mask,
  className,
  gradient,
  spin,
  pulse,
  border,
  fixedWidth,
  inverse,
  listItem,
  flip,
  size,
  pull,
  rotation,
  transform,
  symbol,
  tabIndex,
  title,
  swapOpacity,
  primaryColor,
  secondaryColor,
  style,
  ...props
}: IconProps) => {
  const gradientClassName = gradient ? gradientClassNames[gradient] : null

  // duotone icons, see https://fontawesome.com/docs/web/style/duotone
  if (primaryColor && secondaryColor) {
    className = [className, 'fa-duotone'].filter((cl) => !!cl).join(' ')
    if (colors[primaryColor]) primaryColor = colors[primaryColor]
    if (colors[secondaryColor]) secondaryColor = colors[secondaryColor]
    style = {
      ...style,
      '--fa-primary-color': primaryColor,
      '--fa-secondary-color': secondaryColor,
    }
  }

  return (
    <Box {...props}>
      <FontAwesomeIcon
        icon={icon}
        mask={mask}
        className={
          gradientClassName ? `${gradientClassName} ${className}` : className
        }
        spin={spin}
        pulse={pulse}
        border={border}
        fixedWidth={fixedWidth}
        inverse={inverse}
        listItem={listItem}
        flip={flip}
        size={size}
        pull={pull}
        rotation={rotation}
        transform={transform}
        symbol={symbol}
        tabIndex={tabIndex}
        title={title}
        swapOpacity={swapOpacity}
        style={style}
      />
    </Box>
  )
}

export default Icon
