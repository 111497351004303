import { useCallback } from 'react'

import { useSetRecoilState } from 'recoil'

import { useLocation } from '@redwoodjs/router'

import { backUrlState } from 'src/atoms/navigation.atom'

export type UrlParams = Record<string, string | number | boolean>

export type UseSetBackUrlOptions = {
  path?: string
  search?: UrlParams
  hash?: UrlParams
}

export const useSetBackUrl = (baseOptions: UseSetBackUrlOptions = {}) => {
  const { pathname, search, hash } = useLocation()
  const setBackUrl = useSetRecoilState(backUrlState)
  return useCallback(
    (options: UseSetBackUrlOptions = {}) => {
      options = { ...baseOptions, ...options }
      const url = `${getPath(pathname, options.path)}${getSearch(
        search,
        options.search
      )}${getHash(hash, options.hash)}`
      setBackUrl(url)
    },
    [baseOptions, pathname, search, hash, setBackUrl]
  )
}

function getPath(pathFromUrl: string, pathFromBackUrl?: string): string {
  if (pathFromBackUrl) return pathFromBackUrl
  return pathFromUrl || ''
}

function getSearch(
  searchFromUrl: string,
  searchFromBackUrl?: UrlParams
): string {
  return buildQueryString('?', searchFromUrl, searchFromBackUrl)
}

function getHash(hashFromUrl: string, hashFromBackUrl?: UrlParams): string {
  return buildQueryString('#', hashFromUrl, hashFromBackUrl)
}

function buildQueryString(
  prefix: string,
  fromUrl: string,
  fromBackUrl: UrlParams
) {
  if (fromBackUrl) {
    return `${prefix}${Object.entries(fromBackUrl)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join('&')}`
  }
  return fromUrl || ''
}
