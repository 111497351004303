import { Flex } from '@chakra-ui/react'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

import Icon from 'src/components/widgets/Icon/Icon'

import { Result } from '../../HeaderSearch'

import ItemIcon from './ItemIcon/ItemIcon'

const ResultItem: React.FC<{
  result: Result
  isSelected: boolean
  pickResult(itemId: number): unknown
}> = ({ result, isSelected, pickResult }) => {
  const clickResultHandler: React.MouseEventHandler = () => {
    pickResult(result.item.id)
  }
  return (
    <Flex
      gap={3.5}
      alignItems="center"
      mt={2}
      color="stone"
      _hover={{ bg: 'air' }}
      bg={isSelected ? 'air' : 'white'}
      cursor="pointer"
      borderRadius={12}
      onMouseDown={clickResultHandler}
      data-item-id={result.item.id}
    >
      <ItemIcon flex="0 0 36px" item={result.item} />
      {result.stringInContext}
      <Icon icon={faChevronRight} color="graphite" flex="0 0 20px" />
    </Flex>
  )
}

export default ResultItem
