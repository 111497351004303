import { useCallback, useState } from 'react'

import { Box, BoxProps, Text, useBreakpointValue } from '@chakra-ui/react'
import _ from 'lodash'
import { useSetRecoilState } from 'recoil'
import { ItList, ItListItem } from 'types/graphql'

import { currentItemIdState } from 'src/atoms/ui.atom'
import { isString } from 'src/lib/typeGuards'
import { getBrandColor, isValidUrl } from 'src/lib/utils'

export type Result = {
  item: ItListItem
  stringInContext: React.ReactNode
  fieldValue: string
}

const CONTEXT_BEFORE_QUERY_MAX_LENGTH = 35

const stylePerBreakpoint: Record<
  'base' | 'md' | 'lg',
  { focus: BoxProps; blur: BoxProps }
> = {
  base: {
    focus: {
      position: 'absolute',
      right: 0,
      width: 'calc(100vw - 32px)',
      top: -6,
    },
    blur: {
      position: 'absolute',
      right: 0,
      top: -6,
      w: 190,
    },
  },
  md: {
    focus: {
      position: 'absolute',
      right: 8,
      width: 'calc(100vw - 100px)',
      top: -6,
    },
    blur: {
      position: 'absolute',
      right: 0,
      top: -6,
      w: 190,
    },
  },
  lg: {
    focus: {
      position: 'absolute',
      right: 0,
      top: -6,
      w: 343,
    },
    blur: {
      position: 'absolute',
      right: 0,
      top: -6,
      w: 190,
    },
  },
}

export default function useHeaderSearch(itList: ItList) {
  const setCurrentItemId = useSetRecoilState(currentItemIdState)
  const styleProps = useBreakpointValue(stylePerBreakpoint)
  const brandColor = getBrandColor(itList.creator)
  const [isFocus, setIsFocus] = useState(false)
  const [results, setResults] = useState<Result[]>()
  const [currentQuery, setCurrentQuery] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const updateSearch: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    const query = ev.target.value.toLocaleLowerCase()
    setCurrentQuery(query)
    setCurrentIndex(0)
    if (query.length < 2) {
      setResults([])
    } else {
      setResults(search(query))
    }
  }
  const search = useCallback(
    (query: string) => {
      let newResults: Result[] = []
      for (const item of itList.items) {
        const newResult = searchRec(item, item, query)
        if (newResult) newResults = [...newResults, newResult]
      }
      return newResults
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itList.items]
  )
  const searchRec = (
    item: ItListItem,
    itemObj: Record<string, unknown>,
    query: string
  ): Result | null => {
    let itemResults: Result[] = []
    for (const [key, value] of Object.entries(itemObj)) {
      if (isString(value) && !isValidUrl(value) && key !== 'type') {
        const newSearchResult = searchQueryInString(
          query,
          value.toLocaleLowerCase()
        )
        if (newSearchResult) {
          itemResults.push({
            item,
            stringInContext: newSearchResult,
            fieldValue: value,
          })
        }
      } else if (_.isObject(value)) {
        const newItemResult = searchRec(
          item,
          value as Record<string, unknown>,
          query
        )
        if (newItemResult) {
          itemResults = [...itemResults, newItemResult]
        }
      }
    }
    return itemResults.length
      ? itemResults.sort(
          (resA, resB) => resB.fieldValue.length - resA.fieldValue.length
        )[0]
      : null
  }
  const searchQueryInString = (query: string, value: string) => {
    const indexOfQuery = value.indexOf(query)
    if (indexOfQuery > -1) {
      const parts = splitString(value, indexOfQuery, query)
      return (
        <Box noOfLines={2} flexGrow={1} className="body-small">
          {parts[0]}
          <Text
            color={brandColor}
            className="body-small-emphasis"
            display="inline"
          >
            {parts[1]}
          </Text>
          {parts[2]}
        </Box>
      )
    }
  }

  const pickCurrentResult = () => {
    const result = results[currentIndex]
    if (result) {
      pickResult(result.item.id)
    }
  }

  const pickResult = (itemId: number) => {
    setCurrentItemId(itemId)
    history.pushState(null, null, `#it-list-item-${itemId}`)
  }

  const incCurrentIndex = (direction: 1 | -1) => {
    const newCurrentIndex = currentIndex + direction
    if (newCurrentIndex === -1) {
      setCurrentIndex(results.length - 1)
    } else {
      setCurrentIndex(results[newCurrentIndex] ? newCurrentIndex : 0)
    }
  }

  return {
    updateSearch,
    isFocus,
    setIsFocus,
    currentQuery,
    setCurrentQuery,
    currentIndex,
    setCurrentIndex,
    results,
    brandColor,
    styleProps,
    pickResult,
    pickCurrentResult,
    incCurrentIndex,
  }
}

const splitString = (str: string, indexOfQuery: number, query: string) => {
  str =
    indexOfQuery > CONTEXT_BEFORE_QUERY_MAX_LENGTH
      ? `...${str.slice(indexOfQuery - CONTEXT_BEFORE_QUERY_MAX_LENGTH)}`
      : str

  indexOfQuery = str.indexOf(query)
  return [
    str.substring(0, indexOfQuery),
    query,
    str.substring(indexOfQuery + query.length),
  ]
}
