import { Box, Flex, Spacer } from '@chakra-ui/react'

import ErrorMessage from '../ErrorMessage/ErrorMessage'
import InputCounter from '../InputCounter/InputCounter'

const InputFooter: React.FC<{
  showCounter?: boolean
  hasErrors?: boolean
  errorMessage?: string
  hint?: string
  count?: number
  maxLength?: number
  noWarningMax?: number
  countDown?: boolean
  hideCounterMax?: number
}> = ({
  showCounter,
  hasErrors,
  errorMessage,
  hint,
  count,
  maxLength,
  noWarningMax,
  countDown,
  hideCounterMax,
}) => {
  return (
    <Flex px={4} gap={2} justifyContent="space-between" w="100%">
      {hasErrors && (
        <ErrorMessage mt={1} message={errorMessage || 'Invalid input'} />
      )}
      {!hasErrors && !!hint && (
        <Box color="stone" className="body-extra-small-emphasis" mt={1}>
          {hint}
        </Box>
      )}

      <Spacer />

      {showCounter && (
        <InputCounter
          count={count}
          max={maxLength}
          noWarningMax={noWarningMax}
          countDown={countDown}
          hideCounterMax={hideCounterMax}
          mr={4}
          mt={1}
        />
      )}
    </Flex>
  )
}

export default InputFooter
