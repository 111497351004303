import { useEffect } from 'react'

import { useCurrentUser } from '../auth/useCurrentUser'

import { analytics } from './segment'
import { useAnalytics } from './useAnalytics'

export function useIdentify() {
  const { liveSessionIdentify } = useAnalytics()
  const currentUser = useCurrentUser()
  useEffect(() => {
    if (currentUser.isAuthenticated) {
      liveSessionIdentify(currentUser)
      analytics.identify(`${currentUser.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.isAuthenticated])
}
