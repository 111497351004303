import { IconButton } from '@chakra-ui/react'
import { faChevronDown, faCheck } from '@fortawesome/pro-solid-svg-icons'
import { motion } from 'framer-motion'
import _ from 'lodash'

import Icon from 'src/components/widgets/Icon/Icon'
import { itListTheme } from 'src/theme'

const ExpandButton = ({ expanded }) => (
  <IconButton
    as={motion.button}
    initial={{
      padding: 'var(--chakra-space-0)',
      backgroundColor: 'rgba(1,1,1,0)',
    }}
    animate={{
      rotate: expanded ? 180 : 0,
      padding: expanded ? 'var(--chakra-space-2)' : 'var(--chakra-space-0)',
      backgroundColor: expanded ? itListTheme.colors.dust : 'rgba(1,1,1,0)',
    }}
    whileHover={{
      backgroundColor: expanded
        ? itListTheme.colors.sandstone
        : 'rgba(1,1,1,0)',
    }}
    flex="0 0 auto"
    variant="function"
    p={2}
    ml={4}
    aria-label="expand-collapse"
    data-testid="editItem-collapseButton"
    icon={
      <Icon
        boxSize="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flip={expanded ? 'both' : undefined}
        icon={expanded ? faCheck : faChevronDown}
      />
    }
  ></IconButton>
)

export default ExpandButton
