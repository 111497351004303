import { Flex, SystemProps, Text } from '@chakra-ui/react'

import PageLoadError from 'src/assets/illustration/ErrorGenericSVG'

type ErrorProps = {
  title?: string
  subtitle?: string
} & SystemProps

export const Error = ({
  title = "Hmm, this page couldn't load...",
  subtitle = "Try to reload the page, or check your internet. If that doesn't work, retry a bit later.",
  ...props
}: ErrorProps) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      gap={4}
      justifyContent="center"
      m={4}
      flexGrow={1}
      {...props}
    >
      <PageLoadError />
      <Text
        as="h5"
        className="thick"
        textAlign="center"
        data-testid="error-pageNotFound"
      >
        {title}
      </Text>
      <Text
        className="body-extra-small"
        textAlign="center"
        whiteSpace="pre-wrap"
      >
        {subtitle}
      </Text>
    </Flex>
  )
}

export default Error
