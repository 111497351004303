import { Flex, useMediaQuery } from '@chakra-ui/react'
import { ItList } from 'types/graphql'

import Large from './Large/Large'
import Small from './Small/Small'

const Header: React.FC<{
  children?: React.ReactNode
  headerButtonVariant?: string
  itList?: ItList
}> = ({ headerButtonVariant, itList, ...props }) => {
  const [isLargerThan745px] = useMediaQuery('(min-width: 745px)')
  return (
    <Flex
      py={3}
      as="header"
      alignItems="center"
      justifyContent="space-between"
      wrap="wrap"
      w="100%"
      {...props}
    >
      {isLargerThan745px ? (
        <Large headerButtonVariant={headerButtonVariant} itList={itList} />
      ) : (
        <Small headerButtonVariant={headerButtonVariant} itList={itList} />
      )}
    </Flex>
  )
}

export default Header
