import type { SVGProps } from 'react'
const SocialTwitch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.22656 8.1045L6.16568 5.6001H18.9977V14.366L15.2414 18.1209H12.4247L10.5475 20.0001H8.6684V18.1209H5.22656V8.1045ZM17.7459 6.85156H7.41675V15.9303H10.2353V17.8069L12.112 15.9303H15.5544L17.7459 13.7387V6.85156Z"
      fill="#6441A5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1124 13.1133H10.8604V9.35718H12.1124V13.1133ZM15.5548 13.1133H14.303V9.35718H15.5548V13.1133Z"
      fill="#6441A5"
    />
  </svg>
)
export default SocialTwitch
