import { MutableRefObject, useEffect, useState } from 'react'

import { InputProps as ChakraInputProps } from '@chakra-ui/react'
import _ from 'lodash'
import InputMask from 'react-input-mask'

import { useFormContext } from '@redwoodjs/forms'

import useField from 'src/lib/hooks/form/useField'

import { InputProps } from '.'

export type InputValue = string | number | string[]

export default function useInput(
  {
    icon,
    name,
    validation,
    mask,
    value,
    type,
    defaultValue,
    autoFocus,
    onClear,
    variant = 'outline',
    shouldRegister = true,
    format,
    withCounter,
    ...props
  }: InputProps,
  inputElRef: MutableRefObject<HTMLInputElement>
) {
  if (value) {
    throw new Error(
      "Can't use value prop, need to leave input uncontrolled, use defaultValue prop instead"
    )
  }
  const {
    formState: { defaultValues },
  } = useFormContext()
  if (defaultValues && defaultValue) {
    console.warn(
      `Input ${name} is using defaultValue - it is better to pass defaultValues to the appropriate Form.`
    )
  }
  const [showCounter, setShowCounter] = useState(false)
  const [count, setCount] = useState(0)
  const field = useField<string | number | readonly string[]>(name, {
    validation,
    defaultValue,
    shouldRegister,
    inputElRef,
  })
  useEffect(() => {
    setCount((field?.value as string)?.length || 0)
  }, [field?.value])

  const [showPassword, setShowPassword] = React.useState(false)

  const toggleShowPassword = () => setShowPassword(!showPassword)

  const clear = () => {
    field.onReset()
    onClear?.()
  }

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    const value = format ? format(ev.target.value) : ev.target.value
    props?.onChange?.(value)
    field.onChange(value)
  }

  const onFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
    setShowCounter(withCounter)
    props.onFocus?.(ev)
    field.onFocus()
  }

  const onBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    setShowCounter(false)
    props.onBlur?.(ev)
    field.onBlur()
  }

  const onPaste = (ev: React.ClipboardEvent<HTMLInputElement>) => {
    if (type != 'url') return

    ev.preventDefault()
    const newValue = (ev.clipboardData
      .getData('text')
      .match(/https?:\/\/[^\s]+/) || [''])[0]
    props?.onChange?.(newValue)
    field.onChange(newValue)
  }

  React.useEffect(() => {
    if (autoFocus) {
      setTimeout(field.focus, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus])

  const hasErrors = Boolean(field.error)

  const inputProps: Omit<InputProps, 'onChange'> &
    Pick<ChakraInputProps, 'onChange'> = {
    autoComplete: 'off',
    ...props,
    defaultValue: _.get(defaultValues, name),
    name,
    isInvalid: hasErrors,
    type: type === 'password' && showPassword ? 'text' : type,
    onChange,
    onPaste,
    onFocus,
    onBlur,
    /** @manual Props dedicated to mask. */
    mask,
    as: mask ? InputMask : undefined,
  }

  const title = props.title || props.placeholder
  const shifted = !!icon || props.borderRadius === 'full' || variant === 'round'

  return {
    toggleShowPassword,
    clear,
    inputProps,
    title,
    shifted,
    variant,
    icon,
    field,
    type,
    showPassword,
    hasErrors,
    showCounter,
    count,
  }
}
