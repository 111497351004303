import { useEffect } from 'react'

import { Box } from '@chakra-ui/react'

import { useFormContext, useWatch } from '@redwoodjs/forms'

import Input from 'src/components/widgets/Input/Input'
import InterceptKey from 'src/components/widgets/InterceptKey/InterceptKey'
import TextArea from 'src/components/widgets/TextArea/TextArea'
import { BIO_LINK_MAX_LENGTH, LINK_URL_VALIDATION } from 'src/lib/constants'

import { BuilderProps } from '../../../EditableList'
import { LinkFormData } from '../LinksEditableList'

const LinkBuilder = ({ name, onClose }: BuilderProps) => {
  const { setValue } = useFormContext<{
    items: Array<BuilderProps>
  }>()

  const linkUrl = useWatch<{ items: LinkFormData[] }, `items.${number}.url`>({
    name: `${name}.url`,
  })

  useEffect(() => {
    if (!linkUrl) {
      setValue(`${name}.name`, null)
    }
  }, [name, setValue, linkUrl])
  return (
    <Box w="100%">
      <Input
        type="url"
        name={`${name}.url` as const}
        placeholder="Link URL"
        data-testid="linkBuilder-linkUrlField"
        validation={LINK_URL_VALIDATION}
        w="100%"
      />

      {!!linkUrl?.length && (
        <Box mt={4}>
          <InterceptKey keyName="Enter" onKeyPressed={onClose}>
            <TextArea
              name={`${name}.name` as const}
              placeholder="Title"
              data-testid="linkBuilder-linkTitle"
              w="100%"
              minH={36}
              autoExpand
              countDown
              withCounter
              maxLength={BIO_LINK_MAX_LENGTH}
              hideCounterMax={60}
            />
          </InterceptKey>
        </Box>
      )}
    </Box>
  )
}

export default LinkBuilder
