import { Box, useBreakpointValue, BoxProps } from '@chakra-ui/react'
import { DateTime } from 'luxon'

import FullWidthDivider from 'src/components/widgets/FullWidthDivider/FullWidthDivider'

import Large from './Large/Large'
import Small from './Small/Small'

export const thisYear = DateTime.local().toFormat('yyyy')

const Footer = ({ ...props }: BoxProps) => {
  const isLg = useBreakpointValue({ sm: false, lg: true })
  if (isLg === undefined) return null
  return (
    <Box {...props}>
      <FullWidthDivider />
      {isLg ? <Large /> : <Small />}
    </Box>
  )
}

export default Footer
