import { Box } from '@chakra-ui/react'

import { itListTheme } from 'src/theme'
import { BrandColor } from 'src/types'

import ItListImage from '../../ItListImage/ItListImage'

const TimerImage = ({
  src,
  showProfileImage,
  duration,
  position,
  brandColor,
}: {
  src: string
  showProfileImage: boolean
  duration: number
  position?: number
  brandColor: BrandColor
}) => {
  const [brandHue, brandColorBrightness] = brandColor.split('.')

  return (
    <Box
      position="relative"
      minWidth="28px"
      h="28px"
      boxShadow={showProfileImage ? null : 'sm'}
      borderRadius="full"
    >
      {showProfileImage && (
        <ItListImage
          htmlHeight={28}
          htmlWidth={28}
          alt="profile image"
          position="absolute"
          src={src}
          borderRadius="full"
          boxSize="28px"
        />
      )}
      {!!position && !!duration && (
        <Box position="absolute" top={0} left={0}>
          <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
            <path
              d={describeArc(14, 14, 12.5, 0, (360 * position) / duration)}
              stroke={itListTheme.colors[brandHue][brandColorBrightness]}
              strokeWidth="3"
              fill="none"
              data-testid="AudioPlayerInline-TimeImage"
            />
          </svg>
        </Box>
      )}
    </Box>
  )
}

// https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
): { x: number; y: number } {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
): string {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ')

  return d
}

export default TimerImage
