import _ from 'lodash'
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useResetRecoilState,
} from 'recoil'
import {
  ItListChannel,
  Link,
  Channel,
  ItList,
  StripeSubscription,
  ItListItem,
} from 'types/graphql'
import { ZodIssue } from 'zod'

import { UISettings } from 'src/types'

export const uiSettingsState = atom<UISettings>({
  key: 'uiSettingsState',
  default: undefined,
})

export const creatorSubscriptionState = atom<Partial<StripeSubscription>>({
  key: 'creatorSubscriptionState',
  default: undefined,
})

export const creatorChannelsState = atom<Channel[]>({
  key: 'creatorChannelsState',
  default: undefined,
})

export const creatorLinksState = atom<Link[]>({
  key: 'creatorLinksState',
  default: undefined,
})

export const creatorDraftItListsState = atom<ItList[]>({
  key: 'creatorDraftItListsState',
  default: undefined,
})

export const creatorLiveItListsState = atom<ItList[]>({
  key: 'creatorLiveItListsState',
  default: undefined,
})

export const currentItListState = atom<ItList>({
  key: 'currentItListState',
  default: undefined,
})

export const currentItListValidationState = atom<{
  isSubmitted: boolean
  errors: ZodIssue[]
}>({
  key: 'currentItListValidationState',
  default: { isSubmitted: false, errors: [] },
})

export const initialItListItemsByIdState = selector<Record<number, ItListItem>>(
  {
    key: 'initialItListItemsByIdState',
    get: ({ get }) => {
      return (get(currentItListState)?.items || []).reduce(
        (acc, item) => ({ ...acc, [item.id]: _.cloneDeep(item) }),
        {}
      )
    },
  }
)

export const itListItemsFamily = atomFamily<ItListItem, number>({
  key: 'itListItemsFamily',
  default: selectorFamily({
    key: 'itListItemsFamily/Default',
    get:
      (id: number) =>
      ({ get }) =>
        get(initialItListItemsByIdState)[id],
  }),
})

export const currentItListItemsState = selector<ItListItem[]>({
  key: 'currentItListItemsState',
  get: ({ get }) =>
    (get(currentItListState)?.items || []).map((item) =>
      get(itListItemsFamily(item.id))
    ),
})

export const currentItListChannelsState = atom<ItListChannel[]>({
  key: 'currentItListChannelsState',
  default: undefined,
})

export const useResetCreatorState = () => {
  const resetCreatorChannels = useResetRecoilState(creatorChannelsState)
  const resetCreatorLinks = useResetRecoilState(creatorLinksState)
  const resetCreatorDraftItLists = useResetRecoilState(creatorDraftItListsState)
  const resetCreatorLiveItLists = useResetRecoilState(creatorLiveItListsState)
  const resetCurrentItList = useResetRecoilState(currentItListState)
  const resetCurrentItListValidation = useResetRecoilState(
    currentItListValidationState
  )
  const resetCurrentItListChannels = useResetRecoilState(
    currentItListChannelsState
  )
  return () => {
    resetCreatorChannels()
    resetCreatorLinks()
    resetCreatorDraftItLists()
    resetCreatorLiveItLists()
    resetCurrentItList()
    resetCurrentItListValidation()
    resetCurrentItListChannels()
  }
}
