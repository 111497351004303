import { atom, useResetRecoilState } from 'recoil'

import { EditorStatus } from 'src/components/features/EditItList'
import type { BrandColor, BasicInfoItem } from 'src/types'

export const currentVoiceTipState = atom<string>({
  key: 'currentVoiceTipState',
  default: undefined,
})

export const currentItemIdState = atom<number | BasicInfoItem>({
  key: 'currentItemIdState',
  default: undefined,
})

interface LightboxVideoModalState {
  vimeoUrl?: string
  youtubeUrl?: string
  brandColor?: BrandColor
}

export const lightboxVideoModalState = atom<LightboxVideoModalState>({
  key: 'lightboxVideoModalState',
  default: {},
})

export interface LightboxImageModalState {
  isOpen: boolean
  image: {
    src: string
    alt?: string
  }
}

export const lightboxImageModalState = atom<LightboxImageModalState>({
  key: 'lightboxImageModalState',
  default: {
    isOpen: false,
    image: { src: '', alt: '' },
  },
})

export const isChannelsModalOpenState = atom({
  key: 'isChannelsModalOpenState',
  default: undefined,
})

export const isLinksModalOpenState = atom({
  key: 'isLinksModalOpenState',
  default: undefined,
})

export const editorStatusState = atom<EditorStatus>({
  key: 'editorStatusState',
  default: 'ready',
})

export const useResetUiState = () => {
  const resetCurrentVoiceTip = useResetRecoilState(currentVoiceTipState)
  const resetCurrentItemId = useResetRecoilState(currentItemIdState)
  const resetIsChannelsModalOpen = useResetRecoilState(isChannelsModalOpenState)
  const resetIsLinksModalOpenState = useResetRecoilState(isLinksModalOpenState)
  const resetEditorStatusState = useResetRecoilState(editorStatusState)
  return () => {
    resetCurrentVoiceTip()
    resetCurrentItemId()
    resetIsChannelsModalOpen()
    resetIsLinksModalOpenState()
    resetEditorStatusState()
  }
}
