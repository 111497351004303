import { Flex, Text } from '@chakra-ui/react'
import {
  faExclamationCircle,
  faGripDotsVertical,
} from '@fortawesome/pro-solid-svg-icons'
import _ from 'lodash'

import Icon from 'src/components/widgets/Icon/Icon'
import useGetError from 'src/lib/hooks/form/useGetError'

import { EditableListElementProps } from '../../EditableList'

const Header: React.FC<{
  expanded: boolean
  formFieldName: `items.${number}`
  expandedHeaderLabel(index: number): string
  Element: React.FC<EditableListElementProps>
  index: number
  isDraggable: boolean
}> = ({
  expanded,
  formFieldName,
  expandedHeaderLabel,
  Element,
  index,
  isDraggable,
}) => {
  const error = useGetError(formFieldName)

  const sharedIconStyles = {
    boxSize: '24px',
    pl: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    overflow: 'visible',
    fontSize: '14px',
  }

  return (
    <Flex
      position="relative"
      alignItems="center"
      overflow="hidden"
      width="100%"
    >
      {expanded ? (
        <Text as="h5" className="thick" ml={4} data-testid="editItem-title">
          {expandedHeaderLabel(index)}
        </Text>
      ) : (
        <Flex alignItems="center" width="100%">
          {error ? (
            <Icon
              {...sharedIconStyles}
              icon={faExclamationCircle}
              color="danger"
            />
          ) : isDraggable ? (
            <Icon
              {...sharedIconStyles}
              textAlign="left"
              icon={faGripDotsVertical}
              color="graphite"
            />
          ) : null}
          <Element name={formFieldName} index={index} />
        </Flex>
      )}
    </Flex>
  )
}

export default Header
