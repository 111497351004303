import { Box, Divider, SystemProps } from '@chakra-ui/react'

const FullWidthDivider = ({ my, mt, mb, ...props }: SystemProps) => (
  <Box my={my} mt={mt} mb={mb} position="relative">
    <Divider
      position="absolute"
      bottom={0}
      w="100vw"
      left="calc(50% - 50vw)"
      color="vapor"
      {...props}
    />
  </Box>
)

export default FullWidthDivider
