import { Button, Flex, Link, Spacer, Text } from '@chakra-ui/react'

import { DevFatalErrorPage } from '@redwoodjs/web/dist/components/DevFatalErrorPage'

import PageLoadError from 'src/assets/illustration/ErrorGenericSVG'

export default DevFatalErrorPage ||
  (({ error }) => {
    // see https://github.com/redwoodjs/redwood/issues/9352#issuecomment-1791385077
    if (
      error?.message?.includes('Failed to fetch dynamically imported module') &&
      !window.location.href.includes('reload=1')
    ) {
      if (window.location.href.includes('?')) {
        window.location.href = window.location.href + '&reload=1'
      } else {
        window.location.href = window.location.href + '?reload=1'
      }
      return
    }

    return (
      <Flex
        direction="column"
        marginTop="40px"
        fontFamily="Plus Jakarta Sans, helvetica, arial, sans-serif"
        fontSize={{
          xs: '1rem',
          sm: '1.2rem',
          md: '1.4rem',
          lg: '1.6rem',
          xl: '1.8rem',
        }}
      >
        <Flex
          direction="column"
          alignItems="center"
          gap="16px"
          justifyContent="center"
          m="16px"
          flexGrow="4px"
        >
          <PageLoadError />
          <Text
            textAlign="center"
            fontSize={{ base: '1.6rem', md: '1.8rem' }}
            fontWeight="700"
            lineHeight={{ base: '1.25', md: '1.25' }}
          >
            Well this is awkward... 😅
          </Text>
          <Text
            fontSize={{ base: '1.2rem', md: '1.4rem' }}
            fontWeight="400"
            lineHeight="1.5"
            textAlign="center"
            whiteSpace="pre-wrap"
          >
            Something unexpected happened. Try reloading the page. If that
            doesn&apos;t work, try back a bit later or contact support if the
            issue persists.
          </Text>
        </Flex>

        <Spacer />
        <Flex
          marginTop="40px"
          alignSelf="center"
          fontSize={{ base: '1.2rem', md: '1.4rem' }}
          fontWeight="400"
          lineHeight="1.5"
          textDecoration="underline"
          gap="16px"
        >
          <Link href="mailto=help@itlist.com?subject=App%20Crash">
            <Button
              lineHeight="21px"
              fontWeight="600"
              transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
              outline="none"
              px="24px"
              paddingX="24px"
              py="12px"
              paddingY="12px"
              h="auto"
              height="auto"
              borderRadius="200px"
              _disabled={{
                pointerEvents: 'none',
                opacity: '0.7',
                boxShadow: 'none !important',
              }}
              color="white"
              bg="#FF425A"
              borderWidth="1.5px"
              borderColor="#FF425A"
              _hover={{
                bg: '#FF425A',
                boxShadow: '0 0 15px rgba(0, 0, 0, 0.15)',
              }}
              _active={{
                bg: '#F0374C',
                borderColor: '#F0374C',
              }}
            >
              Contact Support
            </Button>
          </Link>

          <Link href={process.env.HOME_URL}>
            <Button
              lineHeight="21px"
              fontWeight="600"
              transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
              outline="none"
              px="24px"
              paddingX="24px"
              py="12px"
              paddingY="12px"
              h="auto"
              height="auto"
              border="none"
              bg="transparent"
              _disabled={{
                pointerEvents: 'none',
                opacity: '0.7',
                boxShadow: 'none !important',
              }}
              color="#1A202C"
              textDecoration="underline"
              textUnderlineOffset="3"
              _hover={{
                boxShadow: 'none',
                color: '#575761',
              }}
              _active={{
                bg: 'transparent',
                color: '#1A202C',
              }}
            >
              Back to home
            </Button>
          </Link>
        </Flex>
      </Flex>
    )
  })
