import React from 'react'

import { Box, HStack } from '@chakra-ui/react'

import { useWatch } from '@redwoodjs/forms'

const ChannelElement: React.FC<{
  name: `items.${number}`
}> = ({ name }) => {
  const title = useWatch({ name: `${name}.name` })

  return (
    <HStack w="100%" overflow="hidden" mx={4}>
      <Box
        className="body-small"
        color="onyx"
        noOfLines={1}
        wordBreak="break-all"
      >
        {title}
      </Box>
    </HStack>
  )
}

export default ChannelElement
