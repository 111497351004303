import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Text: ComponentStyleConfig = {
  variants: {
    caption: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '166%',
    },
    strong: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px',
    },
    gradient: {
      bg: 'linear-gradient(45deg, #D38BCC 0%, #8ACBD4 50%, #77E2A5 100%) border-box',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      position: 'relative',
    },
  },
}
