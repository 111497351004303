import { useEffect, useRef } from 'react'

import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import _ from 'lodash'

import Icon from 'src/components/widgets/Icon/Icon'
import { colors } from 'src/theme/colors'
import { BrandColor } from 'src/types'

const CONTROL_KEYS = ['MetaLeft', 'ControlLeft', 'MetaRight', 'ControlRight']

const SearchInput: React.FC<{
  brandColor: BrandColor
  updateSearch: React.ChangeEventHandler<HTMLInputElement>
  isFocus: boolean
  setIsFocus: (isFocus: boolean) => unknown
  currentQuery: string
  setCurrentQuery: (query: string) => unknown
  incCurrentIndex: (direction: 1 | -1) => unknown
  pickCurrentResult: () => unknown
}> = ({
  brandColor,
  updateSearch,
  isFocus,
  setIsFocus,
  currentQuery,
  setCurrentQuery,
  incCurrentIndex,
  pickCurrentResult,
}) => {
  const ref = useRef<HTMLInputElement>()
  const ctrlOn = useRef(false)
  const resetQueryHandler = (ev: React.TouchEvent | React.MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    history.pushState(null, null, '#')
    setCurrentQuery('')
  }
  const keyHandler: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.code === 'Escape') {
      ev.currentTarget.blur()
    }
    if (ev.code === 'ArrowUp') {
      incCurrentIndex(-1)
    }
    if (ev.code === 'ArrowDown') {
      incCurrentIndex(1)
    }
    if (ev.code === 'Enter') {
      ref.current.blur()
      pickCurrentResult()
    }
  }

  const onKeyDown = (ev: KeyboardEvent) => {
    if (CONTROL_KEYS.includes(ev.code)) {
      ctrlOn.current = true
    } else if (ev.code === 'KeyF' && ctrlOn.current) {
      ev.preventDefault()
      ev.stopPropagation()
      ref.current.focus()
    }
  }

  const onKeyUp = (ev: KeyboardEvent) => {
    if (CONTROL_KEYS.includes(ev.code)) {
      ctrlOn.current = false
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  return (
    <InputGroup
      w="100%"
      position="relative"
      zIndex="docked"
      alignItems="center"
    >
      <InputLeftElement pointerEvents="none" m={3}>
        <Icon
          color={brandColor || 'brand.200'}
          fontSize="16px"
          icon={faMagnifyingGlass}
        />
      </InputLeftElement>
      <Input
        pl={10}
        variant="search"
        ref={ref}
        placeholder="Search contents..."
        value={currentQuery}
        className="body-small-emphasis"
        onFocus={() => {
          setIsFocus(true)
        }}
        onKeyDown={keyHandler}
        onChange={updateSearch}
        onBlur={() => {
          setIsFocus(false)
        }}
      />
      {currentQuery && (
        <InputRightElement
          mt="12px"
          pl={3}
          mr={3}
          color="graphite"
          bg={
            isFocus
              ? 'linear-gradient(to right, transparent, white 40%)'
              : `linear-gradient(to right, transparent, ${colors.vapor} 40%)`
          }
        >
          <Icon
            p={3}
            fontSize="16px"
            icon={faCircleXmark}
            onMouseDown={resetQueryHandler}
            onTouchStart={resetQueryHandler}
            cursor="pointer"
          />
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export default SearchInput
