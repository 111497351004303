import { AspectRatio, Box, Flex } from '@chakra-ui/react'

import { useYoutubePlayer } from './useYoutubePlayer'

const YoutubePlayer: React.FC<{
  url: string
  onReady?: () => void
  height?: number
  borderRadius?: number
}> = ({ url, onReady, borderRadius }) => {
  const { ref, width, videoId, isValid, aspectRatio } = useYoutubePlayer(url)

  if (!isValid) {
    return (
      <AspectRatio ratio={aspectRatio}>
        <Flex
          w="100%"
          className="body-extra-small-emphasis"
          color="stone"
          bg="air"
        >
          This video couldn&apos;t load.
        </Flex>
      </AspectRatio>
    )
  }
  return (
    <Box w="100%" ref={ref} overflow="hidden">
      {!!width && (
        <iframe
          title="ytplayer"
          width={width}
          height={width / aspectRatio}
          data-testid="socialProof-input-youtube"
          src={`https://www.youtube.com/embed/${videoId}?controls=1&playsinline=0&modestbranding=1`}
          frameBorder="0"
          style={{ borderRadius: borderRadius || 0 }}
          allowFullScreen
          onLoad={() => {
            onReady?.()
          }}
        ></iframe>
      )}
    </Box>
  )
}

export default YoutubePlayer
