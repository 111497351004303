import { atom, useResetRecoilState } from 'recoil'

export const backUrlState = atom<string>({
  key: 'backUrlState',
  default: '/',
})

export const useResetNavigationState = () => {
  const resetBackUrl = useResetRecoilState(backUrlState)
  return () => {
    resetBackUrl()
  }
}
