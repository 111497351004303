import { ChannelInput } from 'types/graphql'

import EditableList from '../../EditableList'

import AddChannelButton from './AddChannelButton/AddChannelButton'
import ChannelBuilder from './ChannelBuilder/ChannelBuilder'
import ChannelElement from './ChannelElement/ChannelElement'

export type ChannelFormData = Omit<ChannelInput, 'order'>

const ChannelsEditableList = () => (
  <EditableList
    isEmpty={isEmpty}
    expandedHeaderLabel={() => 'Edit Topic Channel'}
    AddItemButton={AddChannelButton}
    Builder={ChannelBuilder}
    Element={ChannelElement}
  />
)

export default ChannelsEditableList

const isEmpty = (item: ChannelFormData) =>
  !item?.name || item.name.trim().length === 0
