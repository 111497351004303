import { useState } from 'react'

import { Channel, SetCreatorChannelsInput } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useCurrentUser } from '../auth/useCurrentUser'

type SetCreatorChannelResponse = {
  setCreatorChannels: Pick<Channel, 'id' | 'name' | 'order'>[]
}

type SetCreatorChannelRequest = {
  input: SetCreatorChannelsInput
}

const SET_CREATOR_CHANNELS_MUTATION = gql`
  mutation SetCreatorChannelsMutation($input: SetCreatorChannelsInput!) {
    setCreatorChannels(input: $input) {
      id
      name
      order
    }
  }
`

export const useSetCreatorChannels = (
  options?: GraphQLMutationHookOptions<
    SetCreatorChannelResponse,
    SetCreatorChannelRequest
  >
) => {
  const { id: sessionUserId } = useCurrentUser()
  const [mutation, { loading, data }] = useMutation<
    SetCreatorChannelResponse,
    SetCreatorChannelRequest
  >(SET_CREATOR_CHANNELS_MUTATION, options)

  const setCreatorChannels = async (input: SetCreatorChannelsInput) =>
    mutation({ variables: { input } })

  const [error, setError] = useState<string>()

  const submit = async ({ items }: { items: Omit<Channel, 'order'>[] }) => {
    const channels = items.map((item, index) => ({
      id: item.id,
      name: item.name,
      order: index + 1,
    }))

    const response = await setCreatorChannels({
      creatorId: sessionUserId,
      channels,
    })
    if (response.errors) {
      setError('Update channels failed')
    }
    return response
  }

  return {
    mutation: setCreatorChannels,
    loading,
    error,
    data,
    submit,
  }
}
