import React from 'react'

import { LinkInput } from 'types/graphql'

import EditableList from 'src/components/widgets/EditableList/EditableList'

import AddLinkButton from './AddLinkButton/AddLinkButton'
import LinkBuilder from './LinkBuilder/LinkBuilder'
import LinkElement from './LinkElement/LinkElement'

export type LinkFormData = Omit<LinkInput, 'order'>

const LinksEditableList = () => (
  <EditableList
    isEmpty={isEmpty}
    expandedHeaderLabel={() => 'Edit Link'}
    AddItemButton={AddLinkButton}
    Builder={LinkBuilder}
    Element={LinkElement}
  />
)

export default LinksEditableList

function isEmpty(item: LinkFormData): boolean {
  return (
    (!item.url || item.url.trim().length === 0) &&
    (!item.name || (item.name || '').trim().length === 0)
  )
}
