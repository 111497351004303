import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import { ItList } from 'types/graphql'

import { PageSectionName } from 'src/lib/hooks/analytics/useAnalytics'

import HeaderSearch from './HeaderSearch/HeaderSearch'
import useHeaderActions from './useHeaderActions'

const HeaderActions: React.FC<
  {
    section?: PageSectionName
    label?: string
    headerButtonVariant?: string
    itList?: ItList
  } & Omit<ButtonProps, 'onClick'> &
    Pick<ButtonProps, 'onClick'>
> = ({
  onClick,
  label,
  section = 'header',
  headerButtonVariant,
  itList,
  ...props
}) => {
  const { creatorButton, clickHandler, showSearch } = useHeaderActions(
    label,
    section,
    onClick,
    itList?.creator?.id
  )

  let content: React.ReactNode | null = null
  if (creatorButton.show) {
    content = creatorButton.Content || (
      <Button
        variant={creatorButton.variant || headerButtonVariant || 'primary'}
        data-testid="become-a-creator-button"
        onClick={clickHandler}
        {...props}
      >
        {creatorButton.label}
      </Button>
    )
  }

  return (
    <Flex gap={4} justifyContent="center" alignItems="center">
      {showSearch && <HeaderSearch itList={itList} />}
      {content}
    </Flex>
  )
}

export default HeaderActions
