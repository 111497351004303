import { useEffect } from 'react'

import { Flex, Box, ChakraProps, Spacer } from '@chakra-ui/react'
import { ItList } from 'types/graphql'

import { useParams } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import Footer from 'src/components/layout/Footer/Footer'
import Header from 'src/components/layout/Header/Header'
import { toast } from 'src/components/widgets/Toaster'
import { pause } from 'src/lib/audioPlayer'

type BaseProps = {
  children?: React.ReactNode
} & ChakraProps

type MainLayoutProps = {
  itList?: ItList
  withFooter?: boolean
  headerButtonVariant?: string
  unauthenticated?: string
  private?: boolean
  roles?: string[] | string
} & BaseProps

const CenteredBox = ({ children, ...props }: BaseProps) => {
  return (
    <Flex
      position="relative"
      direction="column"
      boxSizing="border-box"
      maxW={{
        base: '100%',
        xl: '1200px',
      }}
      m={{
        base: '0',
        xl: '0 auto',
      }}
      px={{ base: 4, lg: 6 }}
      flexGrow={1}
      {...props}
    >
      {children}
    </Flex>
  )
}

const MainLayout = ({
  children,
  withFooter = true,
  headerButtonVariant,
  itList,
  // avoiding passing down props that chakra don't recognize
  unauthenticated: _u,
  private: _p,
  roles: _r,
  ...props
}: MainLayoutProps) => {
  const { loading } = useAuth()
  const { flashError, flashInfo } = useParams()
  useEffect(() => {
    if (loading) return
    if (flashError) {
      toast('error', flashError)
    }
    if (flashInfo) {
      toast('success', flashInfo)
    }
  }, [flashError, flashInfo, loading])
  useEffect(() => () => pause(), [])
  return loading ? null : (
    <>
      <Box
        borderBottom="1px solid"
        borderColor="vapor"
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex="overlay"
        bg="white"
      >
        <CenteredBox>
          <Header headerButtonVariant={headerButtonVariant} itList={itList} />
        </CenteredBox>
      </Box>
      <Flex
        overflowX="hidden"
        zIndex="base"
        pt="68px"
        minH="calc(100vh - 68px)"
      >
        <CenteredBox {...props} data-testid="center-box">
          {children}
          {withFooter && (
            <>
              <Spacer />
              <Footer />
            </>
          )}
        </CenteredBox>
      </Flex>
    </>
  )
}

export default MainLayout
