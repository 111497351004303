import { cssVar } from '@chakra-ui/react'
import type { ComponentStyleConfig } from '@chakra-ui/theme'

const $width = cssVar('switch-track-width')
const $height = cssVar('switch-track-height')

//useful example from the default theme here:
//https://github.com/chakra-ui/chakra-ui/blob/v1/packages/theme/src/components/switch.ts

export const Switch: ComponentStyleConfig = {
  baseStyle: ({ colorScheme }) => ({
    thumb: {},
    track: {
      _checked: {
        bg: colorScheme,
      },
    },
    container: {},
  }),

  sizes: {
    xl: {
      container: {
        [$width.variable]: '4.2rem',
        [$height.variable]: '2.5rem',
      },
    },
  },
  defaultProps: {
    size: 'xl',
    colorScheme: 'successful',
  },
}
