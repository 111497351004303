import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      padding: '11px 24px',
      py: '24px',
      _placeholder: {
        color: 'graphite',
      },
      boxShadow: 'none',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        borderColor: 'onyx',
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderRadius: '8px',
        borderColor: 'sandstonePlus',
        boxShadow: 'none',
        _focus: {
          bg: 'white',
          borderWidth: '1px',
          borderColor: 'onyx',
          boxShadow: 'none',
        },
        _focusVisible: {
          bg: 'white',
          borderWidth: '1px',
          borderColor: 'onyx',
          boxShadow: 'none',
        },
        _invalid: {
          borderColor: 'danger',
          boxShadow: 'none',
        },
        _hover: {
          borderColor: 'onyx',
        },
      },
    },
    filled: {
      field: {
        borderRadius: '8px',
        bg: 'vapor',
        textColor: 'onyx',
        boxShadow: 'none',
        borderWidth: '0',
        _placeholder: {
          color: 'stone',
        },
        _focus: {
          bg: 'air',
          boxShadow: 'none',
        },
        _focusVisible: {
          bg: 'air',
          boxShadow: 'none',
        },
        _invalid: {
          borderColor: 'danger',
          boxShadow: 'none',
        },
        _hover: {
          bg: 'air',
        },
      },
    },
    round: {
      field: {
        border: '2px solid',
        borderRadius: 'full',
        _focus: {
          border: '2px solid',
          borderRadius: 'full',
        },
        _focusVisible: {
          border: '2px solid',
          borderRadius: 'full',
        },
      },
    },
    search: {
      field: {
        color: 'stone',
        bg: 'vapor',
        borderRadius: 'full',
        _focus: {
          bg: 'white',
        },
      },
    },
  },
}
