import type { SVGProps } from 'react'
const SocialVimeo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="white"
    />
    <path
      d="M18.7929 9.68105C18.7288 11.0827 17.7499 13.0017 15.856 15.4379C13.8983 17.9817 12.2417 19.2541 10.8872 19.2541C10.0474 19.2541 9.33722 18.4798 8.75686 16.93C8.36945 15.5094 7.98238 14.089 7.5948 12.6684C7.16372 11.1194 6.70176 10.3441 6.20725 10.3441C6.09948 10.3441 5.72253 10.5708 5.07708 11.0221L4.3999 10.1497C5.11062 9.52546 5.81154 8.90109 6.50116 8.27589C7.44901 7.45724 8.16105 7.02633 8.63531 6.98299C9.75551 6.87522 10.4453 7.64106 10.7042 9.28053C10.984 11.0497 11.1778 12.1498 11.2864 12.5804C11.6099 14.0481 11.9654 14.7813 12.3534 14.7813C12.6545 14.7813 13.1075 14.3054 13.7113 13.3529C14.3139 12.401 14.637 11.6767 14.6805 11.1789C14.7667 10.3574 14.4436 9.94558 13.7113 9.94558C13.3665 9.94558 13.011 10.025 12.6454 10.1817C13.3533 7.86408 14.7053 6.73839 16.7009 6.80265C18.1805 6.84599 18.8784 7.80546 18.7929 9.68105"
      fill="#31AFD8"
    />
  </svg>
)
export default SocialVimeo
