import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'

import { get } from 'lodash'
import _ from 'lodash'
import { FieldError } from 'react-hook-form'

import {
  ErrorOption,
  RegisterOptions,
  useFormContext,
  UseFormGetFieldState,
  useWatch,
} from '@redwoodjs/forms'

import useGetError from './useGetError'

export interface UseFieldProps<FieldValue, HTMLElement = HTMLInputElement> {
  validation?: RegisterOptions
  defaultValue?: FieldValue
  shouldRegister?: boolean
  inputElRef?: MutableRefObject<HTMLElement>
}

export type UseFieldReturn<FieldValue, HTMLElement = HTMLInputElement> = {
  error: FieldError
  isTouched: boolean
  hasFocus: boolean
  value: FieldValue
  fieldState: UseFormGetFieldState<FieldValue>
  onBlur: () => void
  onChange: (data: FieldValue) => void
  onError: (err: FieldError) => void
  onFocus: () => void
  onReset: () => void
  clearError: () => void
  focus: () => void
  blur: () => void
  ref: MutableRefObject<HTMLElement>
}

export default function useField<
  FieldValue extends string | number | readonly string[] | boolean,
  HTMLElement extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement
>(
  name: string,
  {
    validation,
    defaultValue,
    inputElRef,
    shouldRegister = true,
  }: UseFieldProps<FieldValue, HTMLElement> = {
    validation: undefined,
    defaultValue: undefined,
  }
): UseFieldReturn<FieldValue, HTMLElement> {
  const newRef = useRef<HTMLElement>()
  const {
    register,
    setError,
    setValue,
    getFieldState,
    clearErrors,
    formState: { touchedFields, isSubmitted },
  } = useFormContext()

  const [hasFocus, setHasFocus] = useState<boolean>(false)

  const value = useWatch({ name, defaultValue }) || ''

  const error = useGetError(name)

  const isTouched = useMemo(
    () => Boolean(get(touchedFields, name)),
    [name, touchedFields]
  )

  const ref = inputElRef || newRef

  const updateRefValue = (value: FieldValue) => {
    if (ref.current && value !== ref.current.value) {
      ref.current.value = value as string
    }
  }

  // We need the ref, so applying the recipe from
  // https://react-hook-form.com/faqs/#Whatifyoudonthaveaccesstoref
  useEffect(() => {
    shouldRegister && register(name, validation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Updating uncontrolled input
  useEffect(() => {
    updateRefValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onBlur = () => {
    setHasFocus(false)
  }

  const onFocus = () => {
    setHasFocus(true)
  }

  const onError = (error: ErrorOption) => {
    shouldRegister && setError(name, error)
  }

  const onChange = (data: FieldValue) => {
    if (shouldRegister) {
      setValue(name, data, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: isSubmitted,
      })
    } else {
      updateRefValue(data)
    }
  }

  const onReset = () => {
    shouldRegister && setValue(name, '')
    if (ref.current) ref.current.value = ''
  }

  return {
    error,
    isTouched,
    hasFocus,
    value,
    fieldState: () => getFieldState(name),
    onBlur,
    onChange,
    onError,
    onFocus,
    onReset,
    clearError: () => clearErrors(name),
    ref,
    focus: () => {
      ref.current?.focus()
    },
    blur: () => {
      ref.current?.blur()
    },
  }
}
