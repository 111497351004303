import { Flex, Box, Button, Spacer } from '@chakra-ui/react'

import { Link, routes } from '@redwoodjs/router'

import { useLogout } from 'src/lib/hooks/form/useLogout'

const DesktopNav = () => {
  const logout = useLogout()
  return (
    <Flex w="100%">
      <Box display={{ base: 'none', md: 'block' }} as="nav">
        <Link to={routes.adminHome()}>
          <Button variant="ghost">Home</Button>
        </Link>
        <Link to={routes.waitlistTable()}>
          <Button variant="ghost">Waitlist</Button>
        </Link>
        <Link to={routes.creatorsTable()}>
          <Button variant="ghost">Creators</Button>
        </Link>
        <Link to={routes.agencyTable()}>
          <Button variant="ghost">Agencies</Button>
        </Link>
      </Box>
      <Spacer />
      <Button data-testid="menu-logout-button" onClick={logout}>
        Logout
      </Button>
    </Flex>
  )
}

export default DesktopNav
