import { SystemProps, Text } from '@chakra-ui/react'

export type InputCounterProps = {
  count: number
  max: number
  hideCounterMax?: number
  noWarningMax?: number
  countDown?: boolean
} & SystemProps

const InputCounter = ({
  count,
  max,
  noWarningMax,
  hideCounterMax = 0,
  countDown,
  ...props
}: InputCounterProps) => {
  if (count < hideCounterMax) return null
  return (
    <Text
      textAlign="right"
      color={noWarningMax && count >= noWarningMax ? 'danger' : 'onyx'}
      className="body-extra-small-emphasis"
      {...props}
      data-testid="input-counter"
    >
      {countDown ? max - count : count}/{max}
    </Text>
  )
}

export default InputCounter
