import { memo } from 'react'

import { AccordionButton, AccordionPanel } from '@chakra-ui/react'
import { MotionConfig } from 'framer-motion'
import _ from 'lodash'

import InterceptKey from 'src/components/widgets/InterceptKey/InterceptKey'

import {
  EditableListElementProps,
  BuilderProps,
  ActionMenuProps,
} from '../EditableList'

import ExpandButton from './ExpandButton/ExpandButton'
import Header from './Header/Header'
import TrashButton from './TrashButton/TrashButton'
import useEditItem from './useEditItem'

export type EditItemProps = {
  Element: React.FC<EditableListElementProps>
  OverflowMenu?: React.FC<ActionMenuProps>
  Builder: React.FC<BuilderProps>
  expandedHeaderLabel(index: number): string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isEmpty: (item: any) => boolean
  onClose: () => void
  onRemove: (index?: number) => void
  index: number
  expanded: boolean
  isDraggable: boolean
  py?: number
}

const EditItem = ({
  Element,
  OverflowMenu,
  Builder,
  expandedHeaderLabel,
  isEmpty,
  onClose,
  onRemove,
  index,
  expanded,
  isDraggable,
  py = 5,
}: EditItemProps) => {
  const { formFieldName, closeAccordionHandler, clickRemoveHandler } =
    useEditItem(expanded, index, isEmpty, onClose, onRemove)
  const ActionButton = OverflowMenu || TrashButton

  return (
    <MotionConfig transition={{ duration: 0.1 }}>
      <AccordionButton
        _hover={{ background: 'none' }}
        as="div"
        px={5}
        py={py}
        pl={0}
        onClick={closeAccordionHandler}
        data-testid="editItem-button"
      >
        <Header
          expanded={expanded}
          formFieldName={formFieldName}
          expandedHeaderLabel={expandedHeaderLabel}
          index={index}
          Element={Element}
          isDraggable={isDraggable}
        />
        {expanded && (
          <ActionButton onClickRemove={clickRemoveHandler} index={index} />
        )}
        <ExpandButton expanded={expanded} />
      </AccordionButton>
      <AccordionPanel pt={1}>
        <InterceptKey keyName="Enter">
          <Builder name={formFieldName} index={index} onClose={onClose} />
        </InterceptKey>
      </AccordionPanel>
    </MotionConfig>
  )
}

export default memo(EditItem)
