import { Button, ButtonProps } from '@chakra-ui/react'

import { routes, navigate } from '@redwoodjs/router'

import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'
import { useSetBackUrl } from 'src/lib/hooks/useSetBackUrl'

const LoginButton = (props: ButtonProps) => {
  const { isAuthenticated } = useCurrentUser()
  const setBackUrl = useSetBackUrl()
  const clickHandler: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    setBackUrl()
    navigate(routes.signin())
    props.onClick?.(ev)
  }
  return (
    !isAuthenticated && (
      <Button
        variant="text"
        color="brand.200"
        paddingX="0"
        {...props}
        onClick={clickHandler}
        data-testid="sign-in-header-item"
      >
        Sign in
      </Button>
    )
  )
}

export default LoginButton
