import { useEffect, useMemo, useRef, useState } from 'react'

import _ from 'lodash'

export const useDebouncedResizeListener = (debounce = 300) => {
  const ref = useRef()
  const [rect, setRect] = useState<DOMRectReadOnly>()
  const debouncedSetRect = useMemo(
    () => _.debounce(setRect, debounce),
    [debounce, setRect]
  )
  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        setRect(entries[0].contentRect)
      })
      observer.observe(ref.current)
      return () => {
        debouncedSetRect?.cancel()
        observer.disconnect()
      }
    }
  }, [debouncedSetRect])

  return { ref, rect }
}
