import type { SVGProps } from 'react'
const SocialYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="white"
    />
    <path
      d="M18.1326 9.03659C17.9854 8.47108 17.5517 8.02577 17.0009 7.87464C16.0028 7.59998 12.0001 7.59998 12.0001 7.59998C12.0001 7.59998 7.99741 7.59998 6.99923 7.87464C6.44846 8.02577 6.01472 8.47108 5.86752 9.03659C5.6001 10.0615 5.6001 12.2 5.6001 12.2C5.6001 12.2 5.6001 14.3384 5.86752 15.3634C6.01472 15.9289 6.44846 16.3742 6.99923 16.5254C7.99741 16.8 12.0001 16.8 12.0001 16.8C12.0001 16.8 16.0028 16.8 17.0009 16.5254C17.5517 16.3742 17.9854 15.9289 18.1326 15.3634C18.4001 14.3384 18.4001 12.2 18.4001 12.2C18.4001 12.2 18.4001 10.0615 18.1326 9.03659"
      fill="#FF0000"
    />
    <path d="M10.7998 14.4V10.4L13.9998 12.4001L10.7998 14.4Z" fill="white" />
  </svg>
)
export default SocialYoutube
