import { navigate, routes, useLocation } from '@redwoodjs/router'

import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

const Onboarding = ({ children }: { children?: React.ReactNode }) => {
  const { pathname } = useLocation()
  const { creatorStatus } = useCurrentUser()
  React.useEffect(() => {
    if (creatorStatus === 'onboarding' && !pathname.match(/^\/onboarding/)) {
      navigate(routes.onboarding())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorStatus])

  return <>{children}</>
}

export default Onboarding
