import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Slider: ComponentStyleConfig = {
  baseStyle: {
    thumb: {
      boxShadow:
        'rgb(0 0 0 / 12%) 0px 0.5px 4px, rgb(0 0 0 / 12%) 0px 6px 13px !important',
    },
  },
}
