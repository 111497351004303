import { Box, Flex } from '@chakra-ui/react'
import { AnimatePresence, motion, MotionConfig } from 'framer-motion'

import { formatTime } from 'src/lib/utils'

const WithDuration: React.FC<{
  children: React.ReactNode
  showDuration: boolean
  duration?: number
}> = ({ showDuration, duration, children }) => {
  if (!showDuration) return <>{children}</>
  return (
    <Flex gap={4} alignItems="center">
      {!!duration && (
        <MotionConfig transition={{ duration: 0.1, delay: 0.15 }}>
          <AnimatePresence>
            <Box
              as={motion.button}
              animate={{ width: 'auto', paddingRight: '12px' }}
              initial={{ width: 0, paddingRight: 0 }}
              exit={{ width: 0, paddingRight: 0 }}
              className="body-extra-small"
              color="stone"
              borderRight="1px solid"
              borderColor="sandstone"
              h="14px"
              lineHeight={1}
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {formatTime(duration)}
            </Box>
          </AnimatePresence>
        </MotionConfig>
      )}
      {children}
    </Flex>
  )
}

export default WithDuration
