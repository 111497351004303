import { DateTime } from 'luxon'

const VALIDATION_PERIOD_DAYS = 30

export type StoredAffiliation = {
  id: number
  date: number
}

export function set(key: string, id: number) {
  if (!getParsedValue(key)) {
    localStorage.setItem(
      key,
      JSON.stringify({
        id,
        date: DateTime.local().toMillis(),
      })
    )
  }
}

export function get(key: string): number | null {
  return getParsedValue(key)?.id
}

function getParsedValue(key: string): StoredAffiliation | null {
  const savedStringValue = localStorage.getItem(key)
  const now = DateTime.local()
  if (savedStringValue) {
    try {
      const savedValue = JSON.parse(savedStringValue) as StoredAffiliation
      if (getExpirationDate(savedValue.date) > now) {
        return savedValue
      }
    } catch (err: unknown) {
      console.error(
        `error parsing localStorage's ${key}: ${savedStringValue}`,
        err
      )
    }
  }
  return null
}

function getExpirationDate(date: number): DateTime {
  return DateTime.fromMillis(date).plus({
    days: VALIDATION_PERIOD_DAYS,
  })
}
