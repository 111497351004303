import { Button, ButtonProps } from '@chakra-ui/react'
import _ from 'lodash'

import { getColorHexValue } from 'src/lib/utils'
import { ThemeColor } from 'src/types'

export type BrandButtonProps = {
  brandColor: ThemeColor
  children?: React.ReactNode
} & ButtonProps & { variant?: 'primary' | 'secondary' | 'light' | 'disabled' }

const BrandButton: React.FC<BrandButtonProps> = ({
  children,
  brandColor,
  variant = 'primary',
  ...props
}) => {
  const colorHex = getColorHexValue(brandColor)
  const theme = {
    primary: {
      bg: brandColor,
      color: 'white',
      _hover: {
        bg: brandColor,
        boxShadow:
          '0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 8px 16px rgba(22, 34, 51, 0.11)',
      },
      _active: {
        bg: brandColor,
        filter: 'brightness(0.95)',
      },
    },
    secondary: {
      bg: 'white',
      color: brandColor,
      borderColor: brandColor,
      borderWidth: '1px',
      _hover: {
        bg: 'white',
        boxShadow:
          '0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 8px 16px rgba(22, 34, 51, 0.11)',
      },
      _active: {
        bg: 'white',
        filter: 'brightness(0.95)',
      },
    },
    light: {
      bg: `${colorHex}1A`,
      color: brandColor,
      _hover: {
        bg: `${colorHex}33`,
      },
      _active: {
        bg: `${colorHex}1A`,
      },
    },
    disabled: {
      variant: 'disabled',
    },
  }

  return (
    <Button {...props} {...theme[variant]} isDisabled={variant === 'disabled'}>
      {children}
    </Button>
  )
}
export default BrandButton
