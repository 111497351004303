import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Menu: ComponentStyleConfig = {
  variants: {
    header: {
      button: {
        transition: 'all 0.2s',
        fontSize: '1.4rem',
        fontWeight: 400,
      },
      list: {
        padding: '12px',
        boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.2) !important',
        mt: '20px',
        borderRadius: '16px',
        width: '256px',
      },
      item: {
        padding: '12px 24px',
        borderRadius: '8px',
        _focus: {
          bg: 'transparent',
        },
        _hover: {
          bg: 'air',
        },
      },
      divider: {
        borderBottomColor: 'sandstone',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
      },
    },
    context: {
      button: {
        fontSize: '12px',
        borderRadius: 'full',
        bg: 'white',
      },
      list: {
        borderRadius: '12px',
        padding: '12px',
        minWidth: 'auto',
      },
      item: {
        padding: '8px 16px',
        borderRadius: '8px',
        _focus: {
          bg: 'transparent',
        },
        _hover: {
          bg: 'air',
        },
        fontSize: '14px',
        fontWeight: '600',
      },
    },
  },
}
