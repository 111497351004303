import type { SVGProps } from 'react'
const SocialPinterest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 23.9016C18.6274 23.9016 24 18.5511 24 11.9508C24 5.35056 18.6274 0 12 0C5.37258 0 0 5.35056 0 11.9508C0 18.5511 5.37258 23.9016 12 23.9016Z"
      fill="#E60019"
    />
    <path
      d="M12 0C5.37284 0 0 5.35082 0 11.9508C0 17.0164 3.16049 21.3443 7.62469 23.0852C7.51605 22.141 7.42716 20.6852 7.6642 19.6525C7.88148 18.718 9.06667 13.7115 9.06667 13.7115C9.06667 13.7115 8.71111 12.9934 8.71111 11.941C8.71111 10.2787 9.67901 9.03934 10.884 9.03934C11.9111 9.03934 12.4049 9.80656 12.4049 10.7213C12.4049 11.7443 11.7531 13.2787 11.4074 14.7049C11.121 15.8951 12.0099 16.8689 13.1852 16.8689C15.3185 16.8689 16.958 14.6262 16.958 11.4C16.958 8.5377 14.8938 6.54098 11.9407 6.54098C8.52346 6.54098 6.51852 9.08852 6.51852 11.7246C6.51852 12.7475 6.91358 13.8492 7.40741 14.4492C7.50617 14.5672 7.51605 14.6754 7.48642 14.7934C7.39753 15.1672 7.19012 15.9836 7.15062 16.1508C7.10123 16.3672 6.97284 16.4164 6.74568 16.3082C5.24444 15.6098 4.30617 13.4361 4.30617 11.6754C4.30617 7.9082 7.05185 4.4459 12.237 4.4459C16.3951 4.4459 19.6346 7.39672 19.6346 11.3508C19.6346 15.4721 17.0272 18.7869 13.4123 18.7869C12.1975 18.7869 11.0519 18.1574 10.6667 17.4098C10.6667 17.4098 10.0642 19.6918 9.91605 20.2525C9.64938 21.2951 8.91852 22.5934 8.42469 23.3902C9.55062 23.7344 10.7358 23.9213 11.9802 23.9213C18.6074 23.9213 23.9802 18.5705 23.9802 11.9705C24 5.35082 18.6272 0 12 0Z"
      fill="white"
    />
  </svg>
)
export default SocialPinterest
