import { PropsWithChildren } from 'react'

import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'

import Loader from 'src/components/widgets/Loader/Loader'

const LightboxModal: React.FC<
  PropsWithChildren<{
    isOpen: boolean
    onClose: () => void
    loading?: boolean
  }>
> = ({ children, isOpen, onClose, loading = false }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay bgColor="rgba(0,0,0,0.9)" />
    <ModalContent
      h="100%"
      maxW="100%"
      bgColor="transparent"
      boxShadow="none"
      m={0}
      p={0}
      position="relative"
      pointerEvents="none"
    >
      <ModalCloseButton
        variant="lightboxModal"
        position="absolute"
        pointerEvents="auto"
        top={0}
        left={0}
      />
      <ModalBody p={0} bgColor="transparent">
        {loading && (
          <Center w="100%" h="100%" position="absolute">
            <Loader />
          </Center>
        )}
        <Center w="100%" h="100%" p={{ base: 0, md: 12 }}>
          {children}
        </Center>
      </ModalBody>
    </ModalContent>
  </Modal>
)

export default LightboxModal
