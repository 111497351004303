import { Box, Center, Flex, Spinner, Text } from '@chakra-ui/react'

import { LoaderProps } from '..'

const CenteredSpinner = ({
  size = 'xl',
  color = 'brand.200',
  padding,
  bg,
  borderRadius,
  thickness = '2px',
  label,
  ...props
}: Omit<LoaderProps, 'show' | 'inline'>) => (
  <Center
    w="100%"
    h="100%"
    zIndex="modal"
    my={20}
    {...props}
    data-testid="loader"
  >
    <Box padding={padding} bg={bg} borderRadius={borderRadius}>
      <Flex direction="column" gap={2} alignItems="center">
        <Spinner
          size={size}
          opacity={1}
          color={color}
          thickness={thickness}
        ></Spinner>
        {label && (
          <Text className="body-extra-small" color="stone">
            {label}
          </Text>
        )}
      </Flex>
    </Box>
  </Center>
)

export default CenteredSpinner
