import { Box, Button, Flex, useBreakpointValue } from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faCircleInfo,
  faPlug,
} from '@fortawesome/pro-regular-svg-icons'

import {
  Toaster as RedwoodToaster,
  toast as redwoodToast,
  ToastOptions,
} from '@redwoodjs/web/toast'

import { isString } from 'src/lib/typeGuards'
import { itListTheme } from 'src/theme'

import Icon, { IconProps } from '../Icon/Icon'
import Loader from '../Loader/Loader'

const Toaster = () => {
  const isSm = useBreakpointValue({ sm: true, md: false })
  if (isSm === undefined) return null
  return (
    <RedwoodToaster
      position="top-center"
      toastOptions={{
        duration: 2500,
        style: {
          color: itListTheme.colors.white,
          background: '#666',
          minWidth: isSm ? '343px' : '436px',
          zIndex: 1700, // chakraUiTheme.zIndices.toast is failing here
        },
        success: { style: { background: itListTheme.colors.successful } },
        error: { style: { background: itListTheme.colors.danger } },
      }}
    />
  )
}

const typeCheckString = (str: unknown, propName: string) => {
  if (!isString(str)) {
    console.error(
      `Toast was not called with a string for ${propName}: ${propName}=${str}`
    )
  }
}

type ToastType = 'success' | 'error' | 'info' | 'loading'

type ToastAction = {
  label: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

type ToastPromiseConfig = {
  loading?: Pick<CustomToastProps, 'title' | 'subtitle'>
  success?: Pick<CustomToastProps, 'title' | 'subtitle' | 'action'>
  error?: Pick<CustomToastProps, 'title' | 'subtitle' | 'action'>
}

type CustomToastProps = {
  type?: ToastType
  title?: string
  subtitle?: string
  showIcon?: boolean
  iconLeft?: IconProp
  iconRight?: IconProp
  hideIcons?: boolean
  action?: ToastAction
  id?: string
}

const ToastBG = ({ bg, show }: { bg: string; show: boolean }) => (
  <Box
    position="absolute"
    zIndex="toast"
    transition="all 0.3s ease-in-out"
    opacity={show ? 1 : 0}
    borderRadius="full"
    bg={bg}
    top={0}
    left={0}
    right={0}
    bottom={0}
  ></Box>
)

const CustomToast = ({
  type = 'info',
  title,
  subtitle,
  iconLeft,
  iconRight,
  hideIcons,
  action,
  id,
}: CustomToastProps) => {
  // Forcing type check here because exception coming from try...catch are not properly typed
  // and this causes issues that are not obvious to detect
  title && typeCheckString(title, 'title')
  subtitle && typeCheckString(subtitle, 'subtitle')

  if (iconLeft && iconRight)
    console.warn(
      'Warning: The toast received both iconLeft and iconRight as props - iconLeft will be hidden.'
    )

  const config: Record<
    ToastType,
    { bg: string; leftIcon: IconProp; title: string }
  > = {
    success: {
      bg: 'successful',
      leftIcon: iconLeft ? iconLeft : iconRight ? null : faCheck,
      title: 'Success!',
    },
    error: {
      bg: 'linear-gradient(91.3deg, #C75346 0%, #CD836A 49.18%, #A77FA5 100%)',
      leftIcon: iconLeft ? iconLeft : iconRight ? null : faPlug,
      title: 'Oops, something went wrong...',
    },
    info: {
      bg: 'linear-gradient(91.3deg, #7076BB 0%, #9B9EC2 100%)',
      leftIcon: iconLeft ? iconLeft : iconRight ? null : faCircleInfo,
      title: '',
    },
    loading: {
      bg: 'graphite',
      leftIcon: null,
      title: 'Loading...',
    },
  }

  const sharedIconStyles: Omit<IconProps, 'icon'> = {
    display: 'flex',
    boxSize: '24px',
    fontSize: 18,
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
  }

  return (
    <Flex
      position="relative"
      transition="all 0.3s ease-in-out"
      zIndex="toast"
      py={0}
      pl={
        (config[type].leftIcon && !hideIcons) || type === 'loading'
          ? 4
          : subtitle
          ? 8
          : 6
      }
      pr={(iconRight && !hideIcons) || type === 'loading' || action ? 4 : 6}
      color="white"
      className="body-small-emphasis"
      minW={type === 'loading' ? '0px' : '20px'}
      gap={4}
      justifyContent="space-center"
      boxShadow="lg"
      borderRadius="full"
      alignItems="center"
      bg="stone"
      data-testid="toaster"
    >
      {Object.keys(config).map((toastType, i) => (
        <ToastBG key={i} bg={config[toastType].bg} show={type === toastType} />
      ))}

      {config[type].leftIcon && !hideIcons && (
        <Icon
          zIndex="toast"
          icon={config[type].leftIcon}
          {...sharedIconStyles}
        />
      )}

      {type === 'loading' && (
        <Loader zIndex="toast" color="white" size="md" boxSize="24px" inline />
      )}

      <Flex
        zIndex="toast"
        py={4}
        direction="column"
        w="100%"
        onTouchMove={(e) => {
          e.stopPropagation()
          redwoodToast.dismiss(id)
        }}
        userSelect="none"
        data-testid="toaster-text"
      >
        {title || config[type].title}
        {!!subtitle && (
          <Box className="body-extra-small-emphasis" w="100%">
            {subtitle}
          </Box>
        )}
      </Flex>

      {!action && iconRight && !hideIcons && (
        <Icon zIndex="toast" icon={iconRight} {...sharedIconStyles} />
      )}

      {action && (
        <Button
          data-testid="toaster-button"
          tabIndex={-1}
          as="div"
          zIndex="toast"
          variant="text"
          flex="1 0 auto"
          padding={0}
          color="white"
          fontSize={14}
          _active={{
            opacity: 0.8,
          }}
          onPointerDown={(e) => {
            e.stopPropagation()
            e.preventDefault()
            action?.onClick(e)
            redwoodToast.dismiss(id)
          }}
          onTouchEnd={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          {action?.label}
        </Button>
      )}
    </Flex>
  )
}

const defaultToastOpts: ToastOptions = {
  style: {
    background: 'none',
    boxShadow: 'none',
    padding: '0',
  },
}

const toast = (
  type: ToastType,
  title?: string,
  toastProps?: Omit<CustomToastProps, 'type' & 'title'>,
  opts?: ToastOptions
) => {
  const toastOptions = {
    ...defaultToastOpts,
    id: !opts?.id && type === 'error' ? 'gen-error' : undefined,
    ...opts,
  }

  if (!title && type === 'info') {
    console.log('Info toasts without a title will be suppressed.')
    return
  }

  return redwoodToast((toast) => {
    toast.pauseDuration = 0
    return (
      <CustomToast id={toast.id} type={type} title={title} {...toastProps} />
    )
  }, toastOptions)
}

const toastPromise = (
  promise: Promise<unknown>,
  config: ToastPromiseConfig,
  opts?: ToastOptions
) => {
  const toastOptions: ToastOptions = {
    ...defaultToastOpts,
    style: {
      ...defaultToastOpts.style,
      transition: 'all 0.2s ease-in-out',
      minWidth: 'unset',
      pointerEvents: 'auto',
    },
    ...opts,
  }

  const callToast = (type: ToastType, duration: number, id?: string) => {
    return redwoodToast(
      (toast) => {
        toast.pauseDuration = 0
        return (
          <CustomToast id={id ? id : toast.id} type={type} {...config[type]} />
        )
      },
      { ...toastOptions, duration, id: id ? id : undefined }
    )
  }

  const toastID = callToast('loading', Infinity)

  const durationAfterResolve = opts?.duration ? opts.duration : 2500

  promise.then(
    () => callToast('success', durationAfterResolve, toastID),
    () => callToast('error', durationAfterResolve, toastID)
  )

  return toastID
}

export default Toaster

export { toast, toastPromise }
