import { Box } from '@chakra-ui/react'
import { useSetRecoilState } from 'recoil'

import { creatorLinksState } from 'src/atoms/creator.atom'
import { LinkFormData } from 'src/components/widgets/EditableList/instances/LinksEditableList/LinksEditableList'
import ErrorMessage from 'src/components/widgets/ErrorMessage/ErrorMessage'
import ModalForm from 'src/components/widgets/Modals/ModalForm/ModalForm'
import { useSetCreatorLinks } from 'src/lib/hooks/mutations/useSetCreatorLinks'

const CellFrame: React.FC<{
  initialItems?: LinkFormData[]
  onClose: () => void
  children?: React.ReactNode
}> = ({ children, initialItems = [], onClose }) => {
  const setLinks = useSetRecoilState(creatorLinksState)
  const { submit, error } = useSetCreatorLinks({
    onCompleted: ({ setCreatorLinks }) => {
      setLinks(setCreatorLinks)
    },
  })
  return (
    <ModalForm
      mutation={submit}
      defaultValues={{ items: initialItems }}
      onClose={onClose}
    >
      <Box as="h3" mt={10} className="thick" data-testid="cellFrame-title">
        Add your links
      </Box>
      <Box mt={4} className="body-small">
        Link your website, social profiles or podcast - anything that people
        should easily access from your profile.
      </Box>
      <Box mt={8}>{children}</Box>
      {!!error && <ErrorMessage mt={2} ml={6} message={error} />}
    </ModalForm>
  )
}

export default CellFrame
