import { Flex, Box, ListItem, Spacer } from '@chakra-ui/react'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

import Icon from 'src/components/widgets/Icon/Icon'

const DrawerMenuItemSecondary = ({ label }: { label: string }) => (
  <ListItem pl={0} pr={0}>
    <Flex gap={4} alignItems="center">
      <Box>{label}</Box>
      <Spacer />
      <Icon icon={faChevronRight} color="graphite" />
    </Flex>
  </ListItem>
)

export default DrawerMenuItemSecondary
