import { extendTheme } from '@chakra-ui/react'

import { colors } from './colors'
import * as components from './components'
import { editorPreviewTypography, typography } from './typography'

export const itListTheme = extendTheme({
  space: {
    0: '0',
    0.5: '2px',
    1: '4px',
    1.5: '6px',
    2: '8px',
    2.5: '10px',
    3: '12px',
    3.5: '14px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '36px',
    10: '40px',
    12: '48px',
    14: '56px',
    15: '60px',
    16: '64px',
    20: '80px',
    24: '96px',
    28: '112px',
    32: '128px',
  },
  colors,
  components: { ...components },
  fontSizes: {
    xs: '1rem',
    sm: '1.2rem',
    md: '1.4rem',
    lg: '1.6rem',
    xl: '1.8rem',
  },
  shadows: {
    xs: '0px 0px 8px rgba(0, 0, 0, 0.14)',
    sm: '0px 1px 15px rgba(0, 0, 0, 0.15)',
    md: '0px 2px 15px rgba(0, 0, 0, 0.2)',
    lg: '0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 8px 16px rgba(22, 34, 51, 0.11)',
    xlLight: '0px 20px 28px rgba(57, 70, 86, 0.12)',
    xlMedium: '0px 11px 24px 0px #3946564A',
    xlDark: '0px 14px 50px rgba(0, 0, 0, 0.4)',
    xxl: '0 20px 92px #39465633',
  },

  //Semantic z-index values provided by the default theme...
  // zIndicies: {
  //   hide: -1,
  //   auto: "auto",
  //   base: 0,
  //   docked: 10,
  //   dropdown: 1000,
  //   sticky: 1100,
  //   banner: 1200,
  //   overlay: 1300,
  //   modal: 1400,
  //   popover: 1500,
  //   skipLink: 1600,
  //   toast: 1700,
  //   tooltip: 1800,
  // },

  breakpoints: {
    sm: '320px',
    md: '500px',
    lg: '745px',
    xl: '1200px',
    '2xl': '1500px',
  },
  fonts: {
    heading: 'Plus Jakarta Sans, helvetica, arial, sans-serif',
    body: 'Plus Jakarta Sans, helvetica, arial, sans-serif',
  },
  styles: {
    global: {
      ...typography,
      ...editorPreviewTypography,
      '.svg-settings': {
        /* Hiding this content since it's just settings. See - https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
        position: 'absolute !important',
        height: '1px',
        width: '1px',
        overflow: 'hidden',
        clip: 'rect(1px, 1px, 1px, 1px)',
      },
      /* SVG Fill gradients ids declared in SvgGradients.tsx */
      '.fill-gradient-primary-special1 path': {
        fill: 'url(#primary-special1)',
      },
      '.fill-gradient-primary-special2 path': {
        fill: 'url(#primary-special2)',
      },
      '.fill-gradient-primary-special3 path': {
        fill: 'url(#primary-special3)',
      },
      '.fill-gradient-primary-special4 path': {
        fill: 'url(#primary-special4)',
      },
    },
  },
})

// 100	Thin (Hairline)
// 200	Extra Light (Ultra Light)
// 300	Light
// 400	Normal (Regular)
// 500	Medium
// 600	Semi Bold (Demi Bold)
// 700	Bold
// 800	Extra Bold (Ultra Bold)
// 900	Black (Heavy)
// 950	Extra Black (Ultra Black)
