import { faPlus } from '@fortawesome/pro-regular-svg-icons'

import BrandButton from 'src/components/widgets/buttons/BrandButton/BrandButton'
import Icon from 'src/components/widgets/Icon/Icon'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

import { LinkFormData } from '../LinksEditableList'

export const AddLinkButton: React.FC<{
  onAdd: (item: LinkFormData) => void
}> = ({ onAdd }) => {
  const { brandColor } = useCurrentUser()

  return (
    <BrandButton
      brandColor={brandColor}
      w="100%"
      data-testid="editableList-add"
      onClick={() => {
        onAdd({ url: '', name: '' })
      }}
      leftIcon={<Icon icon={faPlus} />}
    >
      Add a link
    </BrandButton>
  )
}

export default AddLinkButton
