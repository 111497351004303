export const colors = {
  brand: {
    200: '#FF425A',
    300: '#F0374C',
    500: '#B43544',
  },
  brandNeutral: {
    100: '#A29696',
    200: '#928686',
    300: '#736868',
    400: '#5F5555',
    500: '#4A4A4A',
    600: '#362C35',
  },
  brandRed: {
    100: '#E7A597',
    200: '#E98576',
    300: '#F35152',
    400: '#EA222C',
    500: '#B92032',
    600: '#900903',
  },
  brandOrange: {
    100: '#E2A77B',
    200: '#CD7056',
    300: '#F48010',
    400: '#DA6900',
    500: '#8E5145',
    600: '#873800',
  },
  brandYellow: {
    100: '#D8B268',
    200: '#C6A055',
    300: '#FAAD14',
    400: '#D08B00',
    500: '#A97903',
    600: '#A86A37',
  },
  brandGreen: {
    100: '#96A474',
    200: '#6E8374',
    300: '#3FAC2B',
    400: '#47AA74',
    500: '#045542',
    600: '#17390E',
  },
  brandTeal: {
    100: '#8EB2AE',
    200: '#71A49F',
    300: '#4D8E8A',
    400: '#248084',
    500: '#16726C',
    600: '#006066',
  },
  brandBlue: {
    100: '#7DA3C3',
    200: '#638EB1',
    300: '#0B88CA',
    400: '#2954C5',
    500: '#252180',
    600: '#110F49',
  },
  brandPurple: {
    100: '#938CC3',
    200: '#8779DA',
    300: '#4552E1',
    400: '#3B46BB',
    500: '#392C8B',
    600: '#30272F',
  },
  brandViolet: {
    100: '#AD9FBF',
    200: '#BC87B6',
    300: '#B66CAD',
    400: '#913C87',
    500: '#621E5A',
    600: '#471641',
  },
  brandMagenta: {
    100: '#F180AE',
    200: '#CC6A91',
    300: '#F02167',
    400: '#D22761',
    500: '#961641',
    600: '#6A092A',
  },
  onyx: '#1A202C', // Used for main copy, active inputs and controls and active input labels.
  stone: '#575761', // Used for secondary/supporting copy, helper text
  graphite: '#97979D', // Used as default and filled input outline and label color and on controls (toggles etc.) in their default & filled state
  sandstonePlus: '#DBDBDB', // Used for content dividers and white card outlines (when they appear on white backgrounds)
  sandstone: '#E3E3E3', // Used for content dividers and white card outlines (when they appear on white backgrounds)
  dust: '#ECECEC', //Used for function buttons ie. Card ••• bg. Although some use sandstone
  vapor: '#F2F2F2', // Used for very subtle backgrounds that cannot be white
  air: '#F8F8F8', // Used for content dividers and white card outlines (when they appear on white backgrounds)
  white: '#FFF',
  black: '#000',

  socialNetwork: {
    instagram: '#E1306C',
    tiktok: '#000000',
    twitter: '#1DA1F2',
    youtube: '#FF0101',
  },

  miami: {
    200: '#FBDBDF',
    300: '#FF3C55',
    500: '#E21E37',
  },

  palmSprings: {
    200: '#FEEEEB',
    300: '#FEA495',
    500: '#FF6349',
  },

  rio: {
    300: '#D08AFF',
    500: '#9D24EE',
  },

  mykonos: {
    300: '#6386EF',
    500: '#5B83FF',
  },

  venice: {
    300: '#799CC9',
    500: '#2160B0',
  },

  cancun: {
    300: '#24B4E3',
    500: '#036A8C',
  },

  exuma: {
    200: '#D6EEEE',
    300: '#19BBB2',
    500: '#005657',
  },

  meadow: {
    300: '#54A060',
    500: '#139527',
  },

  divider: '#EBEBEB',

  warning: '#D08A00',
  danger: '#EC1D37',
  dangerBg: '#FDE4E8',
  successful: '#43C57B',
  offWhite: '#FAFAFA',

  gradient: {
    primarySpecial1:
      'linear-gradient(130.75deg, #A356CB 11.64%, #DFDAE1 87.69%)',
    primarySpecial2:
      'linear-gradient(120.71deg, #FE4C6C 11.08%, #DFDAE1 79.09%)',
    primarySpecial3:
      'linear-gradient(120.71deg, #D08A00 11.08%, #DFDAE1 79.09%)',
    primarySpecial4:
      'linear-gradient(138.14deg, #2FA0BE 9.34%, #DFDAE1 92.75%)',
  },
}
