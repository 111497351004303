import _ from 'lodash'
import { DropResult } from 'react-beautiful-dnd'

import { useFieldArray, useFormContext } from '@redwoodjs/forms'

export default function useFieldMethods<ItemType>({
  onDelete,
  onClear,
  isEmpty,
  dragEndCallback,
}: {
  onDelete: (id: number) => void
  onClear?: (item: ItemType) => ItemType
  isEmpty: (item: ItemType) => boolean
  dragEndCallback?: (source: number, destination: number) => unknown
}) {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any[]
  }>()

  const { fields, move, prepend, remove, update } = useFieldArray({
    name: 'items',
    keyName: 'fieldArrayId',
    control,
  })

  const itemHasError = (index: number): boolean =>
    !_.isEmpty(errors?.items?.[index] || {})

  const addItem: (item: ItemType) => void = async (item) => {
    const firstItem = getValues('items.0')
    if (!firstItem || !isEmpty(firstItem)) {
      prepend(item)
    } else if (isEmpty(firstItem)) {
      update(0, item)
    }
  }

  const clearItem = (index: number): void => {
    if (index > -1 && !!onClear) {
      update(index, onClear(fields[index]))
    }
  }

  const removeItem = async (
    index: number,
    cb: (index: number) => void
  ): Promise<void> => {
    if (index > -1) {
      if (fields[index]?.id && onDelete) {
        onDelete?.(fields[index].id)
      }
      remove(index)
      cb(index)
    }
  }

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (source && destination) {
      move(source.index, destination.index)
    }
    dragEndCallback?.(source.index, destination.index)
  }

  return {
    fields,
    itemHasError,
    removeItem,
    clearItem,
    addItem,
    updateItem: update,
    onDragEnd,
  }
}
