import type { GetUserLinksQueryToEdit } from 'types/graphql'

import type { CellSuccessProps } from '@redwoodjs/web'

import CellFailure from '../../CellFailure/CellFailure'
import LinksEditableList from '../../EditableList/instances/LinksEditableList/LinksEditableList'
import Loader from '../../Loader/Loader'

import CellFrame from './CellFrame/CellFrame'

type EditLinksModalCell = {
  onClose: () => void
}

export const QUERY = gql`
  query GetUserLinksQueryToEdit($creatorId: Int!) {
    links(creatorId: $creatorId) {
      id
      name
      url
      order
    }
  }
`

export const Loading = () => <Loader p={5} />

export const Empty = ({ onClose }: EditLinksModalCell) => (
  <CellFrame onClose={onClose}>
    <LinksEditableList />
  </CellFrame>
)

export const Failure = () => <CellFailure />

export const Success = ({
  links,
  onClose,
}: CellSuccessProps<GetUserLinksQueryToEdit> & EditLinksModalCell) => (
  <CellFrame initialItems={links} onClose={onClose}>
    <LinksEditableList />
  </CellFrame>
)

// cache-and-network fetch policy does not work here. It will set bad default values (from the cache)
// when you come back to the modal
export const beforeQuery = (props: { creatorId: number }) => {
  return {
    variables: props,
    fetchPolicy: 'network-only',
  }
}
