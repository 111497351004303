import { useState } from 'react'

import {
  Button,
  Flex,
  ModalBody,
  ModalHeader,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react'

import { DefaultValues, Form } from '@redwoodjs/forms'

import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'
import { validationErrorOrDefault } from 'src/lib/utils'

import { toast } from '../../Toaster'

type ModalFormProps<T> = {
  mutation: (data: T) => unknown
  onClose: (data?: T) => unknown
  defaultValues?: DefaultValues<T>
  children: React.ReactNode
}

function ModalForm<T>({
  mutation,
  defaultValues,
  children,
  onClose,
}: ModalFormProps<T>) {
  const { brandColor } = useCurrentUser()

  const modalSize = useBreakpointValue({ sm: 'full', md: 'md' })
  const [isLoading, setIsLoading] = useState(false)
  const submitHandler = async (obj: T) => {
    setIsLoading(true)
    try {
      await mutation(obj)
      onClose(obj)
    } catch (err) {
      toast('error', validationErrorOrDefault(err, 'Update failed'))
    }
    setIsLoading(false)
  }
  if (modalSize === undefined) return null
  return (
    <Form noValidate onSubmit={submitHandler} config={{ defaultValues }}>
      <ModalHeader
        borderBottom="1px solid"
        borderBottomColor="sandstone"
        position="absolute"
        left={0}
        right={0}
        zIndex="sticky"
        bg="white"
        p={0}
      >
        <Flex my={3} width="100%">
          <Button
            variant="text"
            color="stone"
            onClick={() => onClose()}
            size="sm"
            data-testid="ModalFrame-cancelButton"
          >
            Cancel
          </Button>
          <Spacer />
          <Button
            isLoading={isLoading}
            variant="text"
            color={brandColor || 'brand.200'}
            type="submit"
            size="sm"
            data-testid="ModalFrame-saveButton"
          >
            Save
          </Button>
        </Flex>
      </ModalHeader>

      <ModalBody
        minH="50vh"
        maxH={modalSize === 'full' ? '100vh' : 'calc(100vh - 124px)'}
        overflow="auto"
        px={modalSize === 'full' ? 4 : 8}
        pt={16}
        pb={10}
      >
        {children}
      </ModalBody>
    </Form>
  )
}

export default ModalForm
