import type { SVGProps } from 'react'
const SocialPatreon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="white"
    />
    <path d="M6.90039 17.2H8.90039V6.80005H6.90039V17.2Z" fill="#002C49" />
    <path
      d="M14.1059 6.80005C16.308 6.80005 18.0996 8.59707 18.0996 10.8061C18.0996 13.0083 16.308 14.8 14.1059 14.8C11.8968 14.8 10.0996 13.0083 10.0996 10.8061C10.0996 8.59707 11.8968 6.80005 14.1059 6.80005Z"
      fill="#F76754"
    />
  </svg>
)
export default SocialPatreon
