import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const CloseButton: ComponentStyleConfig = {
  baseStyle: {
    color: 'onyx',
    borderRadius: 'full',
    m: 5,
    p: 4,
    _hover: { bg: 'graphite', color: 'white' },
  },
  variants: {
    navDrawer: {
      fontSize: 14,
      p: 4,
      m: 0,
      ml: -2,
      color: 'onyx',
      bg: 'none',
      _hover: {
        bg: 'none',
        color: 'stone',
      },
    },

    boxModal: {
      m: 0,
      backgroundColor: 'dust',
      _hover: { bg: 'sandstone', color: 'onyx' },
      fontSize: 8,
    },
    fullscreenModalOnDarkBackground: {
      m: { base: 4, md: 6 },
      backgroundColor: 'white',
      _hover: { bg: 'sandstonePlus', color: 'onyx' },
    },
    fullscreenModalOnLightBackground: {
      m: { base: 4, md: 6 },
      backgroundColor: 'sandstone',
      _hover: { bg: 'sandstonePlus', color: 'onyx' },
    },
    lightboxModal: {
      color: 'white',
      bgColor: 'transparent',
      borderColor: 'white',
      border: '1px solid',
      fontSize: '10px',
      m: { base: 4, md: 6 },
    },
  },
}
