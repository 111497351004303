import { ChangeEvent } from 'react'

import {
  Box,
  Textarea,
  TextareaProps as ChakraTextareaProps,
  TextareaProps,
} from '@chakra-ui/react'
import _ from 'lodash'
import { pick } from 'lodash'
import { isAndroid } from 'react-device-detect'

import { RegisterOptions } from '@redwoodjs/forms'

import InputFooter from '../InputFooter/InputFooter'
import InputTitle from '../InputTitle/InputTitle'

import useTextArea from './useTextArea'

export type TextAreaProps = {
  withCounter?: boolean
  counterVisibleOnBlur?: boolean
  autoExpand?: boolean
  noWarningMax?: number
  hideCounterMax?: number
  validation?: RegisterOptions
  countDown?: boolean
  hint?: string
  hideLabel?: boolean
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void
} & ChakraTextareaProps

const TextArea = ({
  withCounter = false,
  autoExpand = false,
  autoFocus = false,
  counterVisibleOnBlur = false,
  validation,
  noWarningMax,
  hideCounterMax,
  countDown,
  hint,
  hideLabel,
  onChange = (_e: ChangeEvent<HTMLTextAreaElement>) => {},
  onFocus = (_e: ChangeEvent<HTMLTextAreaElement>) => {},
  onBlur = (_e: ChangeEvent<HTMLTextAreaElement>) => {},
  ...props
}: TextAreaProps) => {
  const {
    defaultValues,
    name,
    hasErrors,
    changeHandler,
    field,
    setShowCounter,
    title,
    showCounter,
    count,
    computeHeight,
  } = useTextArea({
    autoExpand,
    validation,
    onChange,
    name: props.name || 'textarea',
    defaultValue: props.defaultValue as string,
    title: props.title,
    placeholder: props.placeholder,
  })

  const textAreaProps: TextareaProps = {
    ...props,
    defaultValue: _.get(defaultValues, name),
    isInvalid: hasErrors,
  }

  return (
    <Box position="relative" {...getWrapperProps(props)}>
      <Textarea
        resize="none"
        {...textAreaProps}
        name={name}
        onChange={changeHandler}
        onBlur={(ev) => {
          field.onChange(field?.value.toString().trim())
          setShowCounter(false)
          onBlur?.(ev)
        }}
        onFocus={(ev) => {
          computeHeight()
          setShowCounter(withCounter)
          onFocus?.(ev)
        }}
        w="100%"
        h="100%"
        m={0}
        ref={field.ref}
        autoFocus={autoFocus}
        enterKeyHint={isAndroid ? 'enter' : ''}
        overflowY="scroll"
      />
      {title && !hideLabel && (
        <InputTitle
          shifted={props.borderRadius === 'full'}
          hasValue={!!field.value}
          hasFocus={field.hasFocus}
          hasErrors={hasErrors}
          value={title}
        />
      )}
      <InputFooter
        showCounter={showCounter || counterVisibleOnBlur}
        hasErrors={hasErrors}
        errorMessage={field.error?.message.toString()}
        hint={hint}
        count={count}
        maxLength={props.maxLength}
        noWarningMax={noWarningMax}
        countDown={countDown}
        hideCounterMax={hideCounterMax}
      />
    </Box>
  )
}

export default TextArea

const wrapperPropNames = [
  'flexGrow',
  'w',
  'width',
  'h',
  'height',
  'm',
  'my',
  'mx',
  'marginX',
  'marginY',
  'margin',
  'ml',
  'mb',
  'mt',
  'mr',
  'marginLeft',
  'marginRight',
  'marginBottom',
  'marginTop',
] as const
function getWrapperProps(
  props: TextAreaProps
  // eslint-disable-next-line @typescript-eslint/ban-types
): Partial<Record<(typeof wrapperPropNames)[number], {}>> {
  return pick(props, wrapperPropNames)
}
