import { AspectRatio, Box, Flex } from '@chakra-ui/react'
import { Options } from '@vimeo/player'

import { BrandColor } from 'src/types'

import { useVimeoPlayer } from './useVimeoPlayer'

const VimeoPlayer: React.FC<{
  url: string
  brandColor?: BrandColor
  onReady?: () => void
  options?: Options
}> = ({ url, onReady, brandColor, options }) => {
  const { ref, videoId, isValid } = useVimeoPlayer(
    url,
    onReady,
    brandColor,
    options
  )
  if (!isValid) {
    return (
      <AspectRatio ratio={16 / 9}>
        <Flex
          w="100%"
          className="body-extra-small-emphasis"
          color="stone"
          bg="air"
        >
          This video couldn&apos;t load.
        </Flex>
      </AspectRatio>
    )
  }
  return (
    <Box w="100%" h="100%" ref={ref}>
      <div id={videoId} data-testid="socialProof-input-vimeo"></div>
    </Box>
  )
}

export default VimeoPlayer
