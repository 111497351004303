import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  CloseButton,
  ModalBody,
  useBreakpointValue,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'

import profilePic from 'src/assets/icon/platform-voice-tip.jpg'
import CustomAccordion from 'src/components/widgets/Accordion/Accordion'
import AudioPlayerInline from 'src/components/widgets/AudioPlayerInline/AudioPlayerInline'
import { aboutChannel } from 'src/lib/voiceTipsUrls'
import { BrandColor } from 'src/types'

import ExampleChannels from './ExampleChannels/ExampleChannels'

export type ChannelFAQProps = {
  closeModal: () => void
  isOpen: boolean
}

const ChannelFAQ = ({ closeModal, isOpen }: ChannelFAQProps) => {
  const modalSize = useBreakpointValue({ sm: 'full', md: 'md' })
  if (modalSize === undefined) return null

  return (
    <Modal onClose={closeModal} size={modalSize} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: 0, md: 16 }}
        mt={modalSize === 'full' ? '' : '124px'}
      >
        <ModalHeader
          position="absolute"
          left={0}
          right={0}
          zIndex="sticky"
          bg="white"
          borderRadius={{ base: '0', md: '16px 16px 0 0' }}
        >
          <CloseButton variant="boxModal" onClick={closeModal} />
          <Flex
            pt={4}
            pb={2}
            position="absolute"
            top={16}
            left={0}
            right={0}
            bg="white"
          >
            <Box as="h3" px={{ base: 4, md: 8, lg: 10 }} className="thick">
              About Topic Channels
            </Box>
          </Flex>
        </ModalHeader>

        <ModalBody
          maxH={modalSize === 'full' ? '100vh' : 'calc(100vh - 248px)'}
          overflow="auto"
          px={{ base: 4, md: 8, lg: 10 }}
        >
          <Box pt={{ base: '120px', md: '117px' }} w="100%">
            <Box mt={4} className="body-small">
              Topic Channels are topics in your niche that you talk about often,
              or more personal topics like &quot;My Routines&quot;. You can also
              think of Topic Channels as categories.
            </Box>
          </Box>
          <Flex
            mt={4}
            mb={20}
            pt={4}
            pb={6}
            borderRadius="12px"
            bg="air"
            alignItems="center"
            direction="column"
          >
            <Box
              as="p"
              mb={4}
              className="body-extra-small-emphasis"
              color="stone"
            >
              Learn more
            </Box>
            <AudioPlayerInline
              brandColor={'brandNeutral.600' as BrandColor}
              mediaUrl={aboutChannel.mediaUrl}
              title={aboutChannel.title}
              pictureUrl={profilePic}
            />
          </Flex>

          <h5 className="thick">FAQ</h5>

          <CustomAccordion
            accordionProps={{ mt: 4, mb: 32 }}
            content={[
              {
                title: 'Can I see some Topic Channel examples?',
                children: (
                  <>
                    Start with 3-5 Topic Channels that are broad enough to have
                    multiple ItLists in each one — you can always adjust and
                    expand from there. Here are some example niches with
                    corresponding Topic Channels to get your ideas flowing!
                    <ExampleChannels
                      niche="Gut Health"
                      channels={[
                        'Gut Health 101',
                        'Gut Protocols',
                        'Digestive Disorders',
                        'Weight Management',
                        'Autoimmune Conditions',
                      ]}
                    />
                    <ExampleChannels
                      niche="Fitness"
                      channels={[
                        'Training 101',
                        'Nutrition 101',
                        'Gym Workouts',
                        'Home Workouts',
                        'Programs',
                      ]}
                    />
                    <ExampleChannels
                      niche="Skincare"
                      channels={[
                        'Anti-Aging',
                        'Skincare by Skin Type',
                        'Acne & Problem Skin',
                        'Daily Routines',
                        'Product Collections',
                      ]}
                    />
                    <ExampleChannels
                      niche="Lifestyle"
                      channels={[
                        'Home',
                        'Beauty',
                        'Fashion',
                        'Travel',
                        "New Moms (If you're a new mom)",
                      ]}
                    />
                    <ExampleChannels
                      niche="Real Estate"
                      channels={[
                        'Listings by area',
                        'First-Time Buyers',
                        'Sellers',
                        'Buyers',
                        'Financing Help',
                      ]}
                    />
                    <ExampleChannels
                      niche="Building Startups"
                      channels={[
                        'Idea Stage',
                        'Fundraising',
                        'Tech Dev',
                        'Talking to Users',
                        'Corp & Legal',
                      ]}
                      my={4}
                    />
                  </>
                ),
              },
              {
                title: 'How should I think about Topic Channels?',
                children: (
                  <>
                    A good way to think about what these topics might be is to
                    ask yourself:
                    <UnorderedList my={2}>
                      <ListItem>
                        What topics do I get asked about most often?
                      </ListItem>
                      <ListItem>
                        What topics do I teach or talk about often?
                      </ListItem>
                      <ListItem>
                        What topics do my workshops, guides or programs cover?
                      </ListItem>
                    </UnorderedList>
                    Your first few Topic Channels should cover topics that will
                    be most valuable and relevant to your current audience.
                    Exploring our examples below might be helpful too!
                  </>
                ),
              },
              {
                title: 'How will followers use my Topic Channels?',
                children: (
                  <>
                    <Box>
                      Your Topic Channels will live on your ItList profile and
                      quickly inform potential customers or curious visitors
                      what you and your digital brand are all about. Right away,
                      visitors see what topics you can help them with and are
                      able to easily jump into the ones that matter to them
                      personally. Visitors can filter your ItList library by one
                      or multiple Topic Channels to zero in on the right ItLists
                      for them.
                    </Box>
                    <Box mt={2}>
                      Topic Channels make ItList a powerful hub for potential
                      customers to discover the content, recs and offers that
                      matter to them most in this moment without getting lost in
                      a sea of Linktree links, website pages, Instagram posts
                      etc.
                    </Box>
                  </>
                ),
              },
            ]}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ChannelFAQ
