import { Button, SystemProps } from '@chakra-ui/react'

import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'
import { useLogout } from 'src/lib/hooks/form/useLogout'

const LogoutButton = ({
  onClick,
  ...props
}: SystemProps & { onClick?: () => void }) => {
  const logout = useLogout()

  const { isAuthenticated } = useCurrentUser()
  const logoutClickHandler = () => {
    onClick?.()
    logout()
  }
  // Large bottom padding due to apple sometimes showing banners on mobile safari which
  // do not resize the viewport so the sign out CTA gets hidden beneath the fold
  return (
    !!isAuthenticated && (
      <Button
        data-testid="menu-logout-button"
        variant="text"
        color="brand.200"
        paddingX="0"
        mb="174px"
        {...props}
        onClick={logoutClickHandler}
      >
        Sign Out
      </Button>
    )
  )
}

export default LogoutButton
