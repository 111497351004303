import type { SVGProps } from 'react'
const SocialAmazon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={24} height={24} rx={12} fill="white" />
    <g clipPath="url(#clip0_434_2369)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3864 18.2468C15.9387 19.3906 13.8409 20 12.0349 20C9.49739 20 7.22152 18.9968 5.49645 17.3267C5.3652 17.1954 5.48245 17.0164 5.6452 17.1186C7.50677 18.2797 9.81239 18.9781 12.1863 18.9781C13.9006 18.9696 15.5965 18.5981 17.1764 17.885C17.4214 17.7734 17.627 18.057 17.3864 18.2473M17.9884 17.509C17.8046 17.2559 16.7651 17.3895 16.2996 17.4481C16.1575 17.4668 16.136 17.3342 16.2638 17.239C17.095 16.6156 18.4486 16.7951 18.607 17.0047C18.7654 17.2142 18.5659 18.6734 17.7889 19.369C17.6695 19.4759 17.5557 19.4192 17.6139 19.2772C17.7889 18.8103 18.18 17.7631 17.9945 17.509"
        fill="#FF9900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0632 11.4988C13.0632 12.2919 13.0821 12.9533 12.708 13.6573C12.4017 14.2292 11.9205 14.5822 11.3933 14.5822C10.664 14.5822 10.2366 13.9869 10.2366 13.108C10.2366 11.3736 11.6873 11.0581 13.0637 11.0581V11.4988M14.9799 16.46C14.8544 16.5805 14.6728 16.5889 14.5311 16.5088C13.9011 15.9477 13.7856 15.687 13.4408 15.1517C12.3987 16.2908 11.6584 16.6316 10.3092 16.6316C8.70881 16.6316 7.46631 15.575 7.46631 13.4595C7.46631 11.8077 8.29975 10.6827 9.49193 10.1328C10.5231 9.64625 11.9638 9.56094 13.0641 9.42969V9.17187C13.0641 8.68812 13.0991 8.11531 12.8322 7.69766C12.6026 7.32266 12.1585 7.16797 11.7661 7.16797C11.042 7.16797 10.398 7.56641 10.2405 8.39047C10.2086 8.57797 10.083 8.75422 9.90931 8.76266L8.06262 8.54141C7.90775 8.50391 7.7345 8.36984 7.77956 8.11953C8.20612 5.72188 10.2261 5 12.0325 5C12.9569 5 14.1649 5.26344 14.8937 6.01344C15.8182 6.93828 15.7302 8.17203 15.7302 9.515V12.6875C15.7302 13.6409 16.0991 14.0586 16.4464 14.5742C16.5667 14.7617 16.5934 14.9773 16.4386 15.1152C15.9482 15.5594 15.4629 16.0099 14.9826 16.4666L14.9799 16.4609"
        fill="#221F1F"
      />
    </g>
    <defs>
      <clipPath id="clip0_434_2369">
        <rect width={14} height={15} fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
)
export default SocialAmazon
