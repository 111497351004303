import { useEffect, useRef, useState } from 'react'

import Player, { Options } from '@vimeo/player'
import _ from 'lodash'
import { useRecoilState } from 'recoil'

import { uiSettingsState } from 'src/atoms/creator.atom'
import { useDebouncedResizeListener } from 'src/lib/hooks/auth/useDebouncedResizer'
import { getColorHexValue } from 'src/lib/utils'
import { BrandColor } from 'src/types'

function getVimeoIdFromUrl(url: string) {
  let videoId: string
  try {
    videoId = new URL(url).pathname.replace(/\//g, '')
  } catch (e) {
    return null
  }
  return isNaN(+videoId) || videoId === '' ? null : +videoId
}

type Dimensions = { height: number; width: number }

export function useVimeoPlayer(
  url: string,
  onReady?: () => void,
  brandColor?: BrandColor,
  options?: Options
) {
  const videoId = getVimeoIdFromUrl(url)
  const videoIdString = `video${videoId}`
  const [uiSettings, setUiSettings] = useRecoilState(uiSettingsState)
  const [isValid, setIsValid] = useState<boolean>(!!videoIdString)

  const player = useRef<Player>()
  const { ref, rect } = useDebouncedResizeListener()
  const destroyPlayer = async () => {
    if (player.current) {
      await player.current.destroy()
    }
  }
  const initPlayer = React.useMemo(
    () =>
      _.debounce(
        async (
          url: string,
          videoIdString: string,
          { width, height }: Dimensions
        ) => {
          if (await player.current?.getFullscreen()) return
          await destroyPlayer()
          try {
            const newPlayer = new Player(videoIdString, {
              url,
              width,
              height,
              controls: true,
              title: false,
              color: getColorHexValue(brandColor || 'brand.200') as string,
              byline: false,
              ...options,
            })
            const title = await newPlayer.getVideoTitle()
            if (title) {
              player.current = newPlayer
              newPlayer.on('loaded', () => {
                onReady?.()
              })
              newPlayer.on('timeupdate', async (time) => {
                if (time.percent > 0.9) {
                  setUiSettings({
                    ...uiSettings,
                    hasPlayedNewItListIntro: true,
                  })
                }
              })
            } else {
              setIsValid(false)
            }
          } catch (err) {
            console.log(err)
            setIsValid(false)
          }
        },
        200
      ),
    [brandColor, options, onReady, setUiSettings, uiSettings]
  )

  useEffect(
    () => () => {
      destroyPlayer()
    },
    []
  )

  useEffect(() => {
    const width = rect?.width
    const height = rect?.height
    if (width) {
      initPlayer(url, videoIdString, { width, height })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rect, url])

  return { ref, videoId: videoIdString, player, isValid }
}
