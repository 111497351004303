import { useEffect } from 'react'

import { useLocation, useParams } from '@redwoodjs/router'

import { LOCAL_STORAGE_REFERRAL_KEY } from '../constants'
import { set } from '../storedAffiliations'

export default function useReferralParam() {
  const { invite: inviterId } = useParams()
  const { pathname } = useLocation()

  useEffect(() => {
    if (inviterId) {
      set(LOCAL_STORAGE_REFERRAL_KEY, +inviterId)
      history.replaceState(null, '', pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
