import { useState } from 'react'

import _ from 'lodash'
import LogRocket from 'logrocket'
import { useRecoilValue } from 'recoil'
import { ItList, ItListStatus } from 'types/graphql'

import { useLocation } from '@redwoodjs/router'

import { currentItListState } from 'src/atoms/creator.atom'

import { getAbsoluteUrl, isE2ETestSession, toCamelKeys } from '../../utils'

import { analytics } from './segment'

// If the user views the page less than 1 sec it won't be recorded as a page view
const ANALYTICS_TIMEOUT = 1000

export type ViewerStatus =
  | 'guest'
  | 'explorer'
  | 'creator'
  | 'purchaser'
  | 'affiliate'
export type PageSectionName = 'header' | 'mobile-nav' | 'about'

export type AnalyticsProps = ItListItemAnalyticsProps & {
  email: string
  roles: string
  id: string
  creatorStatus: string
  firstName: string
  lastName: string
  username: string
  avatar: string
  creatorNetworkType: string
  createdAt: string
  planId: string
  referrer: string
  routeName: string
  buttonLabel: string
  price: number
  quantity: number
  currency: string
  name: string
  userId: string
  sku: string
  revenue: number
  stripeFee: number
  stripeFxFee: number
  tax: number
  transfer: number
  mediaUrl: string
  countryCode: string
  networkHandle: string
  editorStepIndex: number
  //this is only intended for differentiating between different user 'types' and the state of itlist details when viewing.
  viewerStatus: Set<ViewerStatus>
  itListStatus: ItListStatus
  //this is a way to associate a CTA clicked event with a particular spot on the page.  Will initially just be used for Waitlist CTA calls
  section: PageSectionName
  hasLiveVersion: boolean
  fieldValue: string
  referrerUserId: number
}

export type AnalyticsTrackEventName =
  | 'Buy Now Clicked'
  | 'ItList Product Item Clicked'
  | 'ItList Content Item Clicked'
  | 'ItList PDF Item Clicked'
  | 'ItList Draft Created'
  | 'Editor Save & Exit Clicked'
  | 'Creator Sign Up Clicked'
  | 'User Signed Out'
  | 'ItList Clicked'
  | 'Voice Tip Clicked'
  | 'ItList Published'
  | 'ItList Update Published'
  | 'ItList Deleted'
  | 'Email Submitted'
  | 'ItList Editor Step Changed'
  | 'ItList Wishlist Clicked'
  | 'ItList Wishlisted'
  | 'ItList Un-Wishlisted'
  | 'ItList Share Clicked'
  | 'Waitlist Joined'
  | 'Email Paywall Field Focused'
  | 'Email Paywall Field Submitted'
  | 'Affiliate Invite Sent'
  | 'Affiliate Access Granted'

type PageViewPropNames = 'viewerStatus'

type BuyNowClickedPropNames =
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'price'
  | 'quantity'
  | 'currency'
  | 'name'
  | 'productId'
  | 'sku'

type PageViewOptionalPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'

type IdentifyPropNames =
  | 'email'
  | 'roles'
  | 'creatorStatus'
  | 'firstName'
  | 'lastName'
  | 'username'
  | 'avatar'
  | 'creatorNetworkType'
  | 'countryCode'
  | 'networkHandle'

type ItListProductItemClickedPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'
  | 'itemId'
  | 'itemUrl'

type ItListContentItemClickedPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'
  | 'itemId'
  | 'itemUrl'

type ItListPDFItemClickedPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'
  | 'itemId'

type ItListDraftCreatedPropNames = 'productId'
type EditorSaveExitClickedPropNames =
  | 'productId'
  | 'itListStatus'
  | 'hasLiveVersion'
type CreatorSignUpClickedPropNames =
  | 'section'
  | 'buttonLabel'
  | 'referrerUserId'

type ItListClickedPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'

type VoiceTipClickedOptionalPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'
  | 'mediaUrl'

type ItListPublishedPropNames = 'productId'
type ItListUpdatePublishedPropNames = 'productId'
type ItListDeletedPropNames = 'productId' | 'itListStatus' | 'hasLiveVersion'
type ItListEditorStepChangedPropNames =
  | 'editorStepIndex'
  | 'itListStatus'
  | 'hasLiveVersion'
type ItListWishlistClickedPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'

type ItListWishlistedPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'

type ItListUnwishlistedPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'

type ItListShareClickedPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'

type EmailPaywallFieldFocusedPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'

type EmailPaywallFieldSubmittedPropNames =
  | 'isOwner'
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'
  | 'fieldValue'

type AffiliateInviteSentPropNames = 'email'

type AffiliateAccessGrantedPropNames =
  | 'owningCreatorEmail'
  | 'owningCreatorId'
  | 'owningCreatorUsername'
  | 'productId'

type AsEvent<
  EventName extends AnalyticsTrackEventName,
  PropNames extends keyof AnalyticsProps | void = void,
  OptionalPropNames extends keyof AnalyticsProps | void = void
> = PropNames extends keyof AnalyticsProps
  ? OptionalPropNames extends keyof AnalyticsProps
    ? {
        eventName: EventName
        props: Pick<AnalyticsProps, PropNames> &
          Partial<Pick<AnalyticsProps, OptionalPropNames>>
      }
    : {
        eventName: EventName
        props: Pick<AnalyticsProps, PropNames>
      }
  : OptionalPropNames extends keyof AnalyticsProps
  ? {
      eventName: EventName
      props: Partial<Pick<AnalyticsProps, OptionalPropNames>>
    }
  : {
      eventName: EventName
      props?: never
    }

export type BuyNowClickedEvent = AsEvent<
  'Buy Now Clicked',
  BuyNowClickedPropNames
>

export type ItListProductItemClickedEvent = AsEvent<
  'ItList Product Item Clicked',
  ItListProductItemClickedPropNames
>
export type ItListContentItemClickedEvent = AsEvent<
  'ItList Content Item Clicked',
  ItListContentItemClickedPropNames
>
export type ItListPDFItemClickedEvent = AsEvent<
  'ItList PDF Item Clicked',
  ItListPDFItemClickedPropNames
>
export type ItListDraftCreatedEvent = AsEvent<
  'ItList Draft Created',
  ItListDraftCreatedPropNames
>
export type EditorSaveExitClickedEvent = AsEvent<
  'Editor Save & Exit Clicked',
  EditorSaveExitClickedPropNames
>
export type CreatorSignUpClickedEvent = AsEvent<
  'Creator Sign Up Clicked',
  CreatorSignUpClickedPropNames
>
export type ItListClickedEvent = AsEvent<
  'ItList Clicked',
  ItListClickedPropNames
>
export type VoiceTipClickedEvent = AsEvent<
  'Voice Tip Clicked',
  void,
  VoiceTipClickedOptionalPropNames
>
export type ItListPublishedEvent = AsEvent<
  'ItList Published',
  ItListPublishedPropNames
>
export type ItListUpdatePublishedEvent = AsEvent<
  'ItList Update Published',
  ItListUpdatePublishedPropNames
>
export type ItListDeletedEvent = AsEvent<
  'ItList Deleted',
  ItListDeletedPropNames
>
export type ItListEditorStepChangedEvent = AsEvent<
  'ItList Editor Step Changed',
  ItListEditorStepChangedPropNames
>
export type ItListWishlistClickedEvent = AsEvent<
  'ItList Wishlist Clicked',
  ItListWishlistClickedPropNames
>
export type ItListWishlistedEvent = AsEvent<
  'ItList Wishlisted',
  ItListWishlistedPropNames
>
export type ItListUnwishlistedEvent = AsEvent<
  'ItList Un-Wishlisted',
  ItListUnwishlistedPropNames
>
export type ItListShareClickedEvent = AsEvent<
  'ItList Share Clicked',
  ItListShareClickedPropNames
>
export type EmailPaywallFieldFocusedEvent = AsEvent<
  'Email Paywall Field Focused',
  EmailPaywallFieldFocusedPropNames
>
export type EmailPaywallFieldSubmittedEvent = AsEvent<
  'Email Paywall Field Submitted',
  EmailPaywallFieldSubmittedPropNames
>
export type AffiliateAccessGrantedEvent = AsEvent<
  'Affiliate Access Granted',
  AffiliateAccessGrantedPropNames
>
export type AffiliateInviteSentEvent = AsEvent<
  'Affiliate Invite Sent',
  AffiliateInviteSentPropNames
>
export type UserSignedOutEvent = AsEvent<'User Signed Out'>
export type EmailSubmittedOutEvent = AsEvent<'Email Submitted'>
export type WaitlistJoinedEvent = AsEvent<'Waitlist Joined'>

export type AnalyticsTrackEvent =
  | BuyNowClickedEvent
  | ItListProductItemClickedEvent
  | ItListContentItemClickedEvent
  | ItListPDFItemClickedEvent
  | ItListDraftCreatedEvent
  | EditorSaveExitClickedEvent
  | CreatorSignUpClickedEvent
  | ItListClickedEvent
  | VoiceTipClickedEvent
  | ItListPublishedEvent
  | ItListUpdatePublishedEvent
  | ItListDeletedEvent
  | ItListEditorStepChangedEvent
  | ItListWishlistClickedEvent
  | ItListWishlistedEvent
  | ItListUnwishlistedEvent
  | ItListShareClickedEvent
  | EmailPaywallFieldFocusedEvent
  | EmailPaywallFieldSubmittedEvent
  | UserSignedOutEvent
  | EmailSubmittedOutEvent
  | WaitlistJoinedEvent
  | AffiliateAccessGrantedEvent
  | AffiliateInviteSentEvent

export type VoiceTipAnalyticsProps = {
  owningCreatorEmail: string
  owningCreatorId: string
  owningCreatorUsername: string
  productId: string
}

export type ItListAnalyticsProps = {
  owningCreatorEmail: string
  owningCreatorId: string
  owningCreatorUsername: string
  isOwner: boolean
  productId: string
}

export type ItListItemAnalyticsProps = ItListAnalyticsProps & {
  itemId: string
  itemUrl: string
}

export type IdentifyParams = Partial<Pick<AnalyticsProps, IdentifyPropNames>>

export function useAnalytics() {
  const { pathname } = useLocation()
  const [previousPath, setPreviousPath] = useState(document.referrer)
  const itList = useRecoilValue(currentItListState)
  const enabled = !isE2ETestSession()

  /**
   * @see https://dmitripavlutin.com/react-throttle-debounce/
   */
  const pageDebounced = React.useMemo(
    () =>
      _.debounce(
        (
          props: Pick<AnalyticsProps, PageViewPropNames> &
            Partial<Pick<AnalyticsProps, PageViewOptionalPropNames>>,
          previousPath: string,
          pathname: string
        ) => {
          if (pathname === previousPath) return
          const fullProps = {
            ...toCamelKeys(props),
            referrer: getAbsoluteUrl(previousPath),
            route_name: document.title,
          }
          enabled && analytics.page(undefined, fullProps)
          setPreviousPath(pathname)
        },
        ANALYTICS_TIMEOUT
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const page = (
    props: Pick<AnalyticsProps, PageViewPropNames> &
      Partial<Pick<AnalyticsProps, PageViewOptionalPropNames>>
  ) => pageDebounced(props, previousPath, pathname)

  const identify = React.useMemo(
    () =>
      _.debounce((userId: number | undefined, props: IdentifyParams) => {
        enabled &&
          analytics.identify(
            userId ? `${userId}` : undefined,
            toCamelKeys(props)
          )
      }, ANALYTICS_TIMEOUT),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  React.useEffect(
    () => () => {
      pageDebounced && pageDebounced.cancel()
      identify && identify.cancel()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  function liveSessionRecord() {
    if (process.env.LOG_ROCKET_API_KEY && enabled) {
      LogRocket.init(process.env.LOG_ROCKET_API_KEY)
    } else {
      console.log('Log rocket live recording stub called')
    }
  }

  function liveSessionIdentify({
    id,
    email,
    firstName,
    lastName,
  }: {
    id: number
    email: string
    firstName?: string
    lastName?: string
  }) {
    if (process.env.LOG_ROCKET_API_KEY && enabled && id) {
      LogRocket.identify(`${id}`, toCamelKeys({ email, firstName, lastName }))
    }
  }

  function reset() {
    analytics.reset()
  }

  function track({ eventName, props }: AnalyticsTrackEvent) {
    const fullProps = {
      ...toCamelKeys<string | number | boolean>(props),
      referrer: previousPath,
    }
    enabled && analytics.track(eventName, fullProps)
  }

  function trackItListClicked(itList: ItList, isOwner: boolean) {
    track({
      eventName: 'ItList Clicked',
      props: {
        isOwner,
        owningCreatorEmail: itList.creator?.email,
        owningCreatorId: `${itList.creator?.id}`,
        owningCreatorUsername: itList.creator?.username,
        productId: `${itList.id}`,
      },
    })
  }

  function trackVoiceTipClicked(
    mediaUrl: string,
    currentUserId: number,
    props?: VoiceTipAnalyticsProps
  ) {
    if (props) {
      track({
        eventName: 'Voice Tip Clicked',
        props: {
          isOwner: currentUserId && +props.owningCreatorId === currentUserId,
          mediaUrl,
          ...props,
        },
      })
    } else if (itList) {
      track({
        eventName: 'Voice Tip Clicked',
        props: {
          isOwner: currentUserId && itList.creator.id === currentUserId,
          owningCreatorEmail: itList.creator.email,
          owningCreatorId: `${itList.creator.id}`,
          owningCreatorUsername: itList.creator.username,
          productId: `${itList.id}`,
          mediaUrl,
        },
      })
    } else {
      track({ eventName: 'Voice Tip Clicked', props: { mediaUrl } })
    }
  }

  return {
    identify,
    reset,
    page,
    track,
    liveSessionRecord,
    liveSessionIdentify,
    trackItListClicked,
    trackVoiceTipClicked,
  }
}
