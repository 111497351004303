import { BasicInfoItem } from 'src/types'

export function isString(x: unknown): x is string {
  return typeof x === 'string' || x instanceof String
}

export function isNumber(x: unknown): x is number {
  return !!Number(x)
}

export function isBasicInfoItem(x: unknown): x is BasicInfoItem {
  return [
    'cover',
    'title',
    'voiceIntro',
    'description',
    'channels',
    'notes',
    'reviews',
    'featuredVideo',
  ].includes(x as string)
}
