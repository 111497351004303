import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Link: ComponentStyleConfig = {
  variants: {
    primary: {
      color: 'brand.200',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px',
    },
    inline: {
      display: 'inline',
      textDecoration: 'underline',
    },
  },
}
