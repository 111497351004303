import {
  ChakraProps,
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react'

type ModalProps = {
  onClose: () => unknown
  children: React.ReactNode
  isOpen: boolean
} & ChakraProps

function ModalFrame({ children, onClose, isOpen, ...props }: ModalProps) {
  const modalSize = useBreakpointValue({ sm: 'full', md: 'md' })
  if (modalSize === undefined) return null
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        minH={modalSize === 'full' ? '100%' : '0'}
        maxH={modalSize === 'full' ? '100%' : 'calc(100vh - 124px)'}
        borderRadius={modalSize === 'full' ? 0 : 12}
        overflow="hidden"
        p={6}
        containerProps={{ overflow: 'hidden' }}
        {...props}
      >
        {children}
      </ModalContent>
    </Modal>
  )
}

export default ModalFrame
