import React from 'react'

import { Box, Flex, Spacer, Text } from '@chakra-ui/react'

import { useWatch } from '@redwoodjs/forms'

import Input from 'src/components/widgets/Input/Input'
import InputCounter from 'src/components/widgets/InputCounter/InputCounter'
import InterceptKey from 'src/components/widgets/InterceptKey/InterceptKey'
import {
  CHARS_NUM_SPACE_DASH_EMDASH_APOS_AMP_COMMA_REGEXP,
  MAX_SM_INPUT,
} from 'src/lib/constants'

import { BuilderProps } from '../../../EditableList'

const ChannelBuilder: React.FC<BuilderProps> = ({ name, onClose }) => {
  const channelName = useWatch({ name: `${name}.name` })
  const itLists = useWatch({ name: `${name}.itLists` })
  const hasItLists = itLists && itLists.length > 0
  return (
    <>
      <Box w="100%">
        <InterceptKey keyName="Enter" onKeyPressed={onClose}>
          <Input
            autoFocus={true}
            name={`${name}.name` as const}
            placeholder="Topic Channel name"
            data-testid="channelBuilder-channelName"
            validation={{
              pattern: {
                value: CHARS_NUM_SPACE_DASH_EMDASH_APOS_AMP_COMMA_REGEXP,
                message:
                  "Only letters, numbers, spaces and the following symbols allowed: & - '",
              },
            }}
            w="100%"
            maxLength={MAX_SM_INPUT}
          />
        </InterceptKey>
      </Box>
      <Flex mt="8px" w="100%">
        <Text variant="caption" color="stone">
          {hasItLists &&
            `${itLists.length} ${
              itLists.length == 1 ? 'ItList uses' : 'ItLists use'
            } this channel as primary.`}
          {!hasItLists && 'Keep it short and sweet!'}
        </Text>
        <Spacer />
        <InputCounter
          count={channelName?.length || 0}
          max={30}
          noWarningMax={25}
          data-testid="channelBuilder-counter"
        />
      </Flex>
    </>
  )
}

export default ChannelBuilder
