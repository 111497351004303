import type { SVGProps } from 'react'
const SocialSpotify = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9999 0C5.37269 0 0 5.37255 0 11.9998C0 18.6277 5.37269 24 11.9999 24C18.6275 24 24 18.6277 24 11.9998C24 5.37255 18.6275 0 11.9999 0"
      fill="white"
    />
    <path
      d="M17.2394 17.0473C17.027 17.3956 16.5735 17.5047 16.2265 17.2925C13.4511 15.5962 9.9577 15.2131 5.84362 16.1528C5.44717 16.2437 5.05199 15.9953 4.96166 15.599C4.87077 15.2025 5.11832 14.8073 5.51562 14.7169C10.0178 13.6877 13.8797 14.1306 16.995 16.0342C17.3423 16.2464 17.4517 16.7001 17.2394 17.0473"
      fill="#1ED760"
    />
    <path
      d="M18.6855 13.829C18.4182 14.2627 17.8511 14.3987 17.4179 14.1324C14.2418 12.1801 9.39837 11.6144 5.64065 12.7551C5.15346 12.9023 4.63874 12.6276 4.49069 12.1412C4.34391 11.654 4.6187 11.1402 5.10519 10.992C9.39781 9.68936 14.7343 10.3202 18.3823 12.5622C18.8155 12.8289 18.9518 13.3963 18.6855 13.829"
      fill="#1ED760"
    />
    <path
      d="M18.8106 10.4779C15.0008 8.21537 8.71667 8.00733 5.07977 9.11115C4.49561 9.28842 3.878 8.95872 3.70116 8.37471C3.52418 7.79027 3.85345 7.17308 4.43789 6.99554C8.61294 5.72829 15.5531 5.97301 19.939 8.57653C20.4645 8.88844 20.6367 9.56687 20.3253 10.0913C20.0137 10.6166 19.3347 10.7898 18.8106 10.4779"
      fill="#1ED760"
    />
  </svg>
)
export default SocialSpotify
