import { forwardRef, ForwardRefRenderFunction, MutableRefObject } from 'react'

import {
  Box,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps as ChakraInputProps,
  InputRightElement,
} from '@chakra-ui/react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import _ from 'lodash'
import { isAndroid } from 'react-device-detect'

import { RegisterOptions } from '@redwoodjs/forms'

import Icon from '../Icon/Icon'
import InputFooter from '../InputFooter/InputFooter'
import InputTitle from '../InputTitle/InputTitle'

import useInput from './useInput'

export type InputValue = string | number | string[]

export type InputProps = {
  name: string
  hint?: string
  clearable?: boolean
  icon?: IconDefinition
  rightIcon?: IconDefinition
  customIcon?: React.ReactNode
  validation?: RegisterOptions
  mask?: string
  hideLabel?: boolean
  shouldRegister?: boolean
  maxLength?: number
  noWarningMax?: number
  hideCounterMax?: number
  countDown?: boolean
  withCounter?: boolean
  onClear?: () => void
  onClickRightIcon?: React.MouseEventHandler
  format?: (value: string) => string
} & Omit<ChakraInputProps, 'onChange'> & {
    onChange?: (value: InputValue) => void
  }

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    w,
    clearable,
    rightIcon,
    customIcon,
    hint,
    hideLabel,
    onClickRightIcon,
    m,
    mb,
    mt,
    ml,
    mr,
    noWarningMax,
    hideCounterMax,
    countDown,
    ...props
  },
  inputElRef
) => {
  const {
    toggleShowPassword,
    clear,
    inputProps,
    title,
    shifted,
    variant,
    icon,
    field,
    type,
    showPassword,
    hasErrors,
    count,
    showCounter,
  } = useInput(props, inputElRef as MutableRefObject<HTMLInputElement>)

  return (
    <Box
      position="relative"
      w={w || { base: '100%', md: '340px' }}
      role="group"
      m={m}
      mb={mb}
      mt={mt}
      ml={ml}
      mr={mr}
    >
      <InputGroup
        w={w || { base: '100%', md: '340px' }}
        variant={variant}
        zIndex="base"
      >
        {customIcon ? (
          <InputLeftElement mt={3} ml={3}>
            {customIcon}
          </InputLeftElement>
        ) : (
          !!icon && (
            <InputLeftElement
              mt="12px"
              ml={2}
              color={
                field.error
                  ? 'danger'
                  : field.hasFocus || !!field.value
                  ? 'onyx'
                  : 'graphite'
              }
            >
              <Icon icon={icon} />
            </InputLeftElement>
          )
        )}
        <ChakraInput
          {...inputProps}
          pl={customIcon || icon ? 9 : 4}
          ref={field.ref}
          enterKeyHint={isAndroid ? 'go' : 'done'}
        />
        {type === 'password' && (
          <InputRightElement mt="14px" mx={2} color="graphite">
            <Icon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={toggleShowPassword}
            />
          </InputRightElement>
        )}
        {!!rightIcon && (
          <InputRightElement mt="14px" mx={2} color="onyx">
            <Icon icon={rightIcon} onClick={onClickRightIcon} />
          </InputRightElement>
        )}
        {clearable && (
          <InputRightElement mt="14px" mx={2} color="graphite">
            <Icon
              icon={faCircleXmark}
              onClick={clear}
              data-testid={`Input-${props.name}-clearButton`}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {title && !hideLabel && (
        <InputTitle
          shifted={shifted}
          hasValue={!!field.value}
          hasFocus={field.hasFocus}
          hasErrors={hasErrors}
          value={title}
        />
      )}
      <InputFooter
        showCounter={showCounter}
        hasErrors={hasErrors}
        errorMessage={field.error?.message.toString()}
        hint={hint}
        count={count}
        maxLength={props.maxLength}
        noWarningMax={noWarningMax}
        countDown={countDown}
        hideCounterMax={hideCounterMax}
      />
    </Box>
  )
}

export default forwardRef<HTMLInputElement, InputProps>(Input)
