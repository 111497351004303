import {
  Flex,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

import { Link, routes } from '@redwoodjs/router'

import Icon from 'src/components/widgets/Icon/Icon'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'
import { getAbsoluteUrl } from 'src/lib/utils'

const MenuItems = () => {
  const { isCreator, isAffiliate, username } = useCurrentUser()
  return (
    <Flex gap={10} className="body-extra-small">
      <Menu variant="header">
        <MenuButton data-testid="header-it-lists-menu">
          <Flex>
            <Box mr={2}>ItLists</Box>
            <Icon icon={faChevronDown} />
          </Flex>
        </MenuButton>
        <MenuList>
          {(isCreator || isAffiliate) && (
            <Link to={routes.createdByMe()}>
              <MenuItem data-coachmark="created-by-me-1">
                Created by Me
              </MenuItem>
            </Link>
          )}
          {isCreator && username && (
            <a href={getAbsoluteUrl(routes.userItLists({ username }))}>
              <MenuItem>Share Hub</MenuItem>
            </a>
          )}
          <Link to={routes.ownedItLists()}>
            <MenuItem>Saved and Purchased</MenuItem>
          </Link>
        </MenuList>
      </Menu>
      {isCreator && (
        <Link data-coachmark="created-by-me-2" to={routes.analytics()}>
          Analytics
        </Link>
      )}
      <Link data-coachmark="created-by-me-3" to={routes.affiliateDashboard()}>
        Affiliate Dashboard
      </Link>
    </Flex>
  )
}

export default MenuItems
