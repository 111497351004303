import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'

import { Link as RedwoodLink } from '@redwoodjs/router'

export type ItListLinkProps = {
  to: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  children: React.ReactNode | React.ReactNode[]
} & LinkProps

const Link = ({ to, onClick, children, ...props }: ItListLinkProps) => (
  <ChakraLink {...props} as={RedwoodLink} to={to} onClick={onClick}>
    {children}
  </ChakraLink>
)

export default Link
