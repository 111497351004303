import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Accordion: ComponentStyleConfig = {
  baseStyle: {
    container: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'sandstonePlus',
      borderRadius: 12,
    },
    icon: {
      w: '24px',
      h: '24px',
    },
  },
}
