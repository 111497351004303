import type { SVGProps } from 'react'
const ErrorGeneric = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={140}
    height={140}
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1854_4307)">
      <path
        d="M81.6284 71.3622L74.7684 69.7263L45.0247 39.0012C40.4258 41.296 36.6358 44.9383 34.16 49.4424L46.676 63.0357L32.2847 59.6057C29.4385 64.0213 28.2149 68.6637 28.8547 73.5847L65.352 83.3077C68.4075 86.6208 69.8747 88.4961 72.7867 90.1677C75.7792 91.8834 80.6484 93.7888 86.0349 92.379C86.7104 92.2873 83.8096 77.63 81.6284 71.3622Z"
        fill="white"
      />
      <path
        d="M98.959 22.0031L95.1951 14.1162C94.9263 13.5502 94.5483 13.0426 94.0835 12.6227C93.618 12.2027 93.0748 11.8784 92.4847 11.6684C91.8939 11.4584 91.2681 11.3669 90.6423 11.3989C90.0165 11.431 89.4033 11.586 88.837 11.8552L84.9534 13.7032L92.8137 30.2099L96.698 28.3612C97.265 28.0934 97.7732 27.7161 98.1946 27.2513C98.6153 26.7863 98.9401 26.2429 99.1501 25.6521C99.3601 25.0613 99.4511 24.4348 99.4189 23.8085C99.386 23.1823 99.2299 22.5688 98.959 22.0031ZM94.2109 23.0006C94.2109 23.0359 94.1969 23.0697 94.1717 23.0947C94.1472 23.1196 94.1129 23.1336 94.0779 23.1336C94.0429 23.1336 94.0086 23.1196 93.9841 23.0947C93.9589 23.0697 93.9449 23.0359 93.9449 23.0006V20.02L91.9632 20.8978C91.9429 20.9051 91.9212 20.9076 91.9002 20.9052C91.8792 20.9028 91.8582 20.8956 91.84 20.8839C91.8225 20.8724 91.8071 20.8568 91.7959 20.8385C91.7847 20.8201 91.7784 20.7994 91.7763 20.7781V17.6008C91.7763 17.5655 91.7903 17.5317 91.8155 17.5067C91.84 17.4818 91.8743 17.4678 91.9093 17.4678C91.9443 17.4678 91.9786 17.4818 92.0031 17.5067C92.0283 17.5317 92.0423 17.5655 92.0423 17.6008V20.58L94.0247 19.7022C94.0443 19.6941 94.0653 19.6912 94.0863 19.6935C94.1073 19.6958 94.1269 19.7034 94.1444 19.7155C94.1633 19.7266 94.1794 19.742 94.1906 19.7606C94.2025 19.7791 94.2095 19.8001 94.2109 19.8219V23.0006Z"
        fill="#1C1E21"
      />
      <path
        d="M109.706 57.862C109.784 58.1847 109.792 58.5199 109.733 58.8462C110.653 58.437 111.49 57.8647 112.207 57.1571C111.395 57.4634 110.558 57.6993 109.706 57.862Z"
        fill="#1C1E21"
      />
      <path
        d="M83.4905 69.4204C81.9876 65.7762 78.9013 59.6309 76.2014 60.3358C74.4325 60.8013 73.6611 64.1662 73.9271 68.8758L74.7517 69.727L81.6152 71.3629C81.3576 70.5208 81.0006 69.7129 80.5512 68.9556C81.4801 69.1278 82.5301 69.2741 83.4905 69.4204Z"
        fill="#1C1E21"
      />
      <path
        d="M33.1339 26.565C31.9901 28.6006 31.5379 35.9156 30.3675 39.8524C29.9135 41.3019 29.6192 42.7966 29.4897 44.31C30.118 45.3469 30.9187 46.2689 31.8571 47.0365C33.0756 44.0054 34.833 41.2199 37.0441 38.815C35.6847 35.3157 35.999 31.3404 33.1339 26.565Z"
        fill="#1C1E21"
      />
      <path
        d="M26.0848 63.1288C22.9915 60.8342 21.3073 57.4469 16.2421 54.8562C16.3618 57.1837 19.8863 63.6209 20.9902 67.571C21.3748 69.0379 21.9235 70.4571 22.6261 71.8011C23.7116 72.3415 24.8813 72.6922 26.0848 72.8385C25.5038 69.628 25.5038 66.3392 26.0848 63.1288Z"
        fill="#1C1E21"
      />
      <path
        d="M98.8925 57.8354C98.4802 61.719 100.542 62.5037 102.617 59.2452C101.313 58.9599 100.058 58.4849 98.8925 57.8354Z"
        fill="#1C1E21"
      />
      <path
        d="M101.592 51.3443C102.257 51.6635 103.348 51.9295 103.614 51.7433L104.013 50.7724C103.375 50.001 102.404 50.47 101.592 51.3443Z"
        fill="#1C1E21"
      />
      <path
        d="M114.87 49.0168C114.285 46.8524 112.675 44.2687 108.978 41.0634C108.145 44.2411 107.501 47.4653 107.05 50.7192C108.797 50.285 110.595 50.0793 112.396 50.1074C112.556 49.7616 112.891 50.1284 113.314 51.3443C113.328 51.3842 113.367 51.4906 113.407 51.6243C113.492 51.5958 113.583 51.5867 113.673 51.5977C113.633 51.0524 113.568 50.4539 113.487 49.8022C113.095 46.6627 114.011 46.2266 114.843 49.3766C114.896 49.5761 115.003 50.1214 115.123 50.7864C115.111 50.1885 115.026 49.5942 114.87 49.0168Z"
        fill="#1C1E21"
      />
      <path
        d="M113.084 71.6281L112.419 76.19C112.419 76.19 114.401 73.663 115.093 72.48L113.084 71.6281Z"
        fill="#1C1E21"
      />
      <path
        d="M43.3888 105.092L57.1151 107.766L44.2533 96.3144L30.527 93.6404L43.3888 105.092Z"
        fill="#1C1E21"
      />
      <path
        d="M57.3945 107.566L70.1898 99.1739L55.1867 97.3518L49.8527 100.85L57.3945 107.566Z"
        fill="#1C1E21"
      />
      <path
        d="M54.9207 49.2164C59.9009 47.2178 65.2943 46.4675 70.6308 47.0308C75.9676 47.5941 81.0853 49.454 85.5387 52.4483"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.9175 61.4264C40.6498 60.3353 41.4444 59.2872 42.2975 58.2876"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.3747 95.3303C35.7542 90.5199 34.3864 85.1278 34.3973 79.6503C34.3981 78.1425 34.5003 76.6361 34.7032 75.1416"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.2117 86.8441C98.0049 92.2159 95.466 97.1971 91.8288 101.33C88.1909 105.463 83.5716 108.613 78.3965 110.492C73.2221 112.371 67.6572 112.917 62.2157 112.081C56.7741 111.243 51.6306 109.051 47.259 105.704"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.6373 17.1753C101.672 15.8985 104.386 15.2587 106.7 16.0847C110.531 17.4525 112.464 22.0445 111.515 25.8602C110.628 29.426 107.805 32.5249 105.888 35.5699C102.658 40.7051 101.338 44.5215 103.242 50.3868"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.4453 16.7231L98.0812 20.1412L99.2383 19.5812C99.5519 19.4288 99.7927 19.1595 99.9096 18.8312C100.027 18.5028 100.01 18.1417 99.8634 17.8256L99.3734 16.7882C99.2208 16.4747 98.952 16.2336 98.6237 16.1167C98.2954 15.9998 97.9342 16.0165 97.6178 16.1631L96.4453 16.7231Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.827 30.21L84.9667 13.7033L77.1722 17.4147L85.0332 33.9207L92.827 30.21Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.9723 22.0032L95.2084 14.103C94.9396 13.5369 94.5616 13.0294 94.0968 12.6094C93.6313 12.1894 93.0881 11.8652 92.498 11.6552C91.9072 11.4452 91.2814 11.3536 90.6556 11.3857C90.0298 11.4178 89.4159 11.5728 88.8503 11.842L84.9667 13.7033L92.827 30.21L96.7113 28.3613C97.2776 28.0923 97.7844 27.7145 98.2044 27.2494C98.6244 26.7842 98.9485 26.241 99.1585 25.6505C99.3685 25.0601 99.4602 24.4341 99.428 23.8083C99.3965 23.1825 99.2411 22.5691 98.9723 22.0032Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.0332 33.9206L77.1722 17.4146L69.3785 21.1253L77.2387 37.6313L85.0332 33.9206Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.4726 29.2258C63.2035 28.6598 63.0486 28.0463 63.0166 27.4205C62.9847 26.7947 63.0763 26.1687 63.2864 25.5782C63.4964 24.9878 63.8207 24.4446 64.2407 23.9794C64.6607 23.5143 65.1682 23.1366 65.7343 22.8677L69.3785 21.1254L77.2388 37.6314L73.5946 39.3737C73.029 39.6428 72.4151 39.7979 71.7893 39.8299C71.1635 39.8619 70.5377 39.7704 69.9473 39.5604C69.357 39.3504 68.8138 39.0262 68.3488 38.6062C67.8837 38.1862 67.506 37.6787 67.2372 37.1127L63.4726 29.2258Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.3485 83.3064L28.84 73.5834C28.2016 68.6624 29.4252 64.02 32.27 59.6044L46.6746 63.0344"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.6152 71.3622L74.765 69.7263"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.379 87.5091C83.0753 87.9459 80.696 87.7387 78.5022 86.9106C75.6553 85.8886 73.1122 84.1652 71.1074 81.9L37.5627 44.66"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8778 68.3166L58.5648 75.9508"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.046 50.7592C106.168 57.769 107.831 58.8995 112.193 57.1572"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.15 75.2991L45.0156 39.0062C40.4206 41.3009 36.6339 44.9413 34.16 49.4425L68.4895 86.7245C69.7307 88.0867 71.1753 89.2487 72.772 90.1692C75.7708 91.8898 80.6421 93.7903 86.0335 92.3805"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.543 59.2852C110.172 61.6652 109.773 58.1015 109.773 58.1015L109.745 57.8537"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.533 59.3782C108.868 62.4372 96.1394 70.3514 77.4249 60.4688"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.9269 68.8751C73.6609 64.1662 74.4316 60.7999 76.2012 60.3351C78.9004 59.6274 81.9874 65.7748 83.4903 69.4197C82.5992 69.2335 81.5086 69.0739 80.5503 68.9542"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1338 26.565C32.7747 25.9532 32.4688 25.606 32.2427 25.634C30.8315 25.8104 30.4752 33.8149 29.2768 37.8308C28.8392 39.1889 28.5748 40.5968 28.49 42.021C28.6648 42.8438 29.0048 43.6226 29.4895 44.31C29.6098 42.7958 29.9043 41.3005 30.3674 39.8538C31.5371 35.9156 31.99 28.6006 33.1338 26.565Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0441 38.8151C36.1397 36.4876 36.0109 34.3456 35.4215 32.1385C34.8972 30.1916 34.1285 28.3189 33.1339 26.5651C31.9901 28.6007 31.5309 35.9136 30.3675 39.8525C29.9125 41.3017 29.6182 42.7965 29.4897 44.3101C30.1181 45.3469 30.9187 46.2689 31.8571 47.0366C33.0755 44.0053 34.8328 41.2199 37.0441 38.8151Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8571 47.0352L34.158 49.4425"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.044 38.8151L40.0904 42.2339"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.24 54.8563C15.6149 54.5364 15.1627 54.4041 14.9898 54.5504C13.9006 55.4695 17.85 62.4142 18.9931 66.4406C19.3529 67.8209 19.8799 69.1521 20.5625 70.4047C21.1465 71.0102 21.8479 71.4897 22.624 71.8145C21.9212 70.4705 21.3725 69.0514 20.9881 67.5844C19.8933 63.6189 16.3618 57.1838 16.24 54.8563Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0848 63.1289C24.0765 61.6393 22.8116 59.8984 21.1498 58.3542C19.6672 56.9863 18.0183 55.8104 16.2421 54.8542C16.3618 57.1817 19.8821 63.6203 20.9902 67.569C21.3742 69.0362 21.923 70.4551 22.6261 71.7991C23.7116 72.3395 24.8813 72.6902 26.0848 72.8365C25.504 69.6267 25.504 66.3387 26.0848 63.1289Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0981 72.8385L28.8379 73.5833"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0848 63.1289L29.9285 64.2328"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.162 88.6529C119.554 89.4999 119.686 90.4442 119.542 91.3661C119.397 92.288 118.982 93.1462 118.35 93.8329C117.717 94.5189 116.895 95.0019 115.988 95.221C115.081 95.4401 114.129 95.3855 113.253 95.0635C112.377 94.7422 111.616 94.1675 111.066 93.4136C110.517 92.659 110.203 91.7588 110.165 90.8264C110.127 89.894 110.366 88.9714 110.853 88.1748C111.339 87.3782 112.051 86.744 112.898 86.352C113.46 86.0909 114.068 85.9432 114.688 85.9173C115.307 85.8921 115.926 85.9887 116.508 86.2029C117.09 86.4164 117.624 86.7426 118.079 87.1633C118.534 87.584 118.903 88.0901 119.162 88.6529Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.536 87.722L114.88 90.6347L117.739 89.318"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.013 50.7725C103.891 50.622 103.73 50.5072 103.548 50.44C102.358 50.0067 100.542 52.1025 99.5176 55.0824C98.4935 58.0623 98.6412 60.8098 99.8501 61.227C100.65 61.5028 101.699 60.6817 102.63 59.2453"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.548 50.4399C100.144 49.6461 96.7835 48.6784 93.4795 47.5405C89.6757 46.2231 83.3168 48.2188 86.3898 55.5079C87.8927 59.0723 92.2419 63.1554 95.7272 65.2701"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.8501 61.2269L90.4064 56.4522"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.844 57.4364C107.552 57.8088 107.261 58.17 106.98 58.4864C105.685 59.9445 102.297 59.6967 98.8792 57.848"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.587 51.7168C103.321 51.9163 102.257 51.6503 101.592 51.3444"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.875 49.9745C105.888 49.8282 105.915 48.8545 105.928 48.7243C105.995 46.3835 105.456 46.7846 104.625 49.1898C103.918 51.0984 103.111 52.9678 102.204 54.7898"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.991 45.2796C107.764 44.455 107.101 45.1473 106.554 47.248C106.103 48.9777 105.755 50.468 105.41 51.5971"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.993 51.2513C106.231 51.2691 105.489 51.4994 104.851 51.9163"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.239 73.64L116.859 68.7323C117.603 64.6485 119.958 60.592 123.299 58.5571C123.299 58.5571 130.985 63.5593 134.963 67.5619C138.892 71.5141 135.733 79.8882 126.278 77.497C123.158 76.7032 119.022 74.2637 115.093 72.4745C107.049 85.3545 92.0577 90.265 87.3726 91.9681C86.9225 92.1312 86.4745 92.2677 86.03 92.3839C86.8147 92.2775 82.6644 71.9908 80.5497 68.9584C85.7899 69.9958 101.844 71.9376 112.511 60.9784"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.846 55.0824C117.846 55.0824 118.67 53.0195 119.736 52.6324C120.88 52.2194 118.869 55.9714 118.978 58.0595C119.017 58.8043 121.412 60.0146 121.412 60.0146"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.436 49.735C116.343 48.5513 116.537 44.7321 117.433 48.4316C117.604 49.4261 117.715 50.4298 117.766 51.4374C117.956 53.1142 117.925 54.8089 117.673 56.4774"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.75 52.6212C113.697 52.4749 113.378 51.5712 113.338 51.4375C112.54 49.243 112.177 49.8149 112.3 52.3552C112.404 54.4972 112.184 57.6227 112.141 58.4606C111.98 61.5868 113.378 62.4905 117.66 65.749"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.505 53.3925C115.332 51.8896 114.97 49.8925 114.853 49.4557C114.019 46.3092 113.099 46.7558 113.497 49.8813C113.723 51.6635 113.749 53.0068 113.856 54.1912"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.88 49.5488C114.867 43.4588 116.07 46.0236 116.396 49.4158C116.544 50.9453 116.715 52.0758 116.742 52.9802"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.881 49.0742C114.321 46.8944 112.704 44.2967 108.975 41.0634C108.142 44.2411 107.498 47.4654 107.046 50.7192C108.794 50.285 110.592 50.0794 112.393 50.1074"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.661 62.37C107.472 63.1148 108.257 63.77 110.637 62.7291"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.206 57.1571C111.661 57.9551 109.733 58.8462 109.733 58.8462"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 85.86L10.4034 91.8184L12.2255 79.3556"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9191 20.2343C49.91 20.5224 49.8155 20.8014 49.6478 21.0359C49.4801 21.2703 49.2466 21.4498 48.9769 21.5516C48.7071 21.6534 48.4132 21.6728 48.1324 21.6075C47.8516 21.5422 47.5965 21.3951 47.3993 21.1848C47.2021 20.9745 47.0717 20.7104 47.0246 20.426C46.9775 20.1416 47.0159 19.8495 47.1348 19.5869C47.2537 19.3243 47.4478 19.1028 47.6926 18.9505C47.9374 18.7982 48.2219 18.722 48.51 18.7314C48.7012 18.7375 48.8894 18.7813 49.0637 18.8601C49.238 18.939 49.395 19.0514 49.5259 19.191C49.6567 19.3305 49.7588 19.4945 49.8263 19.6735C49.8937 19.8525 49.9253 20.043 49.9191 20.2343Z"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.3925 122.53L80.0856 128.622"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.2851 122.73L74.193 128.422"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.093 72.471C114.415 72.1623 113.744 71.8732 113.084 71.6149L112.419 76.1901"
        stroke="#1C1E21"
        strokeWidth={0.796678}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1854_4307">
        <rect width={140} height={140} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default ErrorGeneric
