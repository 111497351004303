import type { SVGProps } from 'react'
const SocialSubstack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={24} height={24} rx={12} fill="white" />
    <g clipPath="url(#clip0_2848_6539)">
      <path
        d="M17.0279 8.84235H6.97168V10.2007H17.0279V8.84235Z"
        fill="#FF6719"
      />
      <path
        d="M6.97168 11.4313V17.7469L11.9996 14.9264L17.0283 17.7469V11.4313H6.97168Z"
        fill="#FF6719"
      />
      <path
        d="M17.0279 6.25336H6.97168V7.6115H17.0279V6.25336Z"
        fill="#FF6719"
      />
    </g>
    <defs>
      <clipPath id="clip0_2848_6539">
        <rect
          width={10.0566}
          height={11.4933}
          fill="white"
          transform="translate(6.97168 6.25336)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default SocialSubstack
