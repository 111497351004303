import { Box, HStack } from '@chakra-ui/react'

import { useWatch } from '@redwoodjs/forms'

import SocialIcon from 'src/components/widgets/SocialIcon/SocialIcon'
import { getLinkDisplayName } from 'src/lib/utils'

const LinkElement: React.FC<{
  name: `items.${number}`
}> = ({ name }) => {
  const url = useWatch({ name: `${name}.url` })
  const itemName = useWatch({ name: `${name}.name` })

  return (
    <HStack w="100%" mr={4} overflow="hidden" spacing={4}>
      <Box ml={4}>
        <SocialIcon url={url} />
      </Box>

      <Box
        className="body-small"
        color="onyx"
        noOfLines={1}
        wordBreak="break-all"
      >
        {getLinkDisplayName(url, itemName)}
      </Box>
    </HStack>
  )
}

export default LinkElement
