import { faLink } from '@fortawesome/pro-solid-svg-icons'

import AmazonIcon from 'src/assets/icon/SocialAmazonSVG'
import ApplePodcastIcon from 'src/assets/icon/SocialApplePodcastsSVG'
import BuyMeACoffeIcon from 'src/assets/icon/SocialBuymeacoffeeSVG'
import DiscordIcon from 'src/assets/icon/SocialDiscordSVG'
import FacebookIcon from 'src/assets/icon/SocialFacebookSVG'
import InstagramIcon from 'src/assets/icon/SocialInstagramSVG'
import LTKIcon from 'src/assets/icon/SocialLiketoknowSVG'
import LinkedInIcon from 'src/assets/icon/SocialLinkedinSVG'
import MediumIcon from 'src/assets/icon/SocialMediumSVG'
import PatreonIcon from 'src/assets/icon/SocialPatreonSVG'
import PinterestIcon from 'src/assets/icon/SocialPinterestSVG'
import PoshmarkIcon from 'src/assets/icon/SocialPoshmarkSVG'
import SnapchatIcon from 'src/assets/icon/SocialSnapchatSVG'
import SoundCloudIcon from 'src/assets/icon/SocialSoundcloudSVG'
import SpotifyIcon from 'src/assets/icon/SocialSpotifySVG'
import SubstackIcon from 'src/assets/icon/SocialSubstackSVG'
import TikTokIcon from 'src/assets/icon/SocialTiktokSVG'
import TwitchIcon from 'src/assets/icon/SocialTwitchSVG'
import TwitterIcon from 'src/assets/icon/SocialTwitterSVG'
import VimeoIcon from 'src/assets/icon/SocialVimeoSVG'
import YouTubeIcon from 'src/assets/icon/SocialYoutubeSVG'
import { matchUrl } from 'src/lib/utils'
import { ThemeColor } from 'src/types'

import Icon from '../Icon/Icon'

const ICONS = {
  generic: (color: string) => (
    <Icon
      data-testid="socialIcon-generic"
      icon={faLink}
      color={color}
      boxSize="24px"
      fontSize="12px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      bg="white"
    />
  ),
  applePodcast: <ApplePodcastIcon data-testid="socialIcon-applePodcast" />,
  buyMeACoffe: <BuyMeACoffeIcon data-testid="socialIcon-buyMeACoffe" />,
  discord: <DiscordIcon data-testid="socialIcon-discord" />,
  facebook: <FacebookIcon data-testid="socialIcon-facebook" />,
  instagram: <InstagramIcon data-testid="socialIcon-instagram" />,
  linkedIn: <LinkedInIcon data-testid="socialIcon-linkedIn" />,
  ltk: <LTKIcon data-testid="socialIcon-ltk" />,
  medium: <MediumIcon data-testid="socialIcon-medium" />,
  patreon: <PatreonIcon data-testid="socialIcon-patreon" />,
  pinterest: <PinterestIcon data-testid="socialIcon-pinterest" />,
  poshmark: <PoshmarkIcon data-testid="socialIcon-poshmark" />,
  snapchat: <SnapchatIcon data-testid="socialIcon-snapchat" />,
  soundCloud: <SoundCloudIcon data-testid="socialIcon-soundCloud" />,
  spotify: <SpotifyIcon data-testid="socialIcon-spotify" />,
  substack: <SubstackIcon data-testid="socialIcon-substack" />,
  tikTok: <TikTokIcon data-testid="socialIcon-tikTok" />,
  twitch: <TwitchIcon data-testid="socialIcon-twitch" />,
  twitter: <TwitterIcon data-testid="socialIcon-twitter" />,
  vimeo: <VimeoIcon data-testid="socialIcon-vimeo" />,
  youtube: <YouTubeIcon data-testid="socialIcon-youtube" />,
  amazon: <AmazonIcon data-testid="socialIcon-amazon" />,
}

const WEBSITES = [
  { hostname: 'podcasts.apple.com', name: 'applePodcast' },
  { hostname: 'buymeacoffee.com', name: 'buyMeACoffe' },
  { hostname: 'discord.com', name: 'discord' },
  { hostname: 'facebook.com', name: 'facebook' },
  { hostname: 'instagram.com', name: 'instagram' },
  { hostname: 'linkedin.com', name: 'linkedIn' },
  { hostname: 'liketoknow.it', name: 'ltk' },
  { hostname: 'liketk.it', name: 'ltk' },
  { hostname: 'medium.com', name: 'medium' },
  { hostname: 'patreon.com', name: 'patreon' },
  { hostname: 'pinterest.com', name: 'pinterest' },
  { hostname: 'poshmark.com', name: 'poshmark' },
  { hostname: 'snapchat.com', name: 'snapchat' },
  { hostname: 'soundcloud.com', name: 'soundCloud' },
  { hostname: 'spotify.com', name: 'spotify' },
  { hostname: 'substack.com', name: 'substack' },
  { hostname: 'tiktok.com', name: 'tikTok' },
  { hostname: 'twitch.tv', name: 'twitch' },
  { hostname: 'twitter.com', name: 'twitter' },
  { hostname: 'vimeo.com', name: 'vimeo' },
  { hostname: 'youtube.com', name: 'youtube' },
  { hostname: 'youtu.be', name: 'youtube' },
  { hostname: 'amazon.com', name: 'amazon' },
  { hostname: 'a.co', name: 'amazon' },
]

const SocialIcon = ({
  name,
  url,
  themeColor = 'onyx',
}: {
  name?: string
  url?: string
  themeColor?: ThemeColor
}) => {
  const socialKey = name || getWebsite(url)
  return ICONS[socialKey] || ICONS.generic(themeColor)
}

function getWebsite(url?: string) {
  if (!url) return
  const website = WEBSITES.find((website) => matchUrl(website.hostname, url))
  return website?.name
}

export default SocialIcon
