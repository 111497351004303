import { MouseEventHandler } from 'react'

import _ from 'lodash'

import { useFormContext } from '@redwoodjs/forms'

import { ActionMenuProps } from '../EditableList'

export default function useEditItem(
  expanded: boolean,
  index: number,
  isEmpty: (item: unknown) => boolean,
  onClose: () => void,
  onRemove: (index?: number) => void
) {
  const { getValues } = useFormContext<{ items: unknown[] }>()

  const formFieldName = `items.${index}` as `items.${number}`

  const closeAccordionHandler: MouseEventHandler = (ev) => {
    if (expanded) {
      ev.stopPropagation()
      ev.preventDefault()
      closeHandler()
    }
  }

  const closeHandler = async () => {
    const item = getValues(formFieldName)

    if (isEmpty(item)) {
      onRemove(index)
    } else {
      onClose()
    }
  }

  const clickRemoveHandler: ActionMenuProps['onClickRemove'] =
    React.useCallback(
      (ev) => {
        ev?.stopPropagation()
        ev?.preventDefault()
        onRemove(index)
      },
      [index, onRemove]
    )

  return { formFieldName, closeAccordionHandler, clickRemoveHandler }
}
