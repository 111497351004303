import { BoxProps } from '@chakra-ui/react'

import CenteredSpinner from './CenteredSpinner/CenteredSpinner'
import InlineSpinner from './InlineSpinner/InlineSpinner'

export type LoaderProps = {
  show?: boolean
  inline?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  color?: string
  thickness?: string
  label?: string
} & BoxProps

const Loader = ({ show = true, inline = false, ...props }: LoaderProps) =>
  show ? (
    inline ? (
      <InlineSpinner {...props} />
    ) : (
      <CenteredSpinner {...props} />
    )
  ) : null

export const LoaderFullScreen = () => <Loader h="100%" />

export default Loader
