import { Box, SystemProps } from '@chakra-ui/react'

type ErrorMessageProps = {
  message?: string
} & SystemProps

const ErrorMessage = ({ message, ...props }: ErrorMessageProps) => {
  return message ? (
    <Box
      className="body-extra-small-emphasis"
      color="danger"
      fontSize="12px"
      data-testid="errorMessage-error"
      {...props}
    >
      {message}
    </Box>
  ) : null
}

export default ErrorMessage
