import { Private, Route, Router, Set } from '@redwoodjs/router'

import { useAuth } from './auth'
import AfterLogin from './components/layout/NavigationAgents/AfterLogin/AfterLogin'
import Onboarding from './components/layout/NavigationAgents/Onboarding/Onboarding'
import { LoaderFullScreen } from './components/widgets/Loader/Loader'
import AdminLayout from './layouts/AdminLayout/AdminLayout'
import GlobalLayout from './layouts/GlobalLayout/GlobalLayout'
import MainLayout from './layouts/MainLayout/MainLayout'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Set wrap={GlobalLayout} whileLoadingPage={LoaderFullScreen}>
        <Route path="/gift-edit/{giftId:Int}" page={GiftEditPage} name="giftEdit" />
        {/** Auth & Activation. */}
        <Route path="/set-password" page={SetPasswordPage} name="setPassword" />
        <Route path="/enter-activation-code" page={EnterActivationCodePage} name="enterActivationCode" />
        <Route path="/authenticate/{email:string}" page={AuthenticatePage} name="authenticate" />
        <Route path="/signin" page={RegistrationPage} name="signin" />
        <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
        <Route path="/email-updated" page={EmailUpdatedPage} name="emailUpdated" />

        {/** Checkout Auth & Activation. */}
        <Route path="/checkout-completed" page={CheckoutCompletedPage} name="checkoutCompleted" />
        <Route path="/checkout-set-password" page={CheckoutSetPasswordPage} name="checkoutSetPassword" />
        <Route path="/checkout-enter-activation-code" page={CheckoutEnterActivationCodePage} name="checkoutEnterActivationCode" />
        <Route path="/checkout-authenticate/{email:string}" page={CheckoutAuthenticatePage} name="checkoutAuthenticate" />
        <Route path="/checkout-registration" page={CheckoutRegistrationPage} name="checkoutRegistration" />
        <Route path="/checkout-session-redirect" page={CheckoutSessionRedirectPage} name="checkoutSessionRedirect" />
        <Route path="/checkout-form" page={CheckoutFormPage} name="checkoutForm" />

        <Set wrap={[Onboarding, AfterLogin]}>
          <Set wrap={MainLayout}>
            {/** Public access primary pages */}
            <Route path="/about-us" page={AboutUsPage} name="aboutUs" />
            <Route path="/privacy" page={PrivacyPage} name="privacy" />
            <Route path="/terms" page={TermsPage} name="terms" />
            <Route path="/cookies" page={CookiesPage} name="cookies" />
            <Route notfound page={NotFoundPage} />
            <Route path="/" page={HomePage} name="home" />
            <Route path="/profile-non-creator-take-over" page={ProfileNonCreatorTakeOverPage} name="profileNonCreatorTakeOver" />
            {/** Marketing pages */}
            <Route path="/redeem-gift/{code:string}" page={RedeemGiftPage} name="redeemGift" />
          </Set>

          <Set wrap={MainLayout} withFooter={false} headerButtonVariant="secondaryGrayOutline">
            {/** User pages */}
            <Route path="/@{username:string}" page={ProfilePage} name="profile" />
            <Route path="/@{username:string}/share" page={UserItListsPage} name="userItLists" />
          </Set>

          {/** ItList details page */}
          <Route path="/i/{id:Int}/{title:string}" page={ItListPage} name="itList" />
          <Route path="/i/{id:Int}" page={ItListPage} name="itListCanonical" />

          {/** Authenticated routes. */}
          <Private unauthenticated="home" roles={'explorer'}>
            {/** Waitlist & onboarding pages */}
            <Route path="/get-verified" page={WaitlistRegistrationPage} name="waitlistRegistration" />
            <Route path="/verification-pending" page={WaitlistWaitingPage} name="waitlistWaiting" />
          </Private>
          <Private unauthenticated="home" roles={'creator'}>
            {/** Onboarding pages. */}
            <Route path="/onboarding" page={OnboardingPage} name="onboarding" />
            <Route path="/onboarding-goals" page={OnboardingGoalsPage} name="onboardingGoals" />
            {/** Creator List management pages. */}
            <Route path="/edit-it-list/{id:Int}" page={EditItListPage} name="editItList" />
          </Private>
          <Private wrap={MainLayout} unauthenticated="home" roles={['creator', 'explorer']}>
            <Route path="/settings" page={SettingsPage} name="settings" />
            <Route path="/owned-it-lists" page={OwnedItListsPage} name="ownedItLists" />
          </Private>
          <Private wrap={MainLayout} unauthenticated="home" roles={['creator', 'affiliate']} px={0}>
            <Route path="/affiliate-dashboard" page={AffiliateDashboardPage} name="affiliateDashboard" />
          </Private>
          <Private wrap={MainLayout} unauthenticated="home" roles={['creator', 'affiliate']}>
            <Route path="/referral" page={ReferralPage} name="referral" />
          </Private>
          <Private wrap={MainLayout} unauthenticated="home" roles={'creator'}>
            <Route path="/analytics" page={AnalyticsPage} name="analytics" />
          </Private>
          <Private wrap={MainLayout} unauthenticated="home" roles={['creator', 'affiliate']} withFooter={false}>
            <Route path="/created-by-me" page={CreatedByMePage} name="createdByMe" />
            <Route path="/created-by-me/{id:Int}/{shareLink:string}/{imageUrl:string}/{showConfetti:Boolean}" page={CreatedByMePage} name="createdByMeSuccess" />
          </Private>
          <Private wrap={AdminLayout} unauthenticated="home" roles={'admin'}>
            <Route path="/admin-home" page={AdminHomePage} name="adminHome" />
            <Route path="/waitlist-table" page={WaitlistTablePage} name="waitlistTable" />
            <Route path="/creators-table" page={CreatorsTablePage} name="creatorsTable" />
            <Route path="/agency-table" page={AgencyTablePage} name="agencyTable" />
          </Private>
        </Set>
      </Set>
    </Router>
  )
}

export default Routes
