import { useEffect, useRef, useState } from 'react'

import raf from 'raf'

import * as audioPlayer from 'src/lib/audioPlayer'
import {
  VoiceTipAnalyticsProps,
  useAnalytics,
} from 'src/lib/hooks/analytics/useAnalytics'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'
import { generateUid } from 'src/lib/utils'

const SKIP_AMOUNT_IN_SECONDS = 10
const INITIAL_TIME = 0

export default function useAudioPlayerInline(
  mediaUrl: string,
  title: string,
  pictureUrl?: string,
  onPlay?: () => void,
  onStop?: () => void,
  veneerLabel?: string,
  trackingData?: VoiceTipAnalyticsProps
) {
  const playerIdRef = useRef(generateUid())
  const playerId = playerIdRef.current
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDurationState] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [time, setTime] = useState(INITIAL_TIME)
  const rafId = useRef<number>(0)
  const showVeneer = time === INITIAL_TIME && !!veneerLabel && !isPlaying
  const { trackVoiceTipClicked } = useAnalytics()
  const { id } = useCurrentUser()
  const setDuration = (duration: number) => {
    if (duration === Infinity) setDurationState(null)
    else setDurationState(duration)
  }
  const onClickPlay = async (ev: React.MouseEvent) => {
    ev.stopPropagation()
    ev.preventDefault()
    if (isLoading || isPlaying) return
    onPlay?.()
    trackVoiceTipClicked(mediaUrl, id, trackingData)
    setIsLoading(true)
    const { duration } = await audioPlayer.play(
      playerId,
      mediaUrl,
      title,
      pictureUrl,
      {
        onStop: stop,
        onPause: pause,
        onPlay: () => {
          setIsPlaying(true)
        },
      }
    )
    setIsLoading(false)
    setDuration(duration)
  }

  const onClickPause = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    ev.preventDefault()
    audioPlayer.pause()
  }

  const pause = () => {
    setIsPlaying(false)
    onStop?.()
  }

  const stop = () => {
    pause()
    setTime(INITIAL_TIME)
  }

  const skipForwards = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    ev.preventDefault()
    audioPlayer.seek(
      Math.min(duration, audioPlayer.seek() + SKIP_AMOUNT_IN_SECONDS)
    )
  }

  const skipBackwards = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    ev.preventDefault()
    audioPlayer.seek(Math.max(0, audioPlayer.seek() - SKIP_AMOUNT_IN_SECONDS))
  }

  const updateTime = () => {
    setTime(+audioPlayer.seek().toFixed(1))
    rafId.current = raf(updateTime)
  }

  const isCurrentAudio = () =>
    audioPlayer.currentPlayerId === playerId && audioPlayer.isPlaying()

  useEffect(() => {
    if (isCurrentAudio()) {
      setIsPlaying(true)
      setDuration(audioPlayer.duration())
      audioPlayer.setPlayerOptions({
        onStop: stop,
        onPause: pause,
      })
    }
    return () => {
      if (rafId.current) {
        raf.cancel(rafId.current)
      }
      if (isCurrentAudio()) {
        audioPlayer.setPlayerOptions(null)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (rafId.current) {
      raf.cancel(rafId.current)
    }
    if (isPlaying) {
      updateTime()
    }
  }, [isPlaying]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    skipForwards,
    skipBackwards,
    onClickPlay,
    onClickPause,
    showVeneer,
    isPlaying,
    isLoading,
    duration,
    time,
  }
}
