import { ColorModeScript } from '@chakra-ui/react'

import ModalForms from 'src/components/layout/ModalForms/ModalForms'
import SvgGradients from 'src/components/widgets/SvgGradients/SvgGradients'
import Toaster from 'src/components/widgets/Toaster/Toaster'
import { useGenericPageView } from 'src/lib/hooks/analytics/useGenericPageView'
import { useIdentify } from 'src/lib/hooks/analytics/useIdentify'
import { useLiveSession } from 'src/lib/hooks/analytics/useLiveSession'
import useReferralParam from 'src/lib/hooks/useReferralParam'

type GlobalLayoutProps = {
  children?: React.ReactNode
}

const GlobalLayout = ({ children }: GlobalLayoutProps) => {
  useGenericPageView()
  useLiveSession()
  useIdentify()
  useReferralParam()

  return (
    <>
      <ColorModeScript />
      <SvgGradients />
      {children}
      <ModalForms />
      <Toaster />
    </>
  )
}

export default GlobalLayout
