import { useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { Setting } from 'types/graphql'

import { useLocation } from '@redwoodjs/router'

import { SETTINGS_TIMEOUT_CHECK } from 'src/lib/constants'

import { useAnalytics } from './useAnalytics'

export const GET_LIVE_SESSION_PATH_QUERY = gql`
  query GetLiveSessionPath {
    setting(name: liveSessionPath) {
      value
    }
  }
`

export function useLiveSession() {
  const { pathname } = useLocation()
  const { liveSessionRecord } = useAnalytics()
  const [started, setStarted] = useState(false)
  const [lastCheckTime, setLastCheckTime] = useState<number>()
  const [liveSessionPathRegExps, setLiveSessionPathRegExps] = useState<
    RegExp[]
  >([])
  const client = useApolloClient()
  const startLiveSessionIfPathMatches = (pathRegExps: RegExp[]) => {
    pathRegExps.forEach((item) => {
      if (pathname.match(item)) {
        liveSessionRecord()
        setStarted(true)
      }
    })
  }
  const fetchLiveSessionPathAndMatch = async () => {
    const {
      data: {
        setting: { value },
      },
    } = await client.query<{ setting: Setting }>({
      query: GET_LIVE_SESSION_PATH_QUERY,
    })

    const pathRegExps = value
      .split(/\s+/g)
      .map((item) => (item != '' ? new RegExp(item) : null))
    setLiveSessionPathRegExps(pathRegExps)
    startLiveSessionIfPathMatches(pathRegExps)
  }

  useEffect(() => {
    if (started || !process.env.LOG_ROCKET_API_KEY) return
    if (
      liveSessionPathRegExps.length === 0 ||
      lastCheckTime + SETTINGS_TIMEOUT_CHECK < Date.now()
    ) {
      setLastCheckTime(Date.now())
      fetchLiveSessionPathAndMatch()
    } else {
      startLiveSessionIfPathMatches(liveSessionPathRegExps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
}
