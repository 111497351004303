import _ from 'lodash'
import { FieldError } from 'react-hook-form'

import { useFormContext } from '@redwoodjs/forms'

export default function useGetError<FormData>(name: string): FieldError {
  const {
    formState: { errors },
  } = useFormContext<FormData>()

  return _.get(errors, name)
}
