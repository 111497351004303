import type { SVGProps } from 'react'
const SocialFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="white"
    />
    <path
      d="M13.1547 20V11.9991H15.2256L15.5 9.2419H13.1547L13.1582 7.86191C13.1582 7.1428 13.2223 6.75748 14.1907 6.75748H15.4853V4H13.4142C10.9264 4 10.0507 5.33752 10.0507 7.58682V9.24221H8.5V11.9994H10.0507V20H13.1547Z"
      fill="#3B5998"
    />
  </svg>
)
export default SocialFacebook
