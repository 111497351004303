import { useEffect } from 'react'

import { useSetRecoilState } from 'recoil'

import { navigate, routes } from '@redwoodjs/router'

import { backUrlState } from 'src/atoms/navigation.atom'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'
import { useRedirectAfterLogin } from 'src/lib/hooks/auth/useRedirectAfterLogin'

const AfterLogin = ({ children }: { children?: React.ReactNode }) => {
  const loggedOutRoutes: string[] = [routes.home()]
  const redirectAfterLogin = useRedirectAfterLogin()
  const { isAuthenticated } = useCurrentUser()
  const setBackUrl = useSetRecoilState(backUrlState)

  useEffect(() => {
    if (!redirectAfterLogin) return
    const redirectPath = redirectAfterLogin.split('?')[0]
    if (isAuthenticated && !loggedOutRoutes.includes(redirectPath)) {
      setBackUrl('/')
      navigate(redirectAfterLogin)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return <>{children}</>
}

export default AfterLogin
