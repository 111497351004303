import {
  Accordion as ChakraAccordion,
  AccordionButtonProps,
  AccordionPanelProps,
  AccordionProps as ChakraAccordionProps,
  Divider,
} from '@chakra-ui/react'

import AccordionItem from './AccordionItem/AccordionItem'

import { AccordionItemProps } from '.'

export type AccordionProps = {
  content: AccordionItemProps[]
  accordionProps?: ChakraAccordionProps
  headerProps?: AccordionButtonProps
  bodyProps?: AccordionPanelProps
  withChevron?: boolean
}

const Accordion = ({
  content,
  accordionProps,
  headerProps,
  bodyProps,
  withChevron,
}: AccordionProps) => {
  return (
    <ChakraAccordion w="100%" allowToggle={true} {...accordionProps}>
      <Divider color="sandstone"></Divider>
      {content.map((item, index) => (
        <AccordionItem
          key={`accordion-item-${index}`}
          parentKey={`accordion-item-${index}`}
          title={item.title}
          headerProps={headerProps}
          bodyProps={bodyProps}
          hideBottomDivider={item.hideBottomDivider}
          withChevron={!!withChevron}
        >
          {item.children}
        </AccordionItem>
      ))}
    </ChakraAccordion>
  )
}

export default Accordion
