import type { SVGProps } from 'react'
const SocialTiktok = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="white" />
    <path
      d="M15.0484 4H12.3519V14.8985C12.3519 16.1971 11.3148 17.2638 10.0242 17.2638C8.73356 17.2638 7.69646 16.1971 7.69646 14.8985C7.69646 13.6232 8.71051 12.5797 9.95505 12.5333V9.79711C7.21248 9.84347 5 12.0928 5 14.8985C5 17.7276 7.25857 20 10.0472 20C12.8358 20 15.0944 17.7044 15.0944 14.8985V9.31013C16.1085 10.0522 17.353 10.4928 18.6667 10.516V7.77971C16.6386 7.71015 15.0484 6.04058 15.0484 4Z"
      fill="white"
    />
    <path
      d="M15.4508 9.77556C16.4797 10.5137 17.7402 10.948 19.1015 10.948V8.31899C18.8439 8.31905 18.5869 8.29209 18.3348 8.23849V10.3079C16.9736 10.3079 15.7133 9.87358 14.6841 9.13552V14.5005C14.6841 17.1843 12.5162 19.3599 9.84206 19.3599C8.84428 19.3599 7.91688 19.0571 7.14648 18.538C8.02576 19.4402 9.25197 19.9999 10.6086 19.9999C13.2829 19.9999 15.4509 17.8244 15.4509 15.1404V9.77556H15.4508V9.77556ZM16.3966 7.12325C15.8708 6.54674 15.5255 5.8017 15.4508 4.97804V4.63989H14.7243C14.9072 5.68677 15.5309 6.58117 16.3966 7.12325ZM8.83788 16.4785C8.54409 16.0919 8.38533 15.619 8.38604 15.1327C8.38604 13.9053 9.37765 12.91 10.601 12.91C10.829 12.9099 11.0556 12.945 11.2729 13.0142V10.3264C11.019 10.2915 10.7627 10.2767 10.5066 10.2821V12.3741C10.2891 12.3049 10.0624 12.2698 9.83435 12.27C8.61098 12.27 7.61943 13.2652 7.61943 14.4928C7.61943 15.3608 8.11506 16.1124 8.83788 16.4785Z"
      fill="#FF004F"
    />
    <path
      d="M14.6843 9.13547C15.7135 9.87353 16.9738 10.3078 18.335 10.3078V8.23844C17.5752 8.07601 16.9025 7.67752 16.3968 7.12325C15.531 6.58111 14.9073 5.68672 14.7244 4.63989H12.8161V15.1403C12.8117 16.3645 11.8218 17.3557 10.6011 17.3557C9.88171 17.3557 9.24263 17.0115 8.83788 16.4785C8.11513 16.1124 7.61949 15.3608 7.61949 14.4929C7.61949 13.2653 8.61104 12.2701 9.83441 12.2701C10.0688 12.2701 10.2947 12.3067 10.5066 12.3742V10.2822C7.87947 10.3366 5.7666 12.4909 5.7666 15.1404C5.7666 16.463 6.29276 17.662 7.14671 18.538C7.9171 19.0571 8.8445 19.3599 9.84228 19.3599C12.5165 19.3599 14.6844 17.1843 14.6844 14.5005V9.13547H14.6843Z"
      fill="black"
    />
    <path
      d="M18.335 8.23853V7.67899C17.6498 7.68003 16.9781 7.48746 16.3968 7.12329C16.9114 7.68871 17.589 8.07858 18.335 8.23853ZM14.7245 4.63999C14.707 4.53994 14.6936 4.43923 14.6843 4.33814V4H12.0493V14.5005C12.0451 15.7245 11.0553 16.7157 9.83442 16.7157C9.476 16.7157 9.13759 16.6303 8.8379 16.4786C9.24264 17.0116 9.88173 17.3557 10.6011 17.3557C11.8217 17.3557 12.8118 16.3646 12.8161 15.1405V4.63999H14.7245ZM10.5068 10.2823V9.68659C10.2866 9.65639 10.0646 9.64124 9.84235 9.64135C7.16794 9.64129 5 11.8169 5 14.5005C5 16.183 5.85204 17.6658 7.14678 18.538C6.29283 17.662 5.76667 16.463 5.76667 15.1404C5.76667 12.491 7.87949 10.3367 10.5068 10.2823Z"
      fill="#00F2EA"
    />
  </svg>
)
export default SocialTiktok
