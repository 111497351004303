import { ChangeEvent, useEffect, useState } from 'react'

import { useDimensions } from '@chakra-ui/react'
import _ from 'lodash'

import { RegisterOptions, useFormContext } from '@redwoodjs/forms'

import useField from 'src/lib/hooks/form/useField'

export type UseTextAreaProps = {
  autoExpand?: boolean
  validation?: RegisterOptions
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  name: string
  defaultValue?: string
  title?: string
  placeholder?: string
}

export default function useTextArea({
  autoExpand = false,
  validation,
  onChange = (_e: ChangeEvent<HTMLTextAreaElement>) => {},
  name,
  defaultValue,
  title: defaultTitle,
  placeholder,
}: UseTextAreaProps) {
  if (defaultValue) {
    console.warn(
      `TextArea ${name} is using defaultValue - recommended behaviour is to pass defaultValues to the appropriate Form.`
    )
  }
  const {
    formState: { defaultValues },
  } = useFormContext()

  const [showCounter, setShowCounter] = useState(false)

  const field = useField<string, HTMLTextAreaElement>(name, {
    validation,
  })
  const dimensions = useDimensions(field.ref, true)

  const [count, setCount] = useState(0)

  useEffect(() => {
    setCount((field?.value as string)?.length || 0)
    computeHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoExpand, field?.value, dimensions])

  const changeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (ev) => {
    onChange?.(ev)
    field.onChange(ev.target.value)
  }

  const computeHeight = () => {
    if (autoExpand && field?.value && field.ref?.current) {
      field.ref.current.style.height = '0px'
      field.ref.current.style.height = `${field.ref.current.scrollHeight + 5}px`
    }
  }

  const hasErrors = !!field.error

  const title = defaultTitle || placeholder

  return {
    defaultValues,
    computeHeight,
    name,
    hasErrors,
    changeHandler,
    field,
    setShowCounter,
    title,
    showCounter,
    count,
  }
}
