import { KeyboardEventHandler } from 'react'

import { Box } from '@chakra-ui/react'

type InterceptKeyProps = {
  keyName: string
  onKeyPressed?: () => void
  children: React.ReactNode | React.ReactNode[]
  shouldPreventDefault?: boolean
  shouldStopPropagation?: boolean
}

const InterceptKey = ({
  keyName,
  onKeyPressed,
  children,
  shouldPreventDefault = true,
  shouldStopPropagation = true,
}: InterceptKeyProps) => {
  const onOkIfKeyPressed: KeyboardEventHandler<HTMLDivElement> = (ev) => {
    if (ev.key === keyName) {
      shouldPreventDefault && ev.preventDefault()
      shouldStopPropagation && ev.stopPropagation()
      onKeyPressed?.()
    }
  }
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  return (
    <Box w="100%" onKeyDown={onOkIfKeyPressed}>
      {children}
    </Box>
  )
}

export default InterceptKey
