import { faTextSize, faWaveform } from '@fortawesome/pro-regular-svg-icons'
import { ItListItem } from 'types/graphql'

import documentPlaceholder from 'src/assets/image/placeholder-pdf.jpg'
import Icon from 'src/components/widgets/Icon/Icon'
import ItListImage from 'src/components/widgets/ItListImage/ItListImage'

const ItemIcon: React.FC<{ item: ItListItem; flex: string }> = ({
  item,
  ...props
}) => {
  if (item.type === 'voiceTip') return <Icon icon={faWaveform} {...props} />
  if (item.type === 'text') return <Icon icon={faTextSize} {...props} />
  const imageUrl =
    item.content?.coverUrl ||
    item.document?.coverUrl ||
    item.product?.coverUrl ||
    item.image?.coverUrl ||
    documentPlaceholder
  return (
    <ItListImage
      htmlHeight={36}
      htmlWidth={36}
      width="36px"
      height="36px"
      src={imageUrl}
      alt="item illustration"
      borderRadius={8}
      my={2}
      ml={2}
      {...props}
    />
  )
}

export default ItemIcon
