import { Box } from '@chakra-ui/react'
import { faRotateLeft, faRotateRight } from '@fortawesome/pro-regular-svg-icons'

import Icon from '../../Icon/Icon'

const MoveNSeconds =
  (isBackwards: boolean) =>
  ({
    onClick,
    sec,
  }: {
    onClick: React.MouseEventHandler<HTMLDivElement>
    sec: number
  }) => {
    return (
      <Box
        position="relative"
        color="onyx"
        w="19px"
        h="20px"
        m="0 15px"
        p="0 20px"
        onClick={onClick}
        _active={{ transform: 'scale(0.8)' }}
      >
        <Box
          position="absolute"
          fontSize="5px"
          fontWeight="bold"
          lineHeight="9px"
          top="7px"
          left={isBackwards ? '15px' : '16px'}
        >
          {sec}
        </Box>
        <Icon
          icon={isBackwards ? faRotateLeft : faRotateRight}
          position="absolute"
          fontSize="18px"
          top="-4px"
          left="9px"
        />
      </Box>
    )
  }

export default MoveNSeconds
