import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { faGear } from '@fortawesome/pro-light-svg-icons'

import { Link, routes } from '@redwoodjs/router'

import FeedbackButton from 'src/components/widgets/buttons/FeedbackButton/FeedbackButton'
import LogoutButton from 'src/components/widgets/buttons/LogoutButton/LogoutButton'
import Icon from 'src/components/widgets/Icon/Icon'
import ItListImage from 'src/components/widgets/ItListImage/ItListImage'
import { HELP_CENTER_URL } from 'src/lib/constants'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

const SettingsMenu = () => {
  const { username, profileImage, isCreator, isAffiliate } = useCurrentUser()
  return (
    <Menu variant="header">
      <MenuButton
        borderRadius="full"
        p={1}
        borderWidth={1}
        borderColor="sandstonePlus"
        data-testid="desktopHeader-menuButton"
      >
        {profileImage ? (
          <ItListImage
            htmlHeight={26}
            htmlWidth={26}
            alt="profile image"
            src={profileImage}
            borderRadius="full"
            boxSize="26px"
          />
        ) : (
          <IconButton
            as="div"
            p={1}
            variant="iconButtonOutline"
            aria-label="Settings button"
            fontSize={16}
            type="button"
            icon={
              <Icon
                display="flex"
                w="18px"
                h="18px"
                justifyContent="center"
                alignItems="center"
                icon={faGear}
              />
            }
          />
        )}
      </MenuButton>
      <MenuList zIndex="docked">
        {(isCreator || isAffiliate) && (
          <>
            <Link
              data-coachmark="created-by-me-4"
              to={
                username && isCreator
                  ? routes.profile({ username })
                  : routes.profileNonCreatorTakeOver()
              }
            >
              <MenuItem data-testid="publicProfile">Public Profile</MenuItem>
            </Link>
          </>
        )}
        <Link data-coachmark="created-by-me-5" to={routes.settings()}>
          <MenuItem>Settings</MenuItem>
        </Link>
        <a href={HELP_CENTER_URL} target="_blank" rel="noreferrer">
          <MenuItem>Help Center</MenuItem>
        </a>
        <FeedbackButton my={4} w="100%" />
        <LogoutButton ml={6} my={3} display="block" />
      </MenuList>
    </Menu>
  )
}

export default SettingsMenu
