import { ChakraProvider } from '@chakra-ui/react'
import { Settings } from 'luxon'
import { RecoilRoot } from 'recoil'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage/FatalErrorPage'
import Routes from 'src/Routes'

import './index.scss'
import { AuthProvider, useAuth } from './auth'
import { allowAudioInIosSilentMode } from './lib/iosPlaybackFix'
import { itListTheme } from './theme'

Settings.defaultLocale = 'en'
allowAudioInIosSilentMode()

const App = () => {
  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RecoilRoot>
            <ChakraProvider theme={itListTheme}>
              <RedwoodApolloProvider useAuth={useAuth}>
                <Routes />
              </RedwoodApolloProvider>
            </ChakraProvider>
          </RecoilRoot>
        </AuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  )
}

export default App
