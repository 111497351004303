import { Howl } from 'howler'

import { getImageKitUrl } from './imageKit'

type PlayerOptions = {
  onStop?: () => unknown
  onPause?: () => unknown
  onPlay?: () => unknown
}

let player: Howl | null = null
export let currentPlayerId: string | null = null
let playerOptions: PlayerOptions | null = null
export function setPlayerOptions(options: PlayerOptions | null) {
  playerOptions = options
}

export function play(
  playerId: string,
  mediaUrl: string,
  title: string,
  pictureUrl?: string,
  options: PlayerOptions = {}
): Promise<{ duration: number }> {
  return new Promise((resolve) => {
    if (player && currentPlayerId === playerId) {
      player.play()
      initMediaSession(title, pictureUrl)
      resolve({ duration: player.duration() })
    } else {
      unload()
      currentPlayerId = playerId
      player = new Howl({
        src: [mediaUrl],
        format: ['mp3'],
        html5: true,
        onend: () => playerOptions?.onStop?.(),
        onstop: () => {
          playerOptions?.onStop?.()
        },
        onplay: () => playerOptions?.onPlay?.(),
        onpause: () => playerOptions?.onPause?.(),
        onload: () => {
          setPlayerOptions(options)
          player.play()
          initMediaSession(title, pictureUrl)
          resolve({ duration: player.duration() })
        },
      })
    }
  })
}

export function pause() {
  player?.pause()
}

export function duration() {
  return player?.duration()
}

export function seek(position?: number): number {
  return +player?.seek(position) || 0
}

export function isPlaying() {
  return player?.playing()
}

export function reset() {
  unload()
  currentPlayerId = null
  setPlayerOptions(null)
}

export function unload() {
  if (player) {
    player.stop()
    player.unload()
  }
}

const SIZES = [96, 128, 192, 256, 384, 512]

function initMediaSession(title: string, pictureUrl?: string) {
  if ('mediaSession' in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: `ItList - ${title}`,
      artwork:
        pictureUrl &&
        SIZES.map((size) => ({
          src: buildPictureUrl(pictureUrl, size),
          sizes: `${size}x${size}`,
          type: 'image/jpg',
        })),
    })
    const actionHandlers: [MediaSessionAction, () => void][] = [
      [
        'play',
        () => {
          player?.play()
        },
      ],
      [
        'pause',
        () => {
          player?.pause()
        },
      ],
    ]

    for (const [action, handler] of actionHandlers) {
      try {
        navigator.mediaSession.setActionHandler(action, handler)
      } catch (error) {
        console.log(
          `The media session action "${action}" is not supported yet.`
        )
      }
    }
  }
}

function buildPictureUrl(pictureUrl: string, size: number) {
  const baseUrl = getImageKitUrl(pictureUrl)
  return `${baseUrl}${
    baseUrl.includes('?') ? '&' : '?'
  }tr=w-${size},h-${size},fo-auto`
}
