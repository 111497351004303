import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Textarea: ComponentStyleConfig = {
  variants: {
    outline: {
      _placeholder: {
        color: 'graphite',
      },
      borderRadius: '8px',
      border: '1px solid',
      padding: '16px',
      py: '24px',
      borderColor: 'sandstonePlus',
      boxShadow: 'none',
      _focus: {
        bg: 'white',
        borderColor: 'onyx',
        boxShadow: 'none',
      },
      _focusVisible: {
        bg: 'white',
        borderColor: 'onyx',
        boxShadow: 'none',
      },
      _invalid: {
        borderColor: 'danger',
        boxShadow: 'none',
      },
      _hover: {
        borderColor: 'onyx',
      },
    },
  },
}
