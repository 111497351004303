import EditChannelsModal from 'src/components/widgets/EditChannelsModal/EditChannelsModal'
import EditLinksModal from 'src/components/widgets/EditLinksModal/EditLinksModal'
import LightboxImageModal from 'src/components/widgets/LightboxModal/LightboxImageModal'
import LightboxVideoModal from 'src/components/widgets/LightboxModal/LightboxVideoModal'

const ModalForms = () => {
  return (
    <>
      <EditChannelsModal />
      <EditLinksModal />
      <LightboxImageModal />
      <LightboxVideoModal />
    </>
  )
}

export default ModalForms
