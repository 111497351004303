import { Accordion, AccordionItem, VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'

import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'
import { chakraColorToCSSVariable } from 'src/lib/utils'
import { BrandColor } from 'src/types'

import EditItem from './EditItem/EditItem'
import useCollapsible from './useCollapsible'
import useFieldMethods from './useFieldMethods'

export type ActionMenuProps = {
  onClickRemove: (ev?: React.MouseEvent) => void
  index: number
}

export type EditableListElementProps = {
  name: `items.${number}`
  index: number
}

export type BuilderProps = EditableListElementProps & { onClose?: () => void }

export type EditableListProps<FormData> = {
  isEmpty: (item: FormData) => boolean
  brandColor?: BrandColor
  onDelete?: (id: number) => void
  AddItemButton?: React.FC<{
    onAdd: (item: FormData) => void
  }>
  OverflowMenu?: React.FC<ActionMenuProps>
  Builder: React.FC<BuilderProps>
  Element: React.FC<EditableListElementProps>
  expandedHeaderLabel(index: number): string
  py?: number
  isDraggable?: boolean
  onDragEnd?: (source: number, destination: number) => unknown
}

const getBorderColorFromExpandedState = (
  expanded: boolean,
  brandColor: BrandColor
) => chakraColorToCSSVariable(expanded ? brandColor : 'sandstonePlus')

const getBoxShadowFromExpandedState = (expanded: boolean) => {
  return expanded
    ? '0px 8px 16px rgba(22, 34, 51, 0.11)'
    : '0px 0px 0px rgba(22, 34, 51, 0)'
}

export default function EditableList<FormData>({
  isEmpty,
  expandedHeaderLabel,
  AddItemButton,
  Builder,
  Element,
  OverflowMenu,
  onDelete,
  py,
  onDragEnd: dragEndCallback,
  isDraggable = true,
  brandColor,
}: EditableListProps<FormData>) {
  const { brandColor: currentUserBrandColor } = useCurrentUser()
  brandColor = brandColor || currentUserBrandColor || 'brand.200'

  const { fields, itemHasError, removeItem, addItem, onDragEnd } =
    useFieldMethods<FormData>({ onDelete, isEmpty, dragEndCallback })

  const { close, isOpened, open, openedAt, toggle } = useCollapsible()

  const getProps = (draggableProvided: DraggableProvided) =>
    isDraggable
      ? {
          ...draggableProvided.draggableProps,
          ...draggableProvided.dragHandleProps,
        }
      : null

  return (
    <VStack spacing={4} align="stretch" w="100%">
      {!!AddItemButton && (
        <AddItemButton
          onAdd={(item) => {
            close()
            addItem(item)
            open(0)
          }}
        />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableArea">
          {(droppableProvided: DroppableProvided) => (
            <Accordion
              allowToggle
              index={openedAt}
              onChange={toggle}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {fields.map((item, order) => (
                <Draggable
                  isDragDisabled={!isDraggable}
                  draggableId={item.fieldArrayId}
                  key={item.fieldArrayId}
                  index={order}
                >
                  {(draggableProvided: DraggableProvided) => (
                    <AccordionItem
                      as={motion.div}
                      key={item.fieldArrayId}
                      mb={fields.length > 1 ? 4 : 0}
                      bg="white"
                      ref={draggableProvided.innerRef}
                      {...getProps(draggableProvided)}
                      borderColor={
                        itemHasError(order) && !isOpened(order)
                          ? 'danger'
                          : getBorderColorFromExpandedState(
                              isOpened(order),
                              brandColor
                            )
                      }
                      animate={{
                        boxShadow: getBoxShadowFromExpandedState(
                          isOpened(order)
                        ),
                      }}
                      data-testid={`editableList-item-${order}`}
                    >
                      <EditItem
                        expanded={isOpened(order)}
                        expandedHeaderLabel={expandedHeaderLabel}
                        isEmpty={isEmpty}
                        index={order}
                        Builder={Builder}
                        Element={Element}
                        OverflowMenu={OverflowMenu}
                        onRemove={(index) => removeItem(index, close)}
                        onClose={close}
                        py={py}
                        isDraggable={isDraggable}
                      />
                    </AccordionItem>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </Accordion>
          )}
        </Droppable>
      </DragDropContext>
    </VStack>
  )
}
