import type { SVGProps } from 'react'
const SocialDiscord = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="white"
    />
    <path
      d="M17.1981 7.41184C16.2421 6.98979 15.2169 6.67884 14.1449 6.50075C14.1254 6.49731 14.1059 6.5059 14.0959 6.52308C13.964 6.7487 13.818 7.04304 13.7157 7.27439C12.5628 7.10833 11.4157 7.10833 10.2865 7.27439C10.1842 7.0379 10.0328 6.7487 9.90036 6.52308C9.8903 6.50647 9.8708 6.49788 9.85128 6.50075C8.77994 6.67827 7.75473 6.98922 6.79808 7.41184C6.7898 7.41528 6.7827 7.42101 6.77799 7.42845C4.83337 10.2236 4.30066 12.95 4.56199 15.6426C4.56317 15.6558 4.57086 15.6684 4.5815 15.6764C5.86451 16.5829 7.10732 17.1332 8.32705 17.498C8.34657 17.5037 8.36725 17.4969 8.37968 17.4814C8.6682 17.1023 8.9254 16.7026 9.14592 16.2822C9.15894 16.2576 9.14651 16.2284 9.11992 16.2187C8.71196 16.0698 8.3235 15.8883 7.94983 15.6821C7.92027 15.6655 7.91791 15.6248 7.9451 15.6054C8.02373 15.5487 8.10239 15.4897 8.17747 15.4301C8.19106 15.4193 8.20999 15.417 8.22596 15.4238C10.6808 16.5022 13.3385 16.5022 15.7643 15.4238C15.7803 15.4164 15.7992 15.4187 15.8134 15.4296C15.8885 15.4891 15.9672 15.5487 16.0464 15.6054C16.0736 15.6248 16.0718 15.6655 16.0422 15.6821C15.6686 15.8923 15.2801 16.0698 14.8716 16.2181C14.845 16.2279 14.8331 16.2576 14.8462 16.2822C15.0714 16.702 15.3286 17.1017 15.6118 17.4808C15.6236 17.4969 15.6449 17.5037 15.6644 17.498C16.8901 17.1332 18.1329 16.5829 19.4159 15.6764C19.4271 15.6684 19.4342 15.6563 19.4354 15.6432C19.7482 12.5302 18.9116 9.82615 17.2176 7.42902C17.2135 7.42101 17.2064 7.41528 17.1981 7.41184ZM9.51251 14.0031C8.77344 14.0031 8.16446 13.3503 8.16446 12.5485C8.16446 11.7468 8.76163 11.094 9.51251 11.094C10.2693 11.094 10.8724 11.7526 10.8605 12.5485C10.8605 13.3503 10.2634 14.0031 9.51251 14.0031ZM14.4967 14.0031C13.7577 14.0031 13.1487 13.3503 13.1487 12.5485C13.1487 11.7468 13.7458 11.094 14.4967 11.094C15.2535 11.094 15.8566 11.7526 15.8448 12.5485C15.8448 13.3503 15.2535 14.0031 14.4967 14.0031Z"
      fill="#5865F2"
    />
  </svg>
)
export default SocialDiscord
