import { isBrowser } from '@redwoodjs/prerender/browserUtils'

const uaMatches = (regex: RegExp) =>
  isBrowser ? !!window?.navigator?.userAgent?.match(regex) : false
export const iOS = !!uaMatches(/iPad/i) || !!uaMatches(/iPhone/i)
export const webkit = !!uaMatches(/WebKit/i)
export const iOSSafari =
  iOS && webkit && !uaMatches(/CriOS/i) && !uaMatches(/FxiOS/i)
export const safari =
  !uaMatches(/CriOS/i) &&
  !uaMatches(/FxiOS/i) &&
  !uaMatches(/Chrome/i) &&
  uaMatches(/Safari/i)
