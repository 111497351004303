import type { GetUserChannelsQueryToEdit } from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import CellFailure from '../../CellFailure/CellFailure'
import ChannelsEditableList from '../../EditableList/instances/ChannelsEditableList/ChannelsEditableList'
import Loader from '../../Loader/Loader'

import CellFrame from './CellFrame/CellFrame'

type EditChannelsModalCell = {
  onClose: () => void
}

export const QUERY = gql`
  query GetUserChannelsQueryToEdit($creatorId: Int!) {
    channels(creatorId: $creatorId) {
      id
      name
      order
      itLists {
        id
        name
        coverUrl
      }
    }
  }
`

export const Loading = () => <Loader p={5} />

export const Empty = ({ onClose }: EditChannelsModalCell) => (
  <CellFrame onClose={onClose}>
    <ChannelsEditableList />
  </CellFrame>
)

export const Failure = () => <CellFailure />

export const Success = ({
  channels,
  onClose,
}: CellSuccessProps<GetUserChannelsQueryToEdit> & EditChannelsModalCell) => (
  <CellFrame initialItems={channels} onClose={onClose}>
    <ChannelsEditableList />
  </CellFrame>
)

// cache-and-network fetch policy does not work here. It will set bad default values (from the cache)
// when you come back to the modal
export const beforeQuery = (props: { creatorId: number }) => {
  return {
    variables: props,
    fetchPolicy: 'network-only',
  }
}
