import { useEffect, useRef } from 'react'

import { Box, Text } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'

import { Result } from '../HeaderSearch'

import ResultItem from './ResulttItem/ResultItem'

const SearchResuts: React.FC<{
  currentQuery: string
  isFocus: boolean
  results: Result[]
  currentIndex: number
  pickResult(itemId: number): unknown
}> = ({ currentQuery, isFocus, results, currentIndex, pickResult }) => {
  const ref = useRef<HTMLDivElement>()
  useEffect(() => {
    if (results?.length && ref.current) {
      // When using the up and down arrow to select a result
      // we want ot make sure that item is in the viewport
      // so we scroll to it if need be
      const element = ref.current.querySelector(
        `[data-item-id='${results[currentIndex]?.item.id}']`
      )
      if (element) {
        const offset = 80
        const wrapperTop = ref.current.getBoundingClientRect().top
        const elementTop = element.getBoundingClientRect().top
        const elementPosition = elementTop - wrapperTop
        const offsetPosition = elementPosition - offset
        ref.current.scrollBy({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])
  return (
    <Box
      position="absolute"
      zIndex="dropDown"
      top={0}
      left={0}
      right={0}
      minH="48px"
      boxShadow={isFocus ? 'md' : 'none'}
      borderRadius={24}
      pointerEvents="none"
      overflow="hidden"
    >
      <AnimatePresence>
        {currentQuery?.length >= 2 && (
          <Box
            as={motion.div}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            mt="48px"
            pointerEvents="auto"
            bg="white"
            borderRadius="0 0 24px 24px"
            maxH={315}
            overflowY="scroll"
            ref={ref}
          >
            <Box pt={4} px={4} pb={8}>
              <Text className="body-small-emphasis" mb={1}>
                Inside this ItList
              </Text>
              {results?.length ? (
                results.map((result, index) => (
                  <ResultItem
                    key={result.item.id}
                    result={result}
                    isSelected={index === currentIndex}
                    pickResult={pickResult}
                  />
                ))
              ) : (
                <Box
                  border="1px solid"
                  borderColor="sandstone"
                  borderRadius={12}
                  py={4}
                  mt={2}
                  textAlign="center"
                  color="graphite"
                  className="body-small"
                >
                  No matching items
                </Box>
              )}
            </Box>
          </Box>
        )}
      </AnimatePresence>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        height={8}
        bg="linear-gradient(transparent 0%, white 100%)"
      />
    </Box>
  )
}

export default SearchResuts
