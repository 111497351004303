import { Box, Flex, Spinner } from '@chakra-ui/react'
import { faWaveformLines } from '@fortawesome/pro-regular-svg-icons'
import { faPlay, faPause } from '@fortawesome/pro-solid-svg-icons'

import { VoiceTipAnalyticsProps } from 'src/lib/hooks/analytics/useAnalytics'
import { formatTime } from 'src/lib/utils'
import { BrandColor } from 'src/types'

import BrandButton from '../buttons/BrandButton/BrandButton'
import Icon from '../Icon/Icon'

import BackwardsNSeconds from './MoveNSeconds/BackwardsNSeconds/BackwardsNSeconds'
import ForwardsNSeconds from './MoveNSeconds/ForwardsNSeconds/ForwardsNSeconds'
import TimerImage from './TimerImage/TimerImage'
import useAudioPlayerInline from './useAudioPlayerInline'
import WithDuration from './WithDuration/WithDuration'

const AudioPlayerInline = ({
  mediaUrl,
  pictureUrl,
  brandColor = 'brand.200' as BrandColor,
  veneerLabel,
  onPlay,
  onStop,
  showDuration = false,
  showProfileImage = true,
  trackingData,
  title,
}: {
  mediaUrl: string
  pictureUrl?: string
  brandColor?: BrandColor
  veneerLabel?: string
  onPlay?: () => void
  onStop?: () => void
  showDuration?: boolean
  trackingData?: VoiceTipAnalyticsProps
  title: string
  showProfileImage?: boolean
}) => {
  const {
    skipForwards,
    skipBackwards,
    onClickPlay,
    onClickPause,
    showVeneer,
    isPlaying,
    isLoading,
    duration,
    time,
  } = useAudioPlayerInline(
    mediaUrl,
    title,
    pictureUrl,
    onPlay,
    onStop,
    veneerLabel,
    trackingData
  )

  return (
    <WithDuration showDuration={showDuration} duration={!isPlaying && duration}>
      <Flex
        position="relative"
        borderRadius="full"
        boxShadow="0px 0px 15px rgba(169, 176, 191, 0.44)"
        background="white"
        color={brandColor}
        height="36px"
        w={isPlaying ? '227px' : showProfileImage ? '88px' : '65px'}
        alignItems="center"
        p={1}
        transition="width 0.15s ease-out"
        overflow="hidden"
        cursor="pointer"
        onClick={onClickPlay}
        userSelect="none"
        data-testid="AudioPlayerInline-audioContainer"
        // lineHeight={1.3}
      >
        {showVeneer && (
          <Box
            position="absolute"
            pointerEvents="none"
            top={0}
            left={0}
            right={0}
            bottom={0}
          >
            <BrandButton
              isDisabled={false}
              brandColor={brandColor}
              pointerEvents="none"
              bg="white"
              leftIcon={!isLoading && <Icon icon={faPlay} size="sm" />}
              isLoading={isLoading}
              loadingText={veneerLabel}
              zIndex="docked"
              w="100%"
              paddingY={2}
              data-testid="intro-veneer"
            >
              {veneerLabel}
            </BrandButton>
          </Box>
        )}
        {(showProfileImage || isPlaying) && (
          <TimerImage
            showProfileImage={showProfileImage}
            src={pictureUrl}
            duration={duration || 0}
            position={time}
            brandColor={brandColor}
          />
        )}
        {isPlaying ? (
          <>
            <div data-testid="AudioPlayerInline-Backward">
              <BackwardsNSeconds onClick={skipBackwards} sec={10} />
            </div>
            <Icon
              icon={faPause}
              fontSize={'15px'}
              _active={{ transform: 'scale(0.8)' }}
              onClick={onClickPause}
              data-testid="AudioPlayerInline-Pause"
            />
            <div data-testid="AudioPlayerInline-Forward">
              <ForwardsNSeconds onClick={skipForwards} sec={10} />
            </div>
            <Box
              fontSize="14px"
              color="onyx"
              data-testid="AudioPlayerInline-Time"
            >
              {formatTime(time)}
            </Box>
          </>
        ) : (
          <>
            {isLoading ? (
              <Box color="stone" fontSize={'15px'} px={2}>
                <Spinner color={brandColor} size="sm" />
              </Box>
            ) : (
              <Icon
                icon={faPlay}
                fontSize={'15px'}
                _active={{ transform: 'scale(0.8)' }}
                px={2}
                data-testid="AudioPlayerInline-Play"
              />
            )}
            <Icon icon={faWaveformLines} fontSize={'15px'} />
          </>
        )}
      </Flex>
    </WithDuration>
  )
}

export default AudioPlayerInline
