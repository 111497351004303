import { SystemProps } from '@chakra-ui/react'

import Error from '../Error/Error'

type CellFailureProps = {
  title?: string
  subtitle?: string
} & SystemProps

const CellFailure = ({ title, subtitle, ...props }: CellFailureProps) => {
  return <Error title={title} subtitle={subtitle} {...props} />
}

export default CellFailure
