import { useRecoilValue } from 'recoil'

import { routes } from '@redwoodjs/router'

import { backUrlState } from 'src/atoms/navigation.atom'

import { useCurrentUser } from './useCurrentUser'

export const useRedirectAfterLogin = (): string => {
  const { isAuthenticated, creatorStatus } = useCurrentUser()
  const backUrl = useRecoilValue(backUrlState)
  if (isAuthenticated) {
    if (creatorStatus === 'onboarding') return routes.onboarding()
    return backUrl
  } else {
    return undefined
  }
}
