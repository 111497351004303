import { Link as Anchor } from '@chakra-ui/react'
import { IconDefinition } from '@fortawesome/free-brands-svg-icons'

import Icon from 'src/components/widgets/Icon/Icon'

const SocialLink = ({ icon, url }: { icon: IconDefinition; url: string }) => (
  <Anchor href={url} target="_blank" rel="noreferrer" fontSize={20}>
    <Icon icon={icon} />
  </Anchor>
)

export default SocialLink
