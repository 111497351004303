import { Spacer } from '@chakra-ui/react'
import { ItList } from 'types/graphql'

import LoginButton from 'src/components/widgets/buttons/LoginButton/LoginButton'

import HeaderActions from '../../HeaderActions/HeaderActions'

const LoggedOut: React.FC<{
  headerButtonVariant?: string
  itList?: ItList
}> = ({ headerButtonVariant, itList }) => {
  return (
    <>
      <Spacer />

      <HeaderActions
        section="header"
        label="Make An ItList"
        headerButtonVariant={headerButtonVariant}
        itList={itList}
      />
      <LoginButton ml={2} />
    </>
  )
}

export default LoggedOut
