import { AnalyticsBrowser } from '@segment/analytics-next'
import _ from 'lodash'

import { isE2ETestSession } from 'src/lib/utils'

export let analytics: AnalyticsBrowser

if (process.env.SEGMENT_API_KEY && !isE2ETestSession()) {
  analytics = AnalyticsBrowser.load({
    writeKey: process.env.SEGMENT_API_KEY,
  })
} else {
  const _analytics = analytics
  const stubbedAnalytics =
    (fnName: string) =>
    async (...args: unknown[]) => {
      console.info(`segment.io ${fnName} call: `, args)
      _analytics?.[fnName](...args)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return null as any
    }
  analytics = {
    identify: stubbedAnalytics('identify'),
    reset: stubbedAnalytics('reset'),
    page: stubbedAnalytics('page'),
    track: stubbedAnalytics('track'),
  } as AnalyticsBrowser
}
