import { theme } from '@chakra-ui/pro-theme'
import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const CustomSelect: ComponentStyleConfig = {
  ...theme.components.CustomSelect,
  variants: {
    outline: {
      field: {
        border: '1px solid',
        borderColor: 'sandstonePlus',
        borderRadius: '8px',
        color: 'onyx',
        fontWeight: '400',
        px: '16px',
        paddingX: '16px',
        py: '24px',
        paddingY: '24px',
        _focus: {
          borderColor: 'onyx',
          boxShadow: '0px 0px 1px 1px rgb(0 0 0 / 70%)',
        },
        _expanded: {
          borderColor: 'onyx',
          boxShadow: '0px 0px 1px 1px rgb(0 0 0 / 70%)',
        },
      },
      option: {
        color: 'onyx',
        fontWeight: '500',
        borderRadius: '8px',
        px: '8px',
        paddingX: '8px',
        py: '24px',
        paddingY: '24px',
        marginLeft: 0,
      },
      menu: {
        background: 'white',
        border: '1px solid',
        borderColor: 'sandstone',
        borderRadius: '8px',
        px: '8px',
        paddingX: '8px',
        py: '8px',
        paddingY: '8px',
        boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)',
      },
    },
  },
}
