import {
  Flex,
  Box,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  List,
  Spacer,
} from '@chakra-ui/react'

import { Link, routes } from '@redwoodjs/router'

import FeedbackButton from 'src/components/widgets/buttons/FeedbackButton/FeedbackButton'
import LogoutButton from 'src/components/widgets/buttons/LogoutButton/LogoutButton'
import { HELP_CENTER_URL } from 'src/lib/constants'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'
import { getAbsoluteUrl } from 'src/lib/utils'

import HeaderActions from '../../HeaderActions/HeaderActions'
import DrawerMenuItemSecondary from '../DrawerMenuItemSecondary/DrawerMenuItemSecondary'

import SectionHeader from './SectionHeader/SectionHeader'

const DrawerLoggedIn: React.FC<{
  onClose(): void
  headerButtonVariant?: string
}> = ({ onClose, headerButtonVariant }) => {
  const { username, isCreator, isAffiliate } = useCurrentUser()
  return (
    <>
      <DrawerOverlay />
      <DrawerContent maxW={{ base: 'unset', md: '375px' }}>
        <DrawerHeader
          px={4}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <DrawerCloseButton
            variant="navDrawer"
            top="unset"
            right="unset"
            position="relative"
          />

          <FeedbackButton />
        </DrawerHeader>

        <DrawerBody px={4}>
          <Flex flexDir="column">
            <List variant="underlined" as="nav">
              <SectionHeader>ItLists</SectionHeader>
              {(isCreator || isAffiliate) && (
                <Link to={routes.createdByMe()} onClick={onClose}>
                  <DrawerMenuItemSecondary label="Created by Me" />
                </Link>
              )}
              {isCreator && username && (
                <a href={getAbsoluteUrl(routes.userItLists({ username }))}>
                  <DrawerMenuItemSecondary label="Share Hub" />
                </a>
              )}
              {!isCreator && isAffiliate && (
                <Link to={routes.affiliateDashboard()} onClick={onClose}>
                  <DrawerMenuItemSecondary label="Affiliate Dashboard" />
                </Link>
              )}
              <Link to={routes.ownedItLists()} onClick={onClose}>
                <DrawerMenuItemSecondary label="Saved And Purchased" />
              </Link>
              <SectionHeader>Account</SectionHeader>
              {(isCreator || isAffiliate) && (
                <>
                  <Link
                    to={
                      username && isCreator
                        ? routes.profile({ username })
                        : routes.profileNonCreatorTakeOver()
                    }
                    onClick={onClose}
                    data-testid="publicProfile"
                  >
                    <DrawerMenuItemSecondary label="Public Profile" />
                  </Link>
                </>
              )}
              {isCreator && (
                <>
                  <Link to={routes.analytics()} onClick={onClose}>
                    <DrawerMenuItemSecondary label="Analytics" />
                  </Link>
                  <Link to={routes.affiliateDashboard()} onClick={onClose}>
                    <DrawerMenuItemSecondary label="Affiliate Dashboard" />
                  </Link>
                </>
              )}
              <Link to={routes.settings()} onClick={onClose}>
                <DrawerMenuItemSecondary label="Settings" />
              </Link>
              <a
                href={HELP_CENTER_URL}
                target="_blank"
                rel="noreferrer"
                onClick={onClose}
              >
                <DrawerMenuItemSecondary label="Help Center" />
              </a>
            </List>
            <Spacer />
            <Flex
              flexDir="column"
              textAlign="center"
              bg="white"
              px={6}
              gap={6}
              my={14}
            >
              {!isCreator && (
                <>
                  <Box color="stone" textAlign="center" className="body-small">
                    Inspired to start sharing your own expertise or favorites
                    with the world?
                  </Box>
                  <HeaderActions
                    onClick={onClose}
                    label="Make Your Own ItList"
                    section="mobile-nav"
                    headerButtonVariant={headerButtonVariant}
                  />
                </>
              )}
              <LogoutButton onClick={onClose} />
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </>
  )
}

export default DrawerLoggedIn
