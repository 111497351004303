import { Flex, useBreakpoint } from '@chakra-ui/react'

import DesktopNav from './DesktopNav/DesktopNav'
import MobileNav from './MobileNav/MobileNav'

type HeaderProps = {
  children?: React.ReactNode
}

const AdminHeader = (props: HeaderProps) => {
  const size = useBreakpoint()
  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      my="4"
      {...props}
    >
      {size === 'sm' ? <MobileNav /> : <DesktopNav />}
    </Flex>
  )
}

export default AdminHeader
