import { IconButton } from '@chakra-ui/react'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { motion, AnimatePresence } from 'framer-motion'
import _ from 'lodash'

import Icon from 'src/components/widgets/Icon/Icon'

const TrashButton: React.FC<{
  onClickRemove: React.MouseEventHandler
  index?: number
}> = ({ onClickRemove }) => (
  <AnimatePresence key="trash">
    <IconButton
      as={motion.button}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      p={2}
      flex="0 0 auto"
      variant="function"
      aria-label="Remove icon button"
      data-testid="editItem-removeButton"
      icon={
        <Icon
          boxSize="20px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          icon={faTrashAlt}
        />
      }
      onClick={onClickRemove}
    />
  </AnimatePresence>
)

export default TrashButton
