import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    lineHeight: '21px',
    fontWeight: '600',
    transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)',
    outline: 'none',
    px: 6,
    paddingX: 6,
    py: 3,
    paddingY: 3,
    h: 'auto',
    height: 'auto',
    borderRadius: 'full',
    _hover: {
      boxShadow: '0 0 15px rgba(0, 0, 0, 0.15)',
    },
    _disabled: {
      pointerEvents: 'none',
      opacity: 0.7,
      boxShadow: 'none !important',
    },
  },
  sizes: {
    sm: {
      px: 4,
      paddingX: 4,
      py: 2,
      paddingY: 2,
      fontSize: '1.4rem',
    },
  },
  variants: {
    special: {
      color: 'white',
      bgGradient: 'linear(to-l, #8437E6, brand.200)',
      _hover: {
        bgGradient: 'linear(to-l, #8437E6, brand.500)',
      },
      _active: {
        bg: 'brand.500',
      },
    },
    primary: {
      color: 'white',
      bg: 'brand.200',
      borderWidth: '1.5px',
      borderColor: 'brand.200',
      _hover: {
        bg: 'brand.200',
      },
      _active: {
        bg: 'brand.300',
        borderColor: 'brand.300',
      },
    },
    secondary: {
      color: 'onyx',
      bgColor: 'white',
      borderWidth: '1.5px',
      borderColor: 'onyx',
      _hover: {
        bg: 'white',
      },
      _active: {
        bg: 'air',
      },
    },
    secondaryGrayOutline: {
      color: 'onyx',
      bgColor: 'white',
      borderWidth: '1.5px',
      borderColor: 'sandstone',
      _hover: {
        bg: 'white',
      },
      _active: {
        bg: 'air',
      },
    },
    tertiary: {
      color: 'white',
      bgColor: 'onyx',
      borderWidth: '1.5px',
      borderColor: 'onyx',
      _hover: {
        bg: 'onyx',
      },
      _active: {
        bg: '#3e4042',
      },
    },
    disabled: {
      color: 'graphite',
      bgColor: 'sandstone',
      cursor: 'default',
      _focus: {
        bgColor: 'sandstone',
      },
      _active: {
        bgColor: 'sandstone',
      },
      _hover: {
        boxShadow: 'none',
      },
    },
    text: {
      color: 'onyx',
      textDecoration: 'underline',
      textUnderlineOffset: 3,
      _hover: {
        boxShadow: 'none',
        color: 'stone',
      },
      _active: {
        bg: 'transparent',
        color: 'onyx',
      },
    },
    textDisabled: {
      color: 'graphite',
      textDecoration: 'underline',
      _hover: {
        boxShadow: 'none',
        color: 'graphite',
      },
      _active: {
        bg: 'white',
        color: 'graphite',
      },
    },
    quaternary: {
      py: '12px',
      paddingY: '12px',
      px: '14px',
      paddingX: '14px',
      background: 'white',
      borderColor: 'dust',
      borderWidth: '1px',
      borderStyle: 'solid',
      boxShadow: 'sm',
      _active: {
        boxShadow: 'none',
        bg: 'vapor',
      },
      _hover: {
        boxShadow: 'none',
        bg: 'air',
      },
    },
    ghost: {
      bg: 'transparent',
    },
    elevated: {
      paddingY: 4,
      paddingLeft: 5,
      paddingRight: 5,
      border: '1 px solid',
      borderColor: 'sandstone',
      bg: 'white',
      lineHeight: '16px',
      boxShadow: 'sm',
      _hover: {
        boxShadow: 'unset',
        bg: 'air',
      },
      _active: {
        bg: 'vapor',
      },
    },
    borderGradient: {
      bg: 'linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #F17F9D, #A356CB) border-box',
      border: '1px solid transparent',
      _hover: {
        bg: 'linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #F17F9D, #A356CB) border-box',
      },
      _active: {
        bg: 'linear-gradient(#F2F2F2, #F2F2F2) padding-box, linear-gradient(to right, #F17F9D, #F17F9D) border-box',
      },
    },
    highlight: {
      boxShadow: 'sm',
      borderRadius: 'full',
      gap: 2,
      px: 5,
      py: 4,
      _active: {
        boxShadow: 'none',
        bg: 'linear-gradient(45deg, #D38BCC 0%, #8ACBD4 50%, #77E2A5 100%) border-box',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        position: 'relative',
        _before: {
          content: '""',
          zIndex: '-1',
          inset: 0,
          position: 'absolute',
          border: '1px solid transparent',
          borderRadius: 'full',
          bg: 'linear-gradient(to right, #000, #000) border-box',
          backgroundOrigin: 'border-box',
          backgroundClip: 'border-box',
          background: 'inherit',
          WebkitMask:
            'linear-gradient(#fff 0 0) padding-box,linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
          WebkitMaskRepeat: 'no-repeat',
        },
      },
      _hover: {
        boxShadow: 'xs',
        bg: 'linear-gradient(-65deg, #D38BCC 0%, #8ACBD4 50%, #77E2A5 100%) border-box',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        position: 'relative',
        _before: {
          content: '""',
          zIndex: '-1',
          inset: 0,
          position: 'absolute',
          border: '1px solid transparent',
          borderRadius: 'full',
          bg: 'linear-gradient(to right, #000, #000) border-box',
          backgroundOrigin: 'border-box',
          backgroundClip: 'border-box',
          background: 'inherit',
          WebkitMask:
            'linear-gradient(#fff 0 0) padding-box,linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
          WebkitMaskRepeat: 'no-repeat',
        },
      },
    },
    highlightActive: {
      boxShadow: 'none',
      borderRadius: 'full',
      pointerEvents: 'none',
      gap: 2,
      px: 5,
      py: 4,
      bg: 'linear-gradient(45deg, #D38BCC 0%, #8ACBD4 50%, #77E2A5 100%) border-box',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      position: 'relative',
      _before: {
        content: '""',
        zIndex: '-1',
        inset: 0,
        position: 'absolute',
        border: '1px solid transparent',
        borderRadius: 'full',
        bg: 'linear-gradient(to right, #000, #000) border-box',
        backgroundOrigin: 'border-box',
        backgroundClip: 'border-box',
        background: 'inherit',
        WebkitMask:
          'linear-gradient(#fff 0 0) padding-box,linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
        WebkitMaskRepeat: 'no-repeat',
      },
    },
    bgGradient: {
      bg: 'linear-gradient(45deg, #D38BCC 0%, #8ACBD4 50%, #77E2A5 100%) border-box',
      color: 'white',
    },
    border: {
      bg: 'white',
      border: '2px solid',
      borderColor: 'brand.200',
      _hover: {
        bg: 'white',
      },
      _active: {
        bg: 'air',
      },
    },
    radioIconButton: {
      padding: '10px 11px',
      bg: 'vapor',
      _hover: {
        bg: 'sandstone',
        boxShadow: 'none',
      },
      _active: {
        bg: 'air',
      },
    },
    radioIconButtonSelected: {
      padding: '10px 11px',
      bg: 'white',
      boxShadow: '0 4px 28px rgba(116, 129, 153, 0.35)',
      _focus: {
        boxShadow: '0 4px 28px rgba(116, 129, 153, 0.35) !important',
      },
      _active: {
        bg: 'air',
      },
    },
    iconButtonOutline: {
      borderWidth: 1,
      borderColor: 'graphite',
      _active: {
        bg: 'air',
      },
    },
    roundWhite: {
      bg: 'white',
      fontWeight: 'bold',
      _hover: {
        boxShadow: 'none',
      },
      _active: {
        bg: 'air',
      },
    },
    roundBlack: {
      bg: 'onyx',
      textColor: 'white',
      fontWeight: 'bold',
      _active: {
        boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.55) !important',
        bg: 'onyx',
      },
    },
    whiteTransparent: {
      color: 'white',
      bg: 'transparent',
      p: '7px 12px',
      border: '1px solid white',
      _hover: { bg: 'rgba(255, 255, 255, 0.1)' },
    },
    function: {
      color: 'onyx',
      bg: 'dust',
      _hover: {
        bg: 'sandstone',
        boxShadow: 'none',
      },
      _active: {
        bg: 'sandstone',
      },
    },
  },
}
