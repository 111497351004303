import { useState } from 'react'

import { chakra, Image, ImageProps } from '@chakra-ui/react'
import { IKImage } from 'imagekitio-react'

import { getImageKitUrl } from 'src/lib/imageKit'

type WidthOrHeight =
  | {
      htmlWidth: number
      htmlHeight?: number
    }
  | {
      htmlWidth?: number
      htmlHeight: number
    }

type CustomImageProps = WidthOrHeight & { alt: string }

export type ItListImageProps = CustomImageProps &
  Omit<ImageProps, 'htmlWidth' | 'htmlHeight' | 'alt'>

const CharkraIKImage = chakra(IKImage)

// 1x1 vapor color pixel
const DEFAULT_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAABhWlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9biyIVhxYRdchQnSyIioiTVqEIFUKt0KqDyaVf0KQhSXFxFFwLDn4sVh1cnHV1cBUEwQ8QVxcnRRcp8X9JoUWMB8f9eHfvcfcO8NfLTDU7xgBVs4xUIi5ksqtC5yuC6EMYMxiUmKnPiWISnuPrHj6+3sV4lve5P0ePkjMZ4BOIZ5luWMQbxFObls55nzjCipJCfE48atAFiR+5Lrv8xrngsJ9nRox0ap44QiwU2lhuY1Y0VOJJ4qiiapTvz7iscN7irJarrHlP/sJQTltZ5jrNISSwiCWIECCjihLKsBCjVSPFRIr24x7+AccvkksmVwmMHAuoQIXk+MH/4He3Zn5i3E0KxYHgi21/DAOdu0CjZtvfx7bdOAECz8CV1vJX6sD0J+m1lhY9Anq3gYvrlibvAZc7QP+TLhmSIwVo+vN54P2MvikLhG+B7jW3t+Y+Th+ANHWVvAEODoGRAmWve7y7q723f880+/sBuRdywymMABEAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfnBRYUBQ+M4OTRAAAAGXRFWHRDb21tZW50AENyZWF0ZWQgd2l0aCBHSU1QV4EOFwAAAAxJREFUCNdj+PTpEwAFsALXzXARrgAAAABJRU5ErkJggg=='

// Even screens with 3x retina display don't show any difference on images delivered at 2x resolution
// see https://youtu.be/AQqFZ5t8uNc?t=414
const HIGH_DENSITY_FACTOR = Math.min(2, window.devicePixelRatio || 1)

const ItListImage: React.FC<ItListImageProps> = ({
  src,
  htmlWidth,
  htmlHeight,
  fit,
  ...props
}) => {
  const transformation: { width?: string; height?: string } = {}
  const [fallback, setFallback] = useState(false)
  if (htmlHeight) transformation.height = `${htmlHeight * HIGH_DENSITY_FACTOR}`
  if (htmlWidth) transformation.width = `${htmlWidth * HIGH_DENSITY_FACTOR}`
  if (!src) return null
  const css = fit ? { objectFit: fit } : {}
  // fallback will be used when adding an image to assets/image, it won't be available
  // in S3 until the next deployment so we fallback to the local image
  if (fallback || src.startsWith('data:image')) {
    return (
      <Image
        src={src}
        __css={css}
        fallback={
          <Image
            src={DEFAULT_IMAGE}
            width={htmlWidth}
            height={htmlHeight}
            {...props}
          />
        }
        {...props}
      />
    )
  }
  return (
    <CharkraIKImage
      // We're not using urlEndpoint because we have 2 different endpoints
      // so it's easier to just put it in the src but the component throws an error
      // if urlEndpoint is empty
      urlEndpoint="-"
      transformation={[transformation]}
      src={getImageKitUrl(src)}
      lqip={{ active: true }}
      __css={css}
      {...props}
      onError={() => {
        setFallback(true)
      }}
    />
  )
}

export default ItListImage
