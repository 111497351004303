import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react'

import channelsExample from 'src/assets/image/channels_example.jpg'
import ItListImage from 'src/components/widgets/ItListImage/ItListImage'

const ChannelsExample: React.FC = () => (
  <Accordion allowToggle={true}>
    <AccordionItem border={0}>
      {({ isExpanded }) => (
        <Box boxShadow={isExpanded ? 'sm' : ''} borderRadius="16px">
          <AccordionButton
            bg={isExpanded ? 'white' : 'air'}
            borderRadius={isExpanded ? '16px' : 'full'}
            py={3}
          >
            <Box
              as="span"
              flex="1"
              textAlign="left"
              className="body-small-emphasis"
            >
              Example
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <ItListImage
              htmlWidth={452}
              width="100%"
              src={channelsExample}
              alt="Channels examples"
            />
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  </Accordion>
)

export default ChannelsExample
