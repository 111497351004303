import {
  Flex,
  Box,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  List,
} from '@chakra-ui/react'

import { Link, routes } from '@redwoodjs/router'

import LoginButton from 'src/components/widgets/buttons/LoginButton/LoginButton'
import LogoutButton from 'src/components/widgets/buttons/LogoutButton/LogoutButton'
import { HELP_CENTER_URL } from 'src/lib/constants'

import HeaderActions from '../../HeaderActions/HeaderActions'
import DrawerMenuItemSecondary from '../DrawerMenuItemSecondary/DrawerMenuItemSecondary'

const DrawerLoggedOut: React.FC<{
  onClose(): void
  headerButtonVariant?: string
}> = ({ onClose, headerButtonVariant }) => (
  <>
    <DrawerOverlay />
    <DrawerContent maxW={{ base: 'unset', md: '375px' }}>
      <DrawerHeader
        px={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <DrawerCloseButton
          variant="navDrawer"
          top="unset"
          right="unset"
          position="relative"
        />
        <LoginButton onClick={onClose} pt={1} />
      </DrawerHeader>

      <DrawerBody px={4}>
        <List variant="overlined" as="nav" mt={4}>
          <Link to={routes.aboutUs()} onClick={onClose}>
            <DrawerMenuItemSecondary label="About Us"></DrawerMenuItemSecondary>
          </Link>
          <a
            href={HELP_CENTER_URL}
            target="_blank"
            rel="noreferrer"
            onClick={onClose}
          >
            <DrawerMenuItemSecondary label="Help Center"></DrawerMenuItemSecondary>
          </a>
        </List>
        <Flex
          flexDir="column"
          textAlign="center"
          bg="white"
          px={6}
          gap={6}
          my={14}
        >
          <Box color="stone" textAlign="center" className="body-small">
            Reserve your @handle before it&apos;s gone!
          </Box>
          <HeaderActions
            onClick={onClose}
            section="mobile-nav"
            headerButtonVariant={headerButtonVariant}
          />

          <LogoutButton onClick={onClose} />
        </Flex>
      </DrawerBody>
    </DrawerContent>
  </>
)

export default DrawerLoggedOut
