export const aboutChannel = {
  mediaUrl: 'https://itlist.com/assets/static/about-topic-channels.mp3',
  title: 'About topic Channels',
}

export const itListDisclaimer = {
  mediaUrl: 'https://itlist.com/assets/static/about-disclaimers.mp3',
  title: 'ItList Disclaimer',
}

export const postPublishModal = {
  mediaUrl: 'https://itlist.com/assets/static/post-publish-modal.mp3',
  title: 'Publish and share your ItList',
}
