export interface UseCollapsibleReturn {
  openedAt: number
  close(): void
  isOpened(index: number): boolean
  open(index: number): void
  toggle(index?: number): void
}

export default function useCollapsible(): UseCollapsibleReturn {
  const [openedAt, setOpenedAt] = React.useState<number | null>(null)

  const close = React.useCallback(() => {
    setOpenedAt(null)
  }, [])

  const open = React.useCallback((index: number) => {
    setOpenedAt(index)
  }, [])

  const isOpened = React.useCallback(
    (index: number) => index === openedAt,
    [openedAt]
  )

  const toggle = React.useCallback(
    (index) => {
      setOpenedAt(openedAt === index ? null : index)
    },
    [openedAt]
  )

  return { close, openedAt, isOpened, open, toggle }
}
