import { Button, Flex, Box } from '@chakra-ui/react'
import {
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'

import { Link, routes } from '@redwoodjs/router'

import FeedbackButton from 'src/components/widgets/buttons/FeedbackButton/FeedbackButton'

import { thisYear } from '../Footer'
import SocialLink from '../SocialLink/SocialLink'

const Small = () => (
  <>
    <Flex gap={2} py={10} alignItems="center" direction="column">
      <FeedbackButton mb={6} />
      <Link to={routes.terms()}>
        <Button variant="ghost" size="sm" lineHeight="26px">
          Terms
        </Button>
      </Link>
      <Link to={routes.privacy()}>
        <Button variant="ghost" size="sm" lineHeight="26px">
          Privacy
        </Button>
      </Link>
    </Flex>
    <Flex justifyContent="center" gap={6} m="0 auto">
      <SocialLink icon={faTwitter} url="https://twitter.com/ItList_HQ" />
      <SocialLink
        icon={faInstagram}
        url="https://www.instagram.com/itlist_hq/"
      />
      <SocialLink
        icon={faLinkedinIn}
        url="https://www.linkedin.com/company/itlist/about/"
      />
    </Flex>
    <Flex
      alignItems="center"
      className="body-extra-small"
      m="32px auto"
      direction="column"
    >
      <Box color="stone">Made with ❤️ in LA</Box>
      <Box color="stone">&copy; {thisYear} ItList Creator Labs, Inc.</Box>
      <Box color="stone">All rights reserved</Box>
    </Flex>
  </>
)

export default Small
