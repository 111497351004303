import { Box, Button } from '@chakra-ui/react'
import { ItList } from 'types/graphql'

import { routes } from '@redwoodjs/router'

import Link from 'src/components/widgets/Link/Link'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

import HeaderActions from '../../HeaderActions/HeaderActions'

import MenuItems from './MenuItems/MenuItems'
import SettingsMenu from './SettingsMenu/SettingsMenu'

const LoggedIn: React.FC<{ headerButtonVariant?: string; itList?: ItList }> = ({
  headerButtonVariant,
  itList,
}) => {
  const { isCreator, isAffiliate } = useCurrentUser()
  return (
    <>
      <Box flexGrow={1}>
        {isCreator || isAffiliate ? (
          <MenuItems />
        ) : (
          <Link to={routes.ownedItLists()}>
            <Button variant="ghost" size="sm">
              ItLists
            </Button>
          </Link>
        )}
      </Box>

      <HeaderActions
        section="header"
        label="Make An ItList"
        headerButtonVariant={headerButtonVariant}
        itList={itList}
      />
      <SettingsMenu />
    </>
  )
}

export default LoggedIn
