import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Modal: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      borderRadius: '12px',
    },
    header: {
      px: 4,
    },
    footer: {
      px: 4,
    },
    body: { px: 4, py: 0 },
  },
  sizes: {
    sm: { dialog: { maxW: '326px' } },
    md: { dialog: { maxW: '516px' } },
  },
  variants: {
    cropper: {
      content: {
        borderRadius: '12px',
        width: '350px',
        height: '500px',
        margin: '16px',
      },
      header: {
        boxShadow: 'inset 0px -1px 0px #EBEBEB;',
        textAlign: 'center',
      },
      footer: {
        boxShadow: 'inset 0px 1px 0px #EBEBEB;',
      },
    },
  },
}
