import { Box, Flex, BoxProps } from '@chakra-ui/react'

import { BrandColor } from 'src/types'

export type ChannelFAQProps = {
  closeModal: () => void
  isOpen: boolean
  nextStep?: () => void
  brandColor?: BrandColor
}

const ExampleChannels: React.FC<
  {
    niche: string
    channels: string[]
  } & BoxProps
> = ({ niche, channels, ...props }) => {
  return (
    <Flex
      flexDir="column"
      gap={1}
      className="body-small"
      py={2}
      {...props}
      mt={4}
    >
      <Box>
        <Box as="span" className="body-small-emphasis" color="onyx">
          Niche:&nbsp;
        </Box>

        {niche}
      </Box>
      <Box>
        <Box>
          <Box as="span" className="body-small-emphasis" color="onyx">
            Topic Channels:&nbsp;
          </Box>
          {channels.map((channel, index) => (
            <Box as="span" key={index}>
              {channel}&nbsp;
              {index === channels.length - 1 ? '' : ' • '}&nbsp;
            </Box>
          ))}
        </Box>
      </Box>
    </Flex>
  )
}

export default ExampleChannels
