import { useEffect } from 'react'

import { useMatch } from '@redwoodjs/router'

import { useAnalytics } from './useAnalytics'
import { useViewerStatus } from './useViewerStatus'

export function useGenericPageView() {
  const { page } = useAnalytics()
  const viewerStatus = useViewerStatus()
  // I wish there was a way to do this more dynamically, but couldn't figure it out
  // We just don't want the generic page view event to be triggered on those routes
  // because those routes trigger a specific resource page view with more info
  // Profile page route
  const { match: matchProfile } = useMatch('/@{username:string}')
  // ItList details page route
  const { match: matchItListDetails } = useMatch('/i/{id:Int}/{title:string}')

  useEffect(() => {
    if (matchProfile || matchItListDetails) return
    page({ viewerStatus })
  }, [page, matchProfile, matchItListDetails, viewerStatus])
}
