import { useCallback } from 'react'

import { Button, SystemProps } from '@chakra-ui/react'
import { faMegaphone } from '@fortawesome/pro-regular-svg-icons'

import Icon from 'src/components/widgets/Icon'
import { FEEDBACK_URL } from 'src/lib/constants'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'
import { capitalize } from 'src/lib/utils'

const FeedbackButton = (props: SystemProps) => {
  const { email, fullName, accountType } = useCurrentUser()
  const generatedUrl = useCallback(() => {
    return FEEDBACK_URL.replace(
      '[email]',
      email ? encodeURIComponent(email) : ''
    )
      .replace('[fullName]', fullName ? encodeURIComponent(fullName) : '')
      .replace(
        '[accountType]',
        accountType !== 'anonymous' ? capitalize(accountType) : 'Visitor'
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, fullName, accountType])

  return (
    <a href={generatedUrl()} target="_blank" rel="noreferrer">
      <Button variant="elevated" {...props}>
        Give Feedback
        <Icon ml={2} icon={faMegaphone} />
      </Button>
    </a>
  )
}

export default FeedbackButton
