/*
 * To convert a CSS gradient to a SVG one:
 */
const SvgGradients = () => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="svg-settings"
      >
        <defs>
          <linearGradient
            id="primary-special1"
            gradientTransform="rotate(120.71)"
          >
            <stop offset="11.08%" stopColor="#A356CB" />
            <stop offset="79.09%" stopColor="#DFDAE1" />
          </linearGradient>
          <linearGradient
            id="primary-special2"
            gradientTransform="rotate(120.71)"
          >
            <stop offset="11.08%" stopColor="#FE4C6C" />
            <stop offset="79.09%" stopColor="#DFDAE1" />
          </linearGradient>
          <linearGradient
            id="primary-special3"
            gradientTransform="rotate(120.71)"
          >
            <stop offset="11.08%" stopColor="#D08A00" />
            <stop offset="79.09%" stopColor="#DFDAE1" />
          </linearGradient>
          <linearGradient id="primary-special4">
            <stop offset="0%" stopColor="#BA8EDB" />
            <stop offset="100%" stopColor="#75C1A8" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export default SvgGradients
